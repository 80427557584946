import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { NotificationManager } from 'react-notifications';
import AlertIcon from '../../../../../../../assets/img/alerta.png';
import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import { RenderCurrency, RenderNumber } from '../../../../Utils/renderField/renderReadField.js';
import { api } from 'api';

import './paso11.css';

const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);
    const [pageNuevos, setPageNuevos] = React.useState(1);
    const [opciones, setOpciones] = React.useState([{ id: 0, nombre: 'Seleccione empleado' }]);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    React.useEffect(() => {
        async function obtenerEmpleados() {
            await api
                .get('seguro-empleados/empleados_sin_certificado')
                .then(data => {
                    let opciones = [...data];
                    opciones.unshift({ id: 0, nombre: 'Seleccione empleado' });
                    opciones = opciones.map(item => ({ ...item, disabled: false }));
                    setOpciones(opciones);
                })
                .catch(error => {
                    let mensajeError = 'Error al obtener listado de empleados';
                    if (error && error.detail) mensajeError = error.detail;
                    NotificationManager.error(mensajeError, 'Error', 7000);
                });
        }
        // obtenerEmpleados().then();
    }, []);

    const onChangeSelect = (value, row) => {
        const _row = { ...row };
        let copia_data = [...props.nuevos.results];
        let copia_opciones = [...opciones];

        copia_opciones = copia_opciones.map(item => {
            if (item.id === value) {
                item.disabled = true;
            }
            if (_row.EMPLEADO && _row.EMPLEADO === item.id) {
                item.disabled = false;
            }
            return item;
        });

        copia_data = copia_data.map(item => {
            if (item.CERTIFICADO === row.CERTIFICADO) {
                item.EMPLEADO = value;
            }
            return item;
        });

        setOpciones(copia_opciones);
        props.setNuevos(s => ({ ...s, results: copia_data }));
    };

    const { errores, nuevos } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {errores && errores.count > 0 ? (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <div className="custom-scroll">
                        <Grid
                            data={{
                                results: paginate(errores.results, pageErrores),
                                count: errores.count,
                            }}
                            onPageChange={setPageErrores}
                            sizePerPage={10}
                            page={pageErrores}
                            scrollTable
                        >
                            <TableHeaderColumn
                                dataField="CERTIFICADO"
                                dataAlign="center"
                                isKey
                                width="5rem"
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={AlertIcon} />;
                                }}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="ERROR" width="45rem">
                                ERROR
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CERTIFICADO">NO CERTIFICADO</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="GM.1"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                PRIMA SEGUROS GM
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="VIDA.1"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                PRIMA SEGUROS VIDA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="GM.2"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                RETROACTIVO SEGUROS GM
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="VIDA.2"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                RETROACTIVO SEGUROS VIDA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="GASTOS_MEDICOS"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                GASTOS MEDICOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="PORCENTAJE"
                                dataFormat={value => (
                                    <RenderNumber
                                        className="remove-style"
                                        suffix={' %'}
                                        decimalScale={2}
                                        value={value}
                                    />
                                )}
                            >
                                PORCENTAJE
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="TOTAL"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                TOTAL
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {nuevos && nuevos.count > 0 && (
                        <React.Fragment>
                            <span className="text-dark">Nuevos certificados</span>
                            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />

                            <Grid
                                data={{
                                    results: paginate(nuevos.results, pageNuevos),
                                    count: nuevos.count,
                                }}
                                onPageChange={setPageNuevos}
                                sizePerPage={10}
                                page={pageNuevos}
                            >
                                <TableHeaderColumn
                                    dataField="EMPLEADO"
                                    dataAlign="center"
                                    width="7rem"
                                    dataFormat={cell => {
                                        if (cell && cell !== '0') {
                                            return <img className="column-data-icon" src={OkIcon} />;
                                        }
                                        return <img className="column-data-icon" src={AlertIcon} />;
                                    }}
                                >
                                    ESTADO
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="CERTIFICADO" isKey>
                                    NO. CERTIFICADO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="EMPLEADO"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div>
                                                <select
                                                    value={cell || 0}
                                                    onChange={e => onChangeSelect(e.target.value, row)}
                                                >
                                                    {opciones.map(item => (
                                                        <option key={item.id} disabled={item.disabled} value={item.id}>
                                                            {item.id}-{item.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        );
                                    }}
                                >
                                    EMPLEADO
                                </TableHeaderColumn>
                            </Grid>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
