import React from 'react';
import UploadDriver from '../../../../Utils/UploadDriver';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PasoIntermedio from './PasoIntermedio';
import PasoFinal from './PasoFinal';
import IconoAlerta from '../../../../../../../assets/img/alerta-xl.png';
import { api } from 'api';
import _ from 'lodash';
import useCopiarDriver from '../useCopiarDriver';

const dataSeed = {
    results: [],
    count: 0,
};

const SeguroEmpleados = props => {
    const [driverTitle, setDriverTitle] = React.useState('Datos de seguro por empleado');
    const [errores, setErrores] = React.useState(dataSeed);
    const [nuevos, setNuevos] = React.useState(dataSeed);
    // SE HABILITA EL PASO INTERMEDIO SI EXISTE UNA RESPUESTA DEL BACKEND
    const [intermedio, setIntermedio] = React.useState(false);
    // SE HABILITA EL PASO FINAL SI TODO ESTA BIEN
    const [final, setFinal] = React.useState(false);
    // USAMOS ESTA VARIABLE  PARA SABER SI CONSULTAR EL ESTADO DE LA O NO
    const [consultar, setConsultar] = React.useState(false);
    const { setLoader, paso, informe, history } = props;

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });
    const next = async () => {
        if (intermedio) {
            if (comprobarData()) {
                const mySwal = withReactContent(Swal);
                await mySwal
                    .fire({
                        title: 'Al aceptar estarás guardando los certificados asignados',
                        text: '¿Deseas continuar?',
                        iconHtml: <img src={IconoAlerta} />,
                        showCancelButton: true,
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar',
                        reverseButtons: true,
                    })
                    .then(async res => {
                        if (res.value) {
                            await asignar_certificado();
                        }
                    });
            }
        } else {
            const siguiente = paso + 1;
            history.push(`/informe/${informe.id}/${siguiente}`);
        }
    };
    const prev = () => {
        if (!final && !intermedio) {
            const anterior = paso - 1;
            history.push(`/informe/${informe.id}/${anterior}`);
        } else {
            setFinal(false);
            setIntermedio(false);
            setNextButton(s => ({ ...s, isVisible: false }));
        }
    };

    const comprobarData = () => {
        const repetidos = [];
        const no_asignados = [];
        const copia_nuevos = [...nuevos.results];
        copia_nuevos.forEach(item => {
            let count = 0;
            if (item.EMPLEADO) {
                copia_nuevos.forEach(item2 => {
                    if (item2.EMPLEADO && item.EMPLEADO === item2.EMPLEADO) count += 1;
                });
            }
            if (count > 1) repetidos.push(item);
        });
        copia_nuevos.forEach(item => {
            if (!item.EMPLEADO || item.EMPLEADO == 0) no_asignados.push(item);
        });
        const mySwal = withReactContent(Swal);
        if (repetidos.length > 0) {
            mySwal.fire({
                title: 'Error!',
                text: 'Uno o mas empleados tienen mas de un certificado asignado',
                iconHtml: <img src={IconoAlerta} />,
            });
        }
        if (no_asignados.length > 0) {
            mySwal.fire({
                title: 'Error!',
                text: `Tienes ${no_asignados.length} certificados sin empleado asignado`,
                iconHtml: <img src={IconoAlerta} />,
            });
        }
        if (repetidos.length > 0 || no_asignados.length > 0) return false;
        return true;
    };

    const cargar = async (data, files = []) => {
        setLoader(true);
        await api
            .postAttachments('seguro-empleados/cargar_archivo', data, files)
            .then(res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al cargar centro de costo';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const guardar = async data => {
        await setLoader(true);
        await api
            .post('seguro-empleados', data)
            .then(res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al guardar centro de costo';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const asignar_certificado = async () => {
        let asignados = [...nuevos.results];
        setLoader(true);
        asignados = asignados.map(item => ({
            id: item.EMPLEADO,
            no_certificado: item.CERTIFICADO,
        }));

        await api
            .post('seguro-empleados/asignar_certificado', { asignados: asignados })
            .then(async response => {
                await guardar({ informe: informe.id });
            })
            .catch(error => {
                let mensajeError = 'Error al asignar certificados';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const consultarEstado = () => {
        api.get('task/estado', { informe: informe.id, driver: 'SEGURO_EMPLEADO' })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    const siguiente = paso + 1;
                    props.next(informe.id, siguiente).then();
                    setFinal(true);
                    setIntermedio(false);
                    setConsultar(false);
                    setNextButton(s => ({ ...s, isVisible: true }));
                    NotificationManager.success(
                        'Archivo de seguros de vida y gastos medicos cargado exitosamente',
                        'Éxito',
                        4000
                    );
                } else if (response.status === 'FAILURE') {
                    if (response.result && response.result.errores) {
                        setIntermedio(true);
                        setFinal(false);
                        setErrores({ results: response.result.errores, count: response.result.errores.length });
                    } else if (response.result && response.result.nuevos) {
                        setIntermedio(true);
                        setFinal(false);
                        setErrores({ results: [], count: 0 });
                        setNuevos({ results: response.result.nuevos, count: response.result.nuevos.length });
                        setNextButton(s => ({ ...s, isVisible: true }));
                    } else if (
                        response.result &&
                        response.result.exc_message &&
                        _.isString(response.result.exc_message)
                    ) {
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                        setNextButton(s => ({ ...s, isVisible: false }));
                    } else {
                        NotificationManager.error(
                            'Ocurrió un error al cargar archivo de seguros de vida y gastos medicos',
                            'Error',
                            5000
                        );
                        setNextButton(s => ({ ...s, isVisible: false }));
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = 'La tarea que procesa el driver no se pudo ejecutar o fallo.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 5000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 15000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    React.useEffect(() => {
        return setLoader(false);
    }, []);

    const { copiarDriver } = useCopiarDriver(
        informe,
        'COPIA_SEG_EMP',
        paso,
        setLoader,
        setNextButton,
        setFinal,
        props.next
    );
    const onCopy = async () => {
        await copiarDriver('seguro-empleados/copiar_datos');
    };
    const onChangeFile = async file => {
        const files = [];
        files.push({ name: 'archivo', file: file });
        await cargar({ informe: informe.id }, files);
    };

    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="Datos de seguro por empleado"
                totalStep={props.totalPasos}
                nextButton={{ ...nextButton, next: next }}
                prevButton={{ ...prevButton, prev: prev }}
            />

            {final && <PasoFinal setLoader={setLoader} informe={informe} />}
            {intermedio && <PasoIntermedio errores={errores} nuevos={nuevos} setNuevos={setNuevos} />}
            {!final && !intermedio && (
                <UploadDriver driverTitle={driverTitle} onCopy={onCopy} onChange={onChangeFile} />
            )}
        </React.Fragment>
    );
};

export default SeguroEmpleados;
