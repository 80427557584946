import React from 'react';
import Select from 'react-select/';
import Stepper from '../../../../Utils/Stepper';
import Grid from '../../../../Utils/Grid';
import { NotificationManager } from 'react-notifications';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField.js';
import CaretAbajo from '../../../../../../../assets/img/Caret-abajo.png';
import TablaCuentas from './TablaCuentas';
import './DistribuirGestiones.css';
import { api } from 'api';

export default function DistribuirGestiones(props) {
    const { paso, setPaso, totalPasos, setLoader } = props;
    const { id } = props.match.params;
    const [page, setPage] = React.useState(1);
    const [consultar, setConsultar] = React.useState(false);
    const [NoEmpresa, setNoEmpresa] = React.useState(1);
    const [data, setData] = React.useState({ results: [] });

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        isVisible: true,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        isVisible: true,
    });

    const EMPRESAS = [
        { value: 1, label: 'Empresa 1' },
        { value: 2, label: 'Empresa 2' },
    ];

    const listarGestiones = (page = 1, empresa) => {
        setLoader(true);
        const params = {};
        params.informe = id;
        params.page = page;
        params.empresa = empresa;

        return api.get('asignacion-gestion', params);
    };

    const Validate = () => {
        const params = {};
        params.informe = id;
        setLoader(true);
        return api.post('asignacion-gestion/validar', {}, params).then(response => {
            if (response.detail) {
                NotificationManager.error(response.detail, 'Error', 4000);
                setLoader(false);
            } else {
                api.post('asignacion-gestion/distribuir_gestiones', {}, { informe: id })
                    .then(() => setConsultar(true))
                    .catch(() => {
                        setLoader(false);
                        NotificationManager.error(
                            'Error interno, no se pudo iniciar la distribucion de gestiones',
                            'Error',
                            7000
                        );
                    });
            }
        });
    };

    const ActualizarGestiones = cuentas => {
        const params = {};
        params.informe = id;

        return api
            .post('asignacion-gestion/actualizar_gestion', { cuenta: cuentas }, params)
            .then(response => {
                if (response) {
                    listarGestiones(page, NoEmpresa).then(response => {
                        setData(response);
                        setLoader(false);
                    });
                }
            })
            .catch(error => {
                NotificationManager.error(error.detail, 'Error', 4000);
            });
    };

    const GuardarGestion = (cuentas_63, cuentas_64, cuentas_68) => {
        let cuentas = [];
        let porcentaje_63;
        let porcentaje_64;
        let porcentaje_68;
        let no_error = 0;

        if (cuentas_63 && cuentas_63['63'].length > 0) {
            porcentaje_63 = cuentas_63['63'].reduce(function (prev, cur) {
                let porcentaje = parseFloat(cur.porcentaje);
                if (!Number.isNaN(porcentaje)) {
                    return prev + porcentaje;
                } else {
                    return prev;
                }
            }, 0);

            cuentas.push({ cuenta: 63, porcentaje: cuentas_63['63'] });
        }

        if (cuentas_64 && cuentas_64['64'].length > 0) {
            porcentaje_64 = cuentas_64['64'].reduce(function (prev, cur) {
                let porcentaje = parseFloat(cur.porcentaje);
                if (!Number.isNaN(porcentaje)) {
                    return prev + porcentaje;
                } else {
                    return prev;
                }
            }, 0);

            cuentas.push({ cuenta: 64, porcentaje: cuentas_64['64'] });
        }

        if (cuentas_68 && cuentas_68['68'].length > 0) {
            porcentaje_68 = cuentas_68['68'].reduce(function (prev, cur) {
                let porcentaje = parseFloat(cur.porcentaje);
                if (!Number.isNaN(porcentaje)) {
                    return prev + porcentaje;
                } else {
                    return prev;
                }
            }, 0);

            cuentas.push({ cuenta: 68, porcentaje: cuentas_68['68'] });
        }

        porcentaje_63 = porcentaje_63 ? porcentaje_63.toFixed(2) : porcentaje_63;
        porcentaje_64 = porcentaje_64 ? porcentaje_64.toFixed(2) : porcentaje_64;
        porcentaje_68 = porcentaje_68 ? porcentaje_68.toFixed(2) : porcentaje_68;

        if (cuentas_63 && cuentas_63['63'].length > 0 && porcentaje_63 != 100) {
            if (porcentaje_63 > 100) {
                NotificationManager.error('La cuenta 63 se pasa del 100% a distribuir.', 'Error', 5000);
            } else {
                NotificationManager.error('No se ha distribuido el 100% de la cuenta 63.', 'Error', 5000);
            }
            no_error = no_error + 1;
        }
        if (cuentas_64 && cuentas_64['64'].length > 0 && porcentaje_64 != 100) {
            if (porcentaje_64 > 100) {
                NotificationManager.error('La cuenta 64 se pasa del 100% a distribuir.', 'Error', 5000);
            } else {
                NotificationManager.error('No se ha distribuido el 100% de la cuenta 64.', 'Error', 5000);
            }
            no_error = no_error + 1;
        }
        if (cuentas_68 && cuentas_68['68'].length > 0 && porcentaje_68 != 100) {
            if (porcentaje_68 > 100) {
                NotificationManager.error('La cuenta 68 se pasa del 100% a distribuir.', 'Error', 5000);
            } else {
                NotificationManager.error('No se ha distribuido el 100% de la cuenta 68.', 'Error', 5000);
            }
            no_error = no_error + 1;
        }

        if (no_error == 0) ActualizarGestiones(cuentas);
    };

    React.useEffect(() => {
        const { id } = props.match.params;
        if (id) {
            listarGestiones(page, NoEmpresa).then(response => {
                setData(response);
                setLoader(false);
            });
        }
    }, [page]);

    const consultarEstado = () => {
        api.get('task/estado', { informe: id, driver: 'DIST_GESTIONES' })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    setPaso(paso + 1);
                    setLoader(false);
                    NotificationManager.success(
                        'Se han distribuido exitosamente todas las gestiones de soporte',
                        'Éxito',
                        5000
                    );
                } else if (response.status === 'FAILURE') {
                    if (response.result && response.result.exc_message) {
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                    } else {
                        NotificationManager.error(
                            'Ocurrió un error al distribuir las gestiones de soporte',
                            'Error',
                            7000
                        );
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = JSON.stringify(e);
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 7000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 20000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    const expandComponent = row => {
        let data_cuentas = row.cuentas;
        let cuenta_63 = data_cuentas.filter(it => it['63'])[0];
        let cuenta_64 = data_cuentas.filter(it => it['64'])[0];
        let cuenta_68 = data_cuentas.filter(it => it['68'])[0];

        return (
            <React.Fragment>
                <div>
                    <div className="d-flex p-1">
                        {cuenta_63 && cuenta_63['63'].length > 0 && (
                            <TablaCuentas
                                data={cuenta_63['63']}
                                data_original={data}
                                total_cuenta={cuenta_63['total']}
                                setData={setData}
                                cuenta={'CUENTA 63'}
                                id={id}
                                page={page}
                                empresa={NoEmpresa}
                                setLoader={setLoader}
                                listarGestiones={listarGestiones}
                            />
                        )}
                    </div>
                    <div className="d-flex p-1">
                        {cuenta_64 && cuenta_64['64'].length > 0 && (
                            <TablaCuentas
                                data={cuenta_64['64']}
                                data_original={data}
                                total_cuenta={cuenta_64['total']}
                                setData={setData}
                                cuenta={'CUENTA 64'}
                                id={id}
                                page={page}
                                empresa={NoEmpresa}
                                setLoader={setLoader}
                                listarGestiones={listarGestiones}
                            />
                        )}
                    </div>
                    <div className="d-flex p-1">
                        {cuenta_68 && cuenta_68['68'].length > 0 && (
                            <TablaCuentas
                                data={cuenta_68['68']}
                                data_original={data}
                                total_cuenta={cuenta_68['total']}
                                setData={setData}
                                cuenta={'CUENTA 68'}
                                id={id}
                                page={page}
                                empresa={NoEmpresa}
                                setLoader={setLoader}
                                listarGestiones={listarGestiones}
                            />
                        )}
                    </div>
                    <div className="PanelButtonsDistribucionSoporte">
                        {!row.estado && (
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    GuardarGestion(cuenta_63, cuenta_64, cuenta_68);
                                }}
                            >
                                Guardar Configuración
                            </button>
                        )}

                        {row.estado && (
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    GuardarGestion(cuenta_63, cuenta_64, cuenta_68);
                                }}
                            >
                                Actualizar Configuración
                            </button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
        if (isExpandableRow) {
            if (isExpanded) {
                return (
                    <div className="d-flex flex-1">
                        <img
                            src={CaretAbajo}
                            className="m-0 p-0"
                            style={{ transform: 'rotate(0.5turn)' }}
                            width="24px"
                            alt="Expanded"
                        />
                    </div>
                );
            } else {
                return <img src={CaretAbajo} className="m-0 p-0" width="24px" alt="Expanded" />;
            }
        } else return <div> </div>;
    };

    function statusAssigned(cell, row, enumObject) {
        if (row.estado != true) {
            return <div className="no_asignado">PENDIENTE</div>;
        } else if (row.estado == true) {
            return <div className="asignado">ASIGNADO</div>;
        }
    }

    return (
        <React.Fragment>
            <Stepper
                step={paso}
                stepName="Distribucion Gestiones de Soporte"
                totalStep={totalPasos}
                nextButton={{
                    name: 'Siguiente',
                    isVisible: true,
                    next: () => {
                        Validate();
                    },
                }}
                prevButton={{
                    name: 'Anterior',
                    isVisible: true,
                    prev: () => {
                        setPaso(paso - 1);
                    },
                }}
            />
            <br />

            <div className="d-flex justify-content-left mb-3">
                <div className="p-2 ">
                    <span className="text-2">No. </span>
                </div>
                <div className="col-md-3">
                    <Select
                        isClearable={false}
                        className="w-100 m-auto pl-0 pl-md-2"
                        autoFocus
                        defaultValue={EMPRESAS[0]}
                        onChange={value => {
                            setPage(1);
                            setNoEmpresa(value.value ? value.value : null);
                            listarGestiones(1, value.value).then(response => {
                                setData(response);
                                setLoader(false);
                            });
                        }}
                        placeholder="Seleccione tipo"
                        options={EMPRESAS}
                        classNamePrefix="react-select"
                    />
                </div>
            </div>

            <Grid
                className="mt-2"
                data={data}
                expandableRow={() => {
                    return true;
                }}
                pagination={true}
                page={page}
                onPageChange={page => setPage(page)}
                sizePerPage={10}
                expandComponent={expandComponent}
                expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: expandColumnComponent,
                    columnWidth: 45,
                }}
                hover
            >
                <TableHeaderColumn dataField="gestion_soporte" width="5rem" isKey>
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="nombre" width="25rem">
                    Gestión
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="monto"
                    width="10rem"
                    dataFormat={e => <RenderCurrency className="remove-style" value={e} />}
                >
                    Monto Total
                </TableHeaderColumn>
                <TableHeaderColumn dataField="id" dataFormat={statusAssigned} width="7rem">
                    Estado
                </TableHeaderColumn>
            </Grid>
            <br />
        </React.Fragment>
    );
}
