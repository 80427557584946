import React from 'react';
import Upload from '../../../../Utils/Uploader';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import PasoIntermedio from './PasoIntermedio';
import PasoFinal from './PasoFinal';
import ArchiveIcon from '../../../../../../../assets/img/duplicar-archivo.png';
import { api } from 'api';
import _ from 'lodash';
const dataSeed = {
    results: [],
    count: 0,
};

const MCAnioAnterior = props => {
    const [errores, setErrores] = React.useState(dataSeed);
    // SE HABILITA EL PASO INTERMEDIO SI EXISTE UNA RESPUESTA DEL BACKEND
    const [intermedio, setIntermedio] = React.useState(false);
    // SE HABILITA EL PASO FINAL SI TODO ESTA BIEN
    const [final, setFinal] = React.useState(false);
    // USAMOS ESTA VARIABLE  PARA SABER SI CONSULTAR EL ESTADO DE LA O NO
    const [consultar, setConsultar] = React.useState(false);
    const { setLoader, paso, informe, history } = props;

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });

    const next = async () => {
        const siguiente = paso + 1;
        history.push(`/informe/${informe.id}/${siguiente}`);
    };
    const prev = () => {
        if (!final && !intermedio) {
            const anterior = paso - 1;
            history.push(`/informe/${informe.id}/${anterior}`);
        } else {
            setFinal(false);
            setIntermedio(false);
            setNextButton(s => ({ ...s, isVisible: false }));
        }
    };

    const generarDriver25 = () => {
        setLoader(true);
        api.post(`driver/manual`, {}, { informe: informe.id, driver: 'DRIVER_25' })
            .then(setConsultar(true))
            .catch(e => {
                let msj = 'No se puedo ejecutar el proceso del driver 25.';
                if (e && e.detail) msj = e.detail;
                setLoader(false);
                setConsultar(false);
                NotificationManager.error(msj, 'Error', 7000);
            });
    };

    const cargar = async (data, files = [], params) => {
        setLoader(true);
        await api
            .postAttachments('driver/manual', data, files, params)
            .then(async res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al cargar archivo de los 25';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const consultarEstado = () => {
        api.get('task/estado', { informe: informe.id, driver: 'DRIVER_25' })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    const siguiente = paso + 1;
                    props.next(informe.id, siguiente).then();
                    setFinal(true);
                    setIntermedio(false);
                    setConsultar(false);
                    setNextButton(s => ({ ...s, isVisible: true }));
                    NotificationManager.success('Archivo del driver 25 procesado exitosamente', 'Éxito', 4000);
                } else if (response.status === 'FAILURE') {
                    if (response.result && response.result.errores) {
                        setIntermedio(true);
                        setFinal(false);
                        setErrores({ results: response.result.errores, count: response.result.errores.length });
                        if (response.result.errores && response.result.errores.length === 0)
                            setNextButton(s => ({ ...s, isVisible: true }));
                        else setNextButton(s => ({ ...s, isVisible: false }));
                    } else if (
                        response.result &&
                        response.result.exc_message &&
                        _.isString(response.result.exc_message)
                    ) {
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                        setNextButton(s => ({ ...s, isVisible: false }));
                    } else {
                        NotificationManager.error('No se pudo procesar el archivo de driver 25', 'Error', 5000);
                        setNextButton(s => ({ ...s, isVisible: false }));
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = 'La tarea que procesa el driver no se pudo ejecutar o fallo.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 5000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 30000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    React.useEffect(() => {
        return setLoader(false);
    }, []);

    const onChangeFile = async file => {
        const files = [];
        files.push({ name: 'archivo', file: file });
        await cargar({}, files, { informe: informe.id, driver: 'DRIVER_25' });
    };

    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="DVR 25 Monto cobrado año anterior"
                totalStep={props.totalPasos}
                nextButton={{ ...nextButton, next: next }}
                prevButton={{ ...prevButton, prev: prev }}
            />

            {final && <PasoFinal setLoader={setLoader} informe={informe} />}
            {intermedio && <PasoIntermedio errores={errores} />}
            {!final && !intermedio && (
                <React.Fragment>
                    {informe.carga_manual_driver_25 ? (
                        <React.Fragment>
                            <br />
                            <br className="d-none d-md-flex" />
                            <br className="d-none d-md-flex" />
                            <br className="d-none d-sm-flex" />
                            <br className="d-none d-sm-flex" />
                            <br className="d-none d-sm-flex" />
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    <img src={ArchiveIcon} className="upload-driver-icon" />
                                </div>
                                <br />
                                <br className="d-none d-sm-flex" />
                                <div className="d-flex justify-content-center">
                                    <span className="upload-driver-title">Monto cobrado año anterior</span>
                                </div>
                                <br />
                            </div>
                            <br />

                            <Upload onChange={onChangeFile} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <br />
                            <br className="d-none d-md-flex" />
                            <br className="d-none d-md-flex" />
                            <br className="d-none d-sm-flex" />
                            <br className="d-none d-sm-flex" />
                            <br className="d-none d-sm-flex" />
                            <div className="d-flex flex-column">
                                <br />
                                <br className="d-none d-sm-flex" />
                                <div className="d-flex justify-content-center">
                                    <span className="upload-driver-title">Monto cobrado año anterior</span>
                                </div>
                                <br />
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary" onClick={generarDriver25}>
                                        Generar Driver
                                    </button>
                                </div>
                                <br className="d-none d-sm-flex" />
                                <br className="d-none d-sm-flex" />
                                <br className="d-none d-sm-flex" />
                                <br className="d-none d-sm-flex" />
                                <br className="d-none d-sm-flex" />
                            </div>
                            <br />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MCAnioAnterior;
