import React, { Component } from 'react';
import LoadMask from '../Utils/LoadMask/LoadMask';
import moment from 'moment-timezone';
import CabeceraBitacora from '../Utils/Cabeceras/CabeceraBitacora';

import './Bitacora.css';
import IconBitacora from '../../../../assets/img/archivo.png';
import Atras from '../../../../assets/img/11-Izquierda.png';
class Bitacora extends Component {
    componentDidMount() {
        this.props.detail(this.props.match.params.id);
    }

    parseValue(value) {
        const is_date = moment(value, 'DD/MM/YYYY HH:mm', true).isValid();
        if (is_date) {
            return moment(Date(value)).tz('America/Guatemala').format('DD/MM/YYYY HH:mm:ss');
        } else if (value === true || value === 'true') {
            return 'Si';
        } else if (value === false || value === 'false') {
            return 'No';
        }
        return value;
    }

    comprar_cambios(propiedad) {
        const { detail_bitacora } = this.props;
        const detalle = detail_bitacora.detalle;
        const detalle_anterior = detail_bitacora.detalle_anterior;
        if (detalle && detalle_anterior) {
            const valor = this.parseValue(detalle[propiedad]);
            const valor_anterior = this.parseValue(detalle_anterior[propiedad]);
            if (valor !== valor_anterior) {
                return true;
            }
        }
        return false;
    }

    render() {
        const { detail_bitacora, loader } = this.props;
        const detalle = detail_bitacora.detalle;
        const detalle_anterior = detail_bitacora.detalle_anterior;

        return (
            <div className="card px-4 my-3 pb-4">
                <CabeceraBitacora />
                <div className="page-header mt-3">
                    <div className="px-4">
                        <LoadMask loading={loader} light blur>
                            <div className="row">
                                <h3 style={{ fontWeight: 'bolder' }}> Detalle Bitácora</h3>
                            </div>
                            <div className="row pt-3">
                                <h5>
                                    <span className="col-12" style={{ fontWeight: 'bolder', color: '#003da6' }}>
                                        {detail_bitacora.accion}
                                    </span>
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-5 col-lg-3">
                                    <span className="mr-2">Fecha y Hora:</span>
                                    {moment(detail_bitacora.created)
                                        .tz('America/Guatemala')
                                        .format('DD/MM/YYYY HH:mm:ss')}
                                </div>
                                <div className="col-6 col-sm-6 col-md-5 col-lg-3">
                                    <span className="mr-2">Responsable:</span>
                                    {detail_bitacora.usuario}
                                </div>
                            </div>
                            {detalle && detalle !== null && (
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <span>Detalle despues de la accion:</span>
                                    </div>
                                    <div className="col-12 mt-1">
                                        {detalle &&
                                            Object.keys(detalle).map(item => {
                                                return (
                                                    <div
                                                        className={`d-block d-sm-block d-md-flex d-lg-flex border border-dark1 ${
                                                            this.comprar_cambios(item) ? 'text-alert-red' : ''
                                                        }`}
                                                        key={item}
                                                    >
                                                        <div style={{ flex: '1' }} className="text-left col-lg-2">
                                                            <b>{item}:</b>
                                                        </div>
                                                        <div style={{ flex: '1' }} className="ml-3 text-left col-lg-9">
                                                            {this.parseValue(detalle[item])}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                            {detalle_anterior && detalle_anterior !== null && (
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <span>Detalle antes de la accion:</span>
                                    </div>
                                    <div className="col-12 mt-1">
                                        {detalle_anterior &&
                                            Object.keys(detalle_anterior).map(item => {
                                                return (
                                                    <div
                                                        className={`d-block d-sm-block d-md-flex d-lg-flex border border-dark1 ${
                                                            this.comprar_cambios(item) ? 'text-alert-red' : ''
                                                        }`}
                                                        key={item}
                                                    >
                                                        <div style={{ flex: '1' }} className="text-left col-lg-2">
                                                            <b>{item}:</b>
                                                        </div>
                                                        <div style={{ flex: '1' }} className="ml-3 text-left col-lg-9">
                                                            {this.parseValue(detalle_anterior[item])}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bitacora;
