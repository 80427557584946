import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import AlertIcon from '../../../../../../../assets/img/alerta.png';
import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField.js';

const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);
    const [pageNuevos, setPageNuevos] = React.useState(1);
    const [pageActualizados, setPageActualizados] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { errores, nuevos, actualizados } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {errores && errores.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores.results, pageErrores),
                            count: errores.count,
                        }}
                        onPageChange={setPageErrores}
                        sizePerPage={10}
                        page={pageErrores}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CENTRO_COSTO"
                            dataAlign="center"
                            isKey
                            width="5rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="30rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CENTRO_COSTO" width="7rem">
                            C.C.
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="AÑO" width="5rem">
                            AÑO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="MES" width="5rem">
                            MES
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="EMPRESA" width="5rem">
                            EMPRESA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="COMPAÑIA" width="5rem">
                            COMPAÑIA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="GERENCIA">GERENCIA</TableHeaderColumn>
                        <TableHeaderColumn dataField="DIR" width="5rem">
                            COD. DIRECCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DIRECCION">DIRECCION</TableHeaderColumn>
                        <TableHeaderColumn dataField="DEPTO" width="5rem">
                            COD. DEPARTAMENTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DEPARTAMENTO">DEPARTAMENTO</TableHeaderColumn>
                        <TableHeaderColumn dataField="CUENTA" width="10rem">
                            NO. CUENTA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMCTA">CUENTA</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="MONTO_DIST"
                            width="15rem"
                            dataAlign="right"
                            dataFormat={e => <RenderCurrency className="remove-style" value={e} />}
                        >
                            MONTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ELEGIBLE?"
                            width="7rem"
                            dataFormat={e => {
                                if (String(e) === 'true') return 'Si';
                                if (String(e) === 'false') return 'No';
                                return e;
                            }}
                        >
                            ELEGIBLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="MID" width="5rem">
                            MID
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {actualizados && actualizados.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Centros de costo actualizados</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(actualizados.results, pageActualizados),
                            count: actualizados.count,
                        }}
                        onPageChange={setPageActualizados}
                        sizePerPage={10}
                        page={pageActualizados}
                    >
                        <TableHeaderColumn
                            dataField="CENTRO_COSTO"
                            dataAlign="center"
                            isKey
                            width="5rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={OkIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CENTRO_COSTO" width="7rem">
                            C.C.
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="GERENCIA_ACTUAL" width="20rem">
                            GERENCIA ACTUAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="GERENCIA" width="20rem">
                            GERENCIA EN EXCEL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DIRECCION_ACTUAL" width="20rem">
                            DIRECCION ACTUAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DIRECCION" width="20rem">
                            DIRECCION EN EXCEL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DEPARTAMENTO_ACTUAL" width="20rem">
                            DEPARTAMENTO ACTUAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DEPARTAMENTO" width="20rem">
                            DEPARTAMENTO EN EXCEL
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {nuevos && nuevos.count > 0 && (
                <React.Fragment>
                    <span className="text-dark">Nuevos centros de costo</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(nuevos.results, pageNuevos),
                            count: nuevos.count,
                        }}
                        onPageChange={setPageNuevos}
                        sizePerPage={10}
                        page={pageNuevos}
                    >
                        <TableHeaderColumn
                            dataField="CENTRO_COSTO"
                            dataAlign="center"
                            isKey
                            width="5rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={OkIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CENTRO_COSTO" width="7rem">
                            C.C.
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="GERENCIA" width="18rem">
                            GERENCIA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DIRECCION" width="18rem">
                            DIRECCION
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="DEPARTAMENTO" width="18rem">
                            DEPARTAMENTO
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
