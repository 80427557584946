import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import AlertIcon from '../../../../../../../assets/img/alerta.png';
import OkIcon from '../../../../../../../assets/img/circulo-check.png';

const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);
    const [pageErrores_porcentaje, setPageErrores_porcentaje] = React.useState(1);
    const [pageErrores_gnt, setPageErrores_gnt] = React.useState(1);
    const [pageErrores_empleados, setPageErrores_empleados] = React.useState(1);
    const [pageNuevos_gnt, setPageNuevos_gnt] = React.useState(1);
    const [pageNuevos_empleados, setPageNuevos_empleados] = React.useState(1);
    const [pageActualizados_gnt, setPageActualizados_gnt] = React.useState(1);
    const [pageActualizados_empleados, setPageActualizados_empleados] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const {
        errores,
        errores_porcentaje,
        errores_gnt,
        errores_empleados,
        nuevos_gnt,
        nuevos_empleados,
        actualizados_gnt,
        actualizados_empleados,
    } = props;

    return (
        <React.Fragment>
            <br />
            <br />
            {errores && errores.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros de Pesos con errores </span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores.results, pageErrores),
                            count: errores.count,
                        }}
                        onPageChange={setPageErrores}
                        sizePerPage={10}
                        page={pageErrores}
                    >
                        <TableHeaderColumn
                            dataField="CC"
                            dataAlign="center"
                            isKey
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                            width="5rem"
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="15rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CC" width="5rem">
                            CENTRO DE COSTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="Codigo_GNT" width="5rem">
                            CODIGO GNT
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="Nombre_GNT" width="10rem">
                            NOMBRE GNT
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {errores_gnt && errores_gnt.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros de GNT con errores </span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores_gnt.results, pageErrores_gnt),
                            count: errores_gnt.count,
                        }}
                        onPageChange={setPageErrores_gnt}
                        sizePerPage={10}
                        page={pageErrores_gnt}
                    >
                        <TableHeaderColumn
                            dataField="Codigo_GNT"
                            dataAlign="center"
                            isKey
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                            width="5rem"
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="15rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="Codigo_GNT" width="8rem">
                            CODIGO GNT
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="Nombre_GNT" width="10rem">
                            NOMBRE GNT
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {actualizados_gnt &&
                actualizados_gnt.count > 0 &&
                errores.count <= 0 &&
                errores_gnt.count <= 0 &&
                errores_empleados.count <= 0 &&
                errores_porcentaje.count <= 0 && (
                    <React.Fragment>
                        <span className="text-dark"> Registros actualizados en catalogo de GNT</span>
                        <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                        <Grid
                            data={{
                                results: paginate(actualizados_gnt.results, pageActualizados_gnt),
                                count: actualizados_gnt.count,
                            }}
                            onPageChange={setPageActualizados_gnt}
                            sizePerPage={10}
                            page={pageActualizados_gnt}
                        >
                            <TableHeaderColumn
                                dataField="Codigo_GNT"
                                dataAlign="center"
                                isKey
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={OkIcon} />;
                                }}
                                width="10rem"
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Codigo_GNT" width="10rem">
                                CODIGO GNT
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Nombre_GNT" width="20rem">
                                NOMBRE GNT
                            </TableHeaderColumn>
                        </Grid>
                    </React.Fragment>
                )}
            {nuevos_gnt &&
                nuevos_gnt.count > 0 &&
                errores.count <= 0 &&
                errores_gnt.count <= 0 &&
                errores_empleados.count <= 0 &&
                errores_porcentaje.count <= 0 && (
                    <React.Fragment>
                        <span className="text-dark"> Registros nuevos en catalogo de GNT</span>
                        <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                        <Grid
                            data={{
                                results: paginate(nuevos_gnt.results, pageNuevos_gnt),
                                count: nuevos_gnt.count,
                            }}
                            onPageChange={setPageNuevos_gnt}
                            sizePerPage={10}
                            page={pageNuevos_gnt}
                        >
                            <TableHeaderColumn
                                dataField="Codigo_GNT"
                                dataAlign="center"
                                isKey
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={OkIcon} />;
                                }}
                                width="5rem"
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Codigo_GNT" width="5rem">
                                CODIGO GNT
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Nombre_GNT" width="10rem">
                                NOMBRE GNT
                            </TableHeaderColumn>
                        </Grid>
                    </React.Fragment>
                )}
            {errores_empleados && errores_empleados.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros de EMPLEADOS con errores </span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores_empleados.results, pageErrores_empleados),
                            count: errores_empleados.count,
                        }}
                        onPageChange={setPageErrores_empleados}
                        sizePerPage={10}
                        page={pageErrores_empleados}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                            width="5rem"
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="15rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="id" width="8rem">
                            CODIGO EMPLEADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="nombre" width="15rem">
                            NOMBRE EMPLEADO
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {actualizados_empleados &&
                actualizados_empleados.count > 0 &&
                errores.count <= 0 &&
                errores_gnt.count <= 0 &&
                errores_empleados.count <= 0 &&
                errores_porcentaje.count <= 0 && (
                    <React.Fragment>
                        <span className="text-dark"> Registros actualizados en catalogo de EMPLEADOS </span>
                        <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                        <Grid
                            data={{
                                results: paginate(actualizados_empleados.results, pageActualizados_empleados),
                                count: actualizados_empleados.count,
                            }}
                            onPageChange={setPageActualizados_empleados}
                            sizePerPage={10}
                            page={pageActualizados_empleados}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={OkIcon} />;
                                }}
                                width="5rem"
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="id" width="10rem">
                                CODIGO EMPLEADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="nombre" width="30rem">
                                NOMBRE EMPLEADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="empresa" width="10rem">
                                EMPRESA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="gnt" width="10rem">
                                GNT
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CC" width="10rem">
                                CENTRO DE COSTO
                            </TableHeaderColumn>
                        </Grid>
                    </React.Fragment>
                )}
            {nuevos_empleados &&
                nuevos_empleados.count > 0 &&
                errores.count <= 0 &&
                errores_gnt.count <= 0 &&
                errores_empleados.count <= 0 &&
                errores_porcentaje.count <= 0 && (
                    <React.Fragment>
                        <span className="text-dark"> Registros nuevos en catalogo de EMPLEADOS</span>
                        <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                        <Grid
                            data={{
                                results: paginate(nuevos_empleados.results, pageNuevos_empleados),
                                count: nuevos_empleados.count,
                            }}
                            onPageChange={setPageNuevos_empleados}
                            sizePerPage={10}
                            page={pageNuevos_empleados}
                            printable
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={OkIcon} />;
                                }}
                                width="5rem"
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="id" width="5rem">
                                CODIGO EMPLEADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="nombre" width="15rem">
                                NOMBRE EMPLEADO
                            </TableHeaderColumn>
                        </Grid>
                    </React.Fragment>
                )}{' '}
            {errores_porcentaje && errores_porcentaje.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros de centro de costo con incompletos </span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores_porcentaje.results, pageErrores_porcentaje),
                            count: errores_porcentaje.count,
                        }}
                        onPageChange={setPageErrores_porcentaje}
                        sizePerPage={10}
                        page={pageErrores_porcentaje}
                    >
                        <TableHeaderColumn
                            dataField="CC"
                            dataAlign="center"
                            isKey
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                            width="5rem"
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="25rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CC" width="10rem">
                            CENTRO DE COSTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="%" width="10rem" dataFormat={value => <label> {value} % </label>}>
                            PORCENTAJE
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
