import React from 'react';
import Grid from '../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../Utils/Grid/StandardActions';

import Cabecera from '../../Utils/Cabeceras/CabeceraProyecto';
import { Link } from 'react-router-dom';

const ListarGestion = props => {
    const { data, loader, eliminar, page, listar: onPageChange } = props;
    React.useEffect(() => {
        props.listar();
    }, []);

    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Gestiones" />
                <br />
                <div className="d-flex flex-column ml-3">
                    <div className="d-flex flex-row  mt-3 mb-3">
                        <Link to="/gestiones/nuevo" className="btn btn-danger">
                            Nuevo
                        </Link>
                    </div>
                    <Grid data={data} page={page} sizePerPage={10} loading={loader} onPageChange={onPageChange}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="left"
                            width="120"
                            isKey
                            dataFormat={standardActions({ eliminar, editar: '/gestiones' })}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn dataAlign="left" width="250" dataField="nombre">
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat={data => {
                                if (data) return data[0].toUpperCase() + data.slice(1).toLowerCase();
                            }}
                            dataAlign="left"
                            width="250"
                            dataField="tipo"
                        >
                            TIPO
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListarGestion;
