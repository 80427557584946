import React from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import PropTypes from 'prop-types';
import Caret from '../../../../../assets/img/Caret-abajo.png';

const Collapse = props => {
    const [dropDownProperties, setDropDownProperties] = React.useState({});

    const toggleDropDown = property => {
        setDropDownProperties(s => ({ ...s, [property]: !s[property] }));
    };

    const stateProperty = `dropdown_${props.keyValue}`;
    return (
        <React.Fragment>
            <button
                className="btn bg-gris w-100 px-0"
                id={`toggler-${props.keyValue}`}
                onClick={() => {
                    toggleDropDown(`dropdown_${props.keyValue}`);
                }}
            >
                <div className="d-flex justify-content-between">
                    <span className="pl-3">{props.nombre}</span>
                    <img
                        src={Caret}
                        alt="Caret"
                        width="20px"
                        height="20px"
                        className="mr-1"
                        style={
                            dropDownProperties[stateProperty]
                                ? {
                                      transition: 'all 0.2s',
                                      transform: 'rotate(0.5turn)',
                                  }
                                : { transition: 'all 0.2s' }
                        }
                    />
                </div>
            </button>
            <UncontrolledCollapse toggler={`#toggler-${props.keyValue}`}>{props.children}</UncontrolledCollapse>
        </React.Fragment>
    );
};

Collapse.propTypes = {
    keyValue: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
};

export default Collapse;
