import React from 'react';
import GoogleLogin from 'react-google-login';
import Cookie from 'js-cookie';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import './main.css';
import './login.css';
import { GOOGLE_OAUTH_ID } from '../../../../utility/constants';

import ImgLeft from '../../../../../assets/img/bg-1_login.jpg';
import LogoX from '../../../../../assets/img/0-Oficial-Rojo-Seguros-G_T.svg';

export default function Login(props) {
    const [loader, setLoader] = React.useState(false);

    const onGoogleSuccess = googleData => {
        const cookieGyt = Cookie.get('iPlanetDirectoryPro');
        function login() {
            googleData['cookieGyt'] = { iPlanetDirectoryPro: cookieGyt };
            setLoader(true);
            api.post('user/auth_google', googleData)
                .then(response => {
                    setLoader(false);
                    props.history.push('/');
                })
                .catch(error => {
                    if (error && error.detail) {
                        NotificationManager.error(error.detail, 'ERROR', 5000);
                    }
                })
                .finally(() => setLoader(false));
        }

        login();
    };
    const onGoogleFailure = error => {
        console.error(error);
    };

    return (
        <div className="blue-gradient-bg">
            <div className="center-bx">
                <div className="container">
                    <div className="row justify-content-center align-self-center">
                        <div className="size-100">
                            <div className="row justify-content-center">
                                <div className="size-100">
                                    <LoadMask loading={loader} blur>
                                        <div className="wrap d-lg-flex ">
                                            <div className="img left">
                                                <img src={ImgLeft} className="rel size-100-100" alt="Imagen genérica" />
                                            </div>
                                            <div className="login-wrap p-4 p-md-5">
                                                <div className="img div-bord rojo"></div>
                                                <div className="d-flex">
                                                    <div className="w-100 text-center">
                                                        <br></br>
                                                        <img src={LogoX} alt="" width="220" />
                                                    </div>
                                                </div>
                                                <div className="d-flex py-5 px-5 mt-4">
                                                    <GoogleLogin
                                                        clientId={GOOGLE_OAUTH_ID}
                                                        onSuccess={onGoogleSuccess}
                                                        onFailure={onGoogleFailure}
                                                        cookiePolicy={'single_host_origin'}
                                                        render={renderProps => (
                                                            <button
                                                                onClick={renderProps.onClick}
                                                                disabled={renderProps.disabled}
                                                                className="btn button-ingreso rounded flex-1"
                                                            >
                                                                <i className="d-none d-lg-block fa fa-google"></i>{' '}
                                                                Google
                                                            </button>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
