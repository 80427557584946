import React from 'react';
import { NotificationManager } from 'react-notifications';
import IconoExito from '../../../../../../assets/img/exito.png';
import { api } from 'api';

const PasoFinal = props => {
    const { setLoader, paso, informe, history } = props;

    const finalizarInforme = () => {
        setLoader(true);
        api.post(`asignacion-costos/copiar_configuracion`, {}, { informe: props.item.id })
            .then(() => {
                api.post(`informe/${props.item.id}/carga_completa`)
                    .then(() => {
                        props.history.push(`/informe/${props.item.id}/detalle`);
                        NotificationManager.success(
                            'Carga de los datos del informe se finalizo exitosamente!.',
                            'Éxito',
                            5000
                        );
                    })
                    .catch(() => {
                        NotificationManager.error(
                            'Error al intentar cambiar el estado del informe a CARGA COMPLETA.',
                            'Error',
                            5000
                        );
                        setLoader(false);
                    });
            })
            .catch(() => {
                NotificationManager.error('Error al copiar la configuración del archivo base.', 'Error', 5000);
                setLoader(false);
            });
    };
    const regresar = () => {
        const anterior = paso - 1;
        history.push(`/informe/${informe.id}/${anterior}`);
    };
    return (
        <React.Fragment>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br className="d-none d-md-flex" />
            <br className="d-none d-md-flex" />
            <br className="d-none d-md-flex" />
            <div className="d-flex flex-1 flex-column">
                <div className="d-flex m-auto">
                    <img className="success-icon" src={IconoExito} />
                </div>
                <div className="d-flex m-auto pt-4">
                    <span className="success-text text-center">
                        Felicidades ha completado en la carga del informe exitosamente
                    </span>
                </div>
                <div className="d-flex m-auto pt-4">
                    <button className="btn btn-dark mr-2" onClick={regresar} type="button">
                        Regresar
                    </button>
                    <button className="btn btn-danger ml-2" onClick={finalizarInforme} type="button">
                        Aceptar
                    </button>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </React.Fragment>
    );
};

export default PasoFinal;
