import React from 'react';
import UploadDriver from '../../../../Utils/UploadDriver';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PasoIntermedio from './PasoIntermedio';
import PasoFinal from './PasoFinal';
import IconoAlerta from '../../../../../../../assets/img/alerta-xl.png';
import useCopiarDriver from '../useCopiarDriver';
import { api } from 'api';
import _ from 'lodash';
import './paso4.css';

const dataSeed = {
    results: [],
    count: 0,
};

const EncuestaFte = props => {
    const [driverTitle, setDriverTitle] = React.useState('Datos de encuesta FTE');
    // EN ESTA VARIABLES GUARDAREMOS ERRORES
    const [errores, setErrores] = React.useState(dataSeed);
    // EN ESTA VARIABLES GUARDAREMOS TODAS LA ENCUESTAS DONDE LOS USUARIOS NO COMPLETARON EL 100%
    const [incompletas, setIncompletas] = React.useState(dataSeed);
    // EN ESTA VARIABLE GUARDAREMOS TODOS LOS USUARIOS QUE ESTÁN EN PESO SALARIAL PERO NO HAN COMPLETADO LA ENCUESTA
    const [sinEncuesta, setSinEncuesta] = React.useState(dataSeed);
    // EN ESTQ VARIABLE SE GUARDARAN LOS USUARIOS QUE ESTAN EN EL ARCHIVO DE ENCUESTAS PERO NO EN EL PESO SALARIAL
    const [despedidos, setDespedidos] = React.useState(dataSeed);
    const [nuevos, setNuevos] = React.useState(dataSeed);
    // SE HABILITA EL PASO INTERMEDIO SI EXISTE UNA RESPUESTA DEL BACKEND
    const [intermedio, setIntermedio] = React.useState(false);
    // SE HABILITA EL PASO FINAL SI TODO ESTA BIEN
    const [final, setFinal] = React.useState(false);
    // USAMOS ESTA VARIABLE  PARA SABER SI CONSULTAR EL ESTADO DE LA O NO
    const [consultar, setConsultar] = React.useState(false);
    const { setLoader, paso, informe, history } = props;

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });
    const next = async () => {
        if (intermedio) {
            if (comprobarData()) {
                const mySwal = withReactContent(Swal);
                await mySwal
                    .fire({
                        title: 'Al aceptar estarás guardando las actividades con los datos agregados',
                        text: '¿Deseas continuar?',
                        iconHtml: <img src={IconoAlerta} />,
                        showCancelButton: true,
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar',
                        reverseButtons: true,
                    })
                    .then(async res => {
                        if (res.value) {
                            await registrar_actividades();
                        }
                    });
            }
        } else {
            const siguiente = paso + 1;
            history.push(`/informe/${informe.id}/${siguiente}`);
        }
    };
    const prev = () => {
        if (!final && !intermedio) {
            const anterior = paso - 1;
            history.push(`/informe/${informe.id}/${anterior}`);
        } else {
            setFinal(false);
            setIntermedio(false);
            setNextButton(s => ({ ...s, isVisible: false }));
        }
    };

    const comprobarData = () => {
        const repetidos = [];
        const no_asignados = [];
        const copia_nuevos = [...nuevos.results];
        copia_nuevos.forEach(item => {
            let count = 0;
            if (item.ID) {
                copia_nuevos.forEach(item2 => {
                    if (item2.ID && item.ID === item2.ID) count += 1;
                });
            }
            if (count > 1) repetidos.push(item);
        });
        copia_nuevos.forEach(item => {
            if ((item.ID && !item.ID.trim()) || !item.ID) no_asignados.push(item);
            else if ((item.GESTION && !item.GESTION.trim()) || !item.GESTION) no_asignados.push(item);
            else if ((item.ACTIVIDAD && !item.ACTIVIDAD.trim()) || !item.ACTIVIDAD) no_asignados.push(item);
        });
        const mySwal = withReactContent(Swal);
        if (repetidos.length > 0) {
            mySwal.fire({
                title: 'Error!',
                text: 'Uno o mas actividades tienen el código repetido',
                iconHtml: <img src={IconoAlerta} />,
            });
        }
        if (no_asignados.length > 0) {
            mySwal.fire({
                title: 'Error!',
                text: `Tienes ${no_asignados.length} actividades sin completar`,
                iconHtml: <img src={IconoAlerta} />,
            });
        }
        if (repetidos.length > 0 || no_asignados.length > 0) return false;
        return true;
    };

    const cargar = async (data, files = []) => {
        setLoader(true);
        await api
            .postAttachments('encuesta-actividades/cargar_archivo', data, files)
            .then(res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al cargar encuesta FTE';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const guardar = async data => {
        await setLoader(true);
        await api
            .post('encuesta-actividades', data)
            .then(res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al guardar encuesta FTE';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const registrar_actividades = async () => {
        let actividades = [...nuevos.results];
        setLoader(true);

        await api
            .post('encuesta-actividades/registrar_actividades', { actividades: actividades })
            .then(async response => {
                await guardar({ informe: informe.id });
            })
            .catch(error => {
                let mensajeError = 'Error al guardar actividades';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const consultarEstado = () => {
        api.get('task/estado', { informe: informe.id, driver: 'ENCUESTA_ACTIVIDADES' })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    const siguiente = paso + 1;
                    props.next(informe.id, siguiente).then();
                    setFinal(true);
                    setIntermedio(false);
                    setConsultar(false);
                    setNextButton(s => ({ ...s, isVisible: true }));
                    NotificationManager.success('Archivo de encuesta FTE cargado exitosamente', 'Éxito', 4000);
                } else if (response.status === 'FAILURE') {
                    if (response.result && response.result.nuevos) {
                        setIntermedio(true);
                        setFinal(false);
                        setNuevos({ results: response.result.nuevos, count: response.result.nuevos.length });
                        setNextButton(s => ({ ...s, isVisible: true }));
                    } else if (
                        response.result &&
                        (response.result.incompletas ||
                            response.result.sin_encuesta ||
                            response.result.despedidos ||
                            response.result.errores)
                    ) {
                        setIntermedio(true);
                        setFinal(false);
                        setIncompletas({
                            results: response.result.incompletas,
                            count: response.result.incompletas.length,
                        });
                        setSinEncuesta({
                            results: response.result.sin_encuesta,
                            count: response.result.sin_encuesta.length,
                        });
                        setDespedidos({
                            results: response.result.despedidos,
                            count: response.result.despedidos.length,
                        });
                        setErrores({
                            results: response.result.errores,
                            count: response.result.errores.length,
                        });
                        setNuevos({ results: [], count: 0 });
                        setNextButton(s => ({ ...s, isVisible: false }));
                    } else if (
                        response.result &&
                        response.result.exc_message &&
                        _.isString(response.result.exc_message)
                    ) {
                        setNextButton(s => ({ ...s, isVisible: false }));
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                        setNextButton(s => ({ ...s, isVisible: false }));
                    } else {
                        NotificationManager.error('Ocurrió un error al cargar archivo de encuesta FTE', 'Error', 5000);
                        setNextButton(s => ({ ...s, isVisible: false }));
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = 'La tarea que procesa el driver no se pudo ejecutar o fallo.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 5000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 15000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    React.useEffect(() => {
        return setLoader(false);
    }, []);

    const { copiarDriver } = useCopiarDriver(
        informe,
        'COPIA_ENCUESTA_ACT',
        paso,
        setLoader,
        setNextButton,
        setFinal,
        props.next
    );

    const onCopy = async () => {
        await copiarDriver('encuesta-actividades/copiar_datos');
    };
    const onChangeFile = async file => {
        const files = [];
        files.push({ name: 'archivo', file: file });
        await cargar({ informe: informe.id }, files);
    };

    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="Datos de encuesta FTE"
                totalStep={props.totalPasos}
                nextButton={{ ...nextButton, next: next }}
                prevButton={{ ...prevButton, prev: prev }}
            />

            {final && <PasoFinal setLoader={setLoader} informe={informe} />}
            {intermedio && (
                <PasoIntermedio
                    incompletas={incompletas}
                    sinEncuesta={sinEncuesta}
                    despedidos={despedidos}
                    errores={errores}
                    nuevos={nuevos}
                    setNuevos={setNuevos}
                />
            )}
            {!final && !intermedio && (
                <UploadDriver driverTitle={driverTitle} onCopy={onCopy} onChange={onChangeFile} />
            )}
        </React.Fragment>
    );
};

export default EncuestaFte;
