import React, { useRef } from 'react';
import './Uploader.css';
import PropTypes from 'prop-types';
import fileIcon from '../../../../../assets/img/file-archive.png';

const Upload = props => {
    const [uploaded, setUploaded] = React.useState(false);
    const input = useRef();
    const onFileChange = (e, file) => {
        file = file || e.target.files[0];
        const excelType = '.xlsx|.xls|.sheet';
        if (!!file.type.match(excelType)) {
            setUploaded(true);
            props.onChange(file);
        }
    };
    const onClick = () => {
        input.current.click();
    };

    const onDrop = e => {
        e.preventDefault();
        onFileChange(e, e.dataTransfer.files[0]);
    };
    const onDragOver = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div key="label" className="upload-container" onDrop={onDrop} onDragOver={onDragOver}>
                <br className="d-none d-sm-flex" />
                <br />
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    ref={input}
                ></input>
                {props.file ? (
                    <div className="d-flex flex-row justify-content-center align-items-center px-2 mb-2">
                        <img src={fileIcon} className="uploaded-file-icon mr-2" />
                        <p className="uploaded-file-text m-0">{props.file.name}</p>
                    </div>
                ) : (
                    <p className="upload-text m-0 pb-2">
                        {props.uploaderText ? props.uploaderText : 'Arrastra o haz clic en el botón para cargar excel'}
                    </p>
                )}
                <div className="d-flex justify-content-center">
                    <button className={`btn  ${uploaded ? 'btn-dark' : 'btn-danger'}`} type="button" onClick={onClick}>
                        {uploaded ? 'Volver a cargar' : 'Haz click aquí'}
                    </button>
                </div>
                <br className="d-none d-sm-flex" />
                <br />
            </div>
        </React.Fragment>
    );
};

Upload.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default Upload;
