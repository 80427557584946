import React from 'react';
import Cabecera from '../../Utils/Cabeceras/CabeceraInforme';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import AsignacionCosto from './configuracion/AsignacionCosto';
import DistribuirActividades from './configuracion/DistribuirActividades';
import DistribuirCompuestas from './configuracion/DistribuirCompuestas';
import OrdenGestiones from './configuracion/OrdenGestiones';
import DistribuirGestiones from './configuracion/DistribuirGestiones/DistribuirGestiones';
import DistribucionFinalizada from './configuracion/DistribucionFinalizada';
import { getMes } from '../../Utils/utilidades';
import './distribuicion.css';

const Distribucion = props => {
    React.useEffect(() => {
        const { leer, item, history } = props;
        const { id } = props.match.params;
        async function leerInforme() {
            if (!item.id || item.id !== parseInt(id)) {
                await leer(id).then();
            }
        }
        leerInforme().then();
    }, [props.match.params]);

    const [paso, setPaso] = React.useState(1);
    const [totalPasos, setTotalPasos] = React.useState(5);

    const { item } = props;
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera
                    titulo={item.anio}
                    informe={item}
                    setLoader={props.setLoader}
                    subtitulo={getMes(item.mes)}
                ></Cabecera>

                <LoadMask loading={props.loader} blur>
                    {paso === 1 && (
                        <AsignacionCosto
                            paso={paso}
                            setPaso={setPaso}
                            totalPasos={totalPasos}
                            informe={item}
                            {...props}
                        />
                    )}
                    {paso === 2 && (
                        <OrdenGestiones
                            paso={paso}
                            setPaso={setPaso}
                            totalPasos={totalPasos}
                            informe={item}
                            {...props}
                        />
                    )}
                    {paso === 3 && (
                        <DistribuirGestiones
                            paso={paso}
                            setPaso={setPaso}
                            totalPasos={totalPasos}
                            informe={item}
                            {...props}
                        />
                    )}

                    {paso === 4 && (
                        <DistribuirActividades
                            paso={paso}
                            setPaso={setPaso}
                            totalPasos={totalPasos}
                            informe={item}
                            {...props}
                        />
                    )}

                    {paso === 5 && (
                        <DistribuirCompuestas
                            paso={paso}
                            setPaso={setPaso}
                            totalPasos={totalPasos}
                            informe={item}
                            {...props}
                        />
                    )}
                    {paso >= 6 && (
                        <DistribucionFinalizada
                            paso={paso}
                            setPaso={setPaso}
                            totalPasos={totalPasos}
                            informe={item}
                            {...props}
                        />
                    )}
                </LoadMask>

                <div id="draggable-portal" />
            </div>
        </React.Fragment>
    );
};

export default Distribucion;
