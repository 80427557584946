import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/abc/abc';
import CrearEditarABC from './CrearEditar';

const ms2p = state => {
    return {
        ...state.abc,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditarABC);
