import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import errorIcon from '../../../../../../../assets/img/error.png';

const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { errores } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {errores && errores.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores.results, pageErrores),
                            count: errores.count,
                        }}
                        onPageChange={setPageErrores}
                        sizePerPage={10}
                        page={pageErrores}
                    >
                        <TableHeaderColumn
                            dataField="CENTRO_COSTO"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={errorIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn width="50rem" dataField="ERROR">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn width="10rem" dataField="CODPROD">
                            CODIGO PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn width="20rem" dataField="CANAL_COMERCIAL">
                            CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn width="20rem" dataField="RAMO_COMERCIAL">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn width="15rem" dataField="RAMO_CONTABLE">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
