import React from 'react';
import Grid from '../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../Utils/Grid/StandardActions';

import Cabecera from '../../Utils/Cabeceras/CabeceraProyecto';
import { Link } from 'react-router-dom';

const ListarABC = props => {
    React.useEffect(() => {
        props.listar();
    }, []);

    const { data, loader, eliminar, page, listar: onPageChange } = props;

    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Catalogo ABC" />
                <br />
                <div className="d-flex flex-column ml-3">
                    <div className="d-flex flex-row  mt-3 mb-3">
                        <Link to="/abc/nuevo" className="btn btn-danger">
                            Nuevo
                        </Link>
                    </div>
                    <Grid data={data} page={page} sizePerPage={10} loading={loader} onPageChange={onPageChange}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="left"
                            width="7rem"
                            isKey
                            dataFormat={standardActions({ eliminar, editar: '/abc' })}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn dataAlign="left" width="20rem" dataField="nombre">
                            DESCRIPCION
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListarABC;
