import React from 'react';
import { withRouter } from 'react-router-dom';
import { OPCIONES_ITEMS } from '../../../../utility/constants';

const SubMenu = props => {
    const onClickContainer = e => {
        const submenu = document.getElementById('submenu');
        if (e.target === submenu) {
            props.onClose();
        }
    };

    const listadoDrivers = OPCIONES_ITEMS.filter(item => item.tipo === 'DRIVER');
    return (
        <div id="submenu" className="contenedor-submenu" onClick={onClickContainer}>
            <div className="submenu-container">
                <div className="submenu-header pl-2 pt-1 bg-danger">
                    <span className="text-white">Drivers</span>
                </div>
                <ol className="submenu-list p-0">
                    {listadoDrivers.map(item => (
                        <li
                            key={item.label}
                            className="submenu-item"
                            onClick={() => {
                                props.history.push(`/drivers/${item.value}`);
                                props.closeMenu();
                                props.onClose();
                            }}
                        >
                            {item.label} - {item.value}
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default withRouter(SubMenu);
