import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import AlertIcon from '../../../../../../../assets/img/alerta.png';
import ErrorIcon from '../../../../../../../assets/img/error.png';

import './paso7.css';
const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);
    const [pageAlertas, setPageAlertas] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { errores, alertas } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {errores && errores.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <div className="custom-scroll-step-7">
                        <Grid
                            data={{
                                results: paginate(errores.results, pageErrores),
                                count: errores.count,
                            }}
                            onPageChange={setPageErrores}
                            sizePerPage={10}
                            page={pageErrores}
                            scrollTable
                        >
                            <TableHeaderColumn
                                dataField="CODPROD"
                                dataAlign="center"
                                isKey
                                width="7rem"
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={ErrorIcon} />;
                                }}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="ERROR" width="50rem">
                                ERROR
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                                CÓDIGO DE UNIDAD
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="20rem">
                                UNIDAD COMERCIAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODCANAL" width="10rem">
                                CÓDIGO DE CANAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CANAL_COMERCIAL" width="20rem">
                                CANAL COMERCIAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODINTER" width="10rem">
                                CÓDIGO DE INTERMEDIARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="INTERMEDIARIO" width="20rem">
                                INTERMEDIARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="RAMO_COMERCIAL" width="20rem">
                                RAMO COMERCIAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="RAMO_CONTABLE" width="20rem">
                                RAMO CONTABLE
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODPROD" width="10rem">
                                CÓDIGO DE PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="20rem">
                                PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="NUMPOL" width="10rem">
                                POLIZA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CLIENTE" width="25rem">
                                CLIENTE
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
            {alertas && alertas.count > 0 && (
                <React.Fragment>
                    <span className="text-dark">Registros con alertas</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <div className="custom-scroll-step-7">
                        <Grid
                            data={{
                                results: paginate(alertas.results, pageAlertas),
                                count: alertas.count,
                            }}
                            onPageChange={setPageAlertas}
                            sizePerPage={10}
                            page={pageAlertas}
                            scrollTable
                        >
                            <TableHeaderColumn
                                dataField="CODPROD"
                                dataAlign="center"
                                isKey
                                width="7rem"
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={AlertIcon} />;
                                }}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="ALERTA" width="50rem">
                                ALERTA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                                CÓDIGO DE UNIDAD
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="20rem">
                                UNIDAD COMERCIAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODCANAL" width="10rem">
                                CÓDIGO DE CANAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CANAL_COMERCIAL" width="20rem">
                                CANAL COMERCIAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODINTER" width="10rem">
                                CÓDIGO DE INTERMEDIARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="INTERMEDIARIO" width="20rem">
                                INTERMEDIARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="RAMO_COMERCIAL" width="20rem">
                                RAMO COMERCIAL
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="RAMO_CONTABLE" width="20rem">
                                RAMO CONTABLE
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CODPROD" width="10rem">
                                CÓDIGO DE PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="20rem">
                                PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="NUMPOL" width="10rem">
                                POLIZA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CLIENTE" width="25rem">
                                CLIENTE
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
