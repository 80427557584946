import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { standardActions } from '../../Utils/Grid/StandardActions';
import Grid from '../../Utils/Grid';

const TablaReferencias = props => {
    let { data, eliminar } = props;

    //data = data.map((item, index) => ({ ...item, numero: index + 1 }));

    return (
        <React.Fragment>
            <div className="d-flex p-3">
                <Grid data={{ results: data }} pagination={false}>
                    {/*<TableHeaderColumn dataField="id">ID</TableHeaderColumn>*/}
                    <TableHeaderColumn dataField="referencia" isKey>
                        Referencia
                    </TableHeaderColumn>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default TablaReferencias;
