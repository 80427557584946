import React, { Component } from 'react';
import Table from '../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Cabecera from '../Utils/Cabeceras/CabeceraBreadCrumbs';
import LoadMask from '../Utils/LoadMask/LoadMask';
import { NotificationManager } from 'react-notifications';
import { RenderNumber } from '../Utils/renderField/renderReadField';
import { MESES, OPCIONES_ITEMS } from '../../../utility/constants';
import moment from 'moment';
import { api } from 'api';

const dataSeed = {
    results: [],
    count: 0,
    meses: [],
};

const obtenerAnio = () => {
    const anioEnLocal = localStorage.getItem('year');
    if (anioEnLocal) return anioEnLocal;
    return moment().year();
};

const Drivers = props => {
    const [data, setData] = React.useState(dataSeed);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [anio, setAnio] = React.useState(obtenerAnio());

    React.useEffect(() => {
        obtener_driver(1, anio).then();
    }, [props.match.params]);

    async function obtener_driver(page, anio) {
        setLoading(true);
        const id_driver = props.match.params.id_driver;
        const params = { driver: id_driver, anio: anio, page_size: 25, page: page };
        await api
            .get('/drivers/detalles_oficiales', params)
            .then(response => {
                setData(response);
                setPage(page);
            })
            .catch(error => {
                NotificationManager.error('No se pudo obtener la informacion del driver solicitado', 'ERROR', 6000);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onChangeAnio = e => {
        setAnio(e.target.value);
        obtener_driver(1, e.target.value).then();
    };

    const obtener_nombre_driver = () => {
        const id_driver = props.match.params.id_driver;
        let driver = OPCIONES_ITEMS.find(m => m.value === id_driver);
        if (driver && driver.label) return driver.label;
        return '';
    };

    return (
        <div className="card px-4 my-3 pb-4">
            <Cabecera anio={anio} onChangeAnio={onChangeAnio} />
            <span className="py-3">{obtener_nombre_driver()}</span>

            <LoadMask loading={loading} blur>
                <Table
                    data={data}
                    scrollTable
                    sizePerPage={25}
                    page={page}
                    onPageChange={siguiente => obtener_driver(siguiente, anio).then()}
                >
                    <TableHeaderColumn width="20rem" dataField="ramo_comercial">
                        RAMO COMERCIAL
                    </TableHeaderColumn>
                    <TableHeaderColumn width="10rem" dataField="codigo_canal">
                        CODIGO CANAL
                    </TableHeaderColumn>
                    <TableHeaderColumn width="20rem" dataField="canal">
                        CANAL COMERCIAL
                    </TableHeaderColumn>
                    <TableHeaderColumn width="10rem" dataField="codigo_unidad">
                        CODIGO UNIDAD
                    </TableHeaderColumn>
                    <TableHeaderColumn width="20rem" dataField="unidad">
                        UNIDAD COMERCIAL
                    </TableHeaderColumn>
                    <TableHeaderColumn width="10rem" dataField="codigo_intermediario">
                        CODIGO INTERMEDIARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn width="20rem" dataField="intermediario">
                        INTERMEDIARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn width="10rem" dataField="codigo_producto">
                        CODIGO PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn width="20rem" dataField="producto" isKey>
                        PRODUCTO
                    </TableHeaderColumn>
                    {data &&
                        data.meses &&
                        data.meses.map((mes, index) => {
                            return (
                                <TableHeaderColumn
                                    key={`${mes}`}
                                    width="10rem"
                                    dataField={`${mes}`}
                                    dataFormat={value => (
                                        <RenderNumber className="remove-style" decimalScale={2} value={value} />
                                    )}
                                >
                                    {MESES.find(m => m.value === mes).label.toLocaleUpperCase()}
                                </TableHeaderColumn>
                            );
                        })}
                </Table>
            </LoadMask>
        </div>
    );
};

export default Drivers;
