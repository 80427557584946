import React from 'react';
import { Table } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { RenderNumber } from '../../../../Utils/renderField/renderReadField.js';
import { api } from 'api';

export default function TablaProyectos(props) {
    const [proyectos, setProyectos] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const { listadoDrivers } = props;

    React.useEffect(() => {
        obtenerDetalleActividad().then();
    }, []);

    const obtenerDetalleActividad = () => {
        return api.get('actividades-compuestas/detalle_actividad', { ...props.row }).then(response => {
            if (response && response.proyectos) {
                setProyectos(response.proyectos);
                let totalPorcentaje = response.proyectos.reduce((prev, item) => {
                    const porcentaje = parseFloat(item.porcentaje);
                    if (!Number.isNaN(porcentaje)) {
                        return prev + porcentaje;
                    } else {
                        return prev;
                    }
                }, 0);

                totalPorcentaje = totalPorcentaje.toFixed(2);
                setTotal(totalPorcentaje);
            }
        });
    };

    const guardarPorcentajes = (actividad, proyectos) => {
        api.post('actividades-compuestas/actualizar_porcentajes', { proyectos }, { ...actividad }).then(() => {
            obtenerDetalleActividad().then();
            props.listarActividades(props.page);
        });
    };
    const enCambioPorcentaje = (value, proyecto) => {
        const copia_proyectos = proyectos.map(item => {
            if (item.proyecto === proyecto.proyecto) item.porcentaje = value || 0;
            return item;
        });

        let totalPorcentaje = proyectos.reduce((prev, item) => {
            const porcentaje = parseFloat(item.porcentaje);
            if (!Number.isNaN(porcentaje)) {
                return prev + porcentaje;
            } else {
                return prev;
            }
        }, 0);

        totalPorcentaje = totalPorcentaje.toFixed(2);
        setTotal(totalPorcentaje);

        if (totalPorcentaje == 100) {
            guardarPorcentajes(props.row, copia_proyectos);
        }
        setProyectos(copia_proyectos);
    };

    const enCambioDriver = (value, proyecto) => {
        const actividades = [];
        const actividad = { ...props.row };
        actividad['proyecto'] = proyecto.proyecto;
        actividad['distribucion'] = value;
        actividades.push(actividad);
        api.post('actividades-compuestas/actualizar_actividades', { actividades }).then(() => {
            obtenerDetalleActividad().then();
            props.listarActividades(props.page);
        });
    };

    return (
        <React.Fragment>
            <br />
            <div className="d-flex justify-content-end ml-auto p-2 bd-highlight">
                <div className={`${total != 100 ? 'text-percent-error' : 'text-percent'}  m-0`}>
                    Distribución{' '}
                    <RenderNumber
                        className={`${total != 100 ? 'text-percent-error' : 'text-percent'}  m-0`}
                        suffix={'%'}
                        value={total}
                        decimalScale={2}
                    />{' '}
                    de 100.00%
                </div>
            </div>

            <Table className="custom-table-projects">
                <thead>
                    <tr>
                        <th width="20%">PROYECTO</th>
                        <th width="30%">PORCENTAJE</th>
                        <th width="40%">DISTRIBUCIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    {proyectos.map(entry => {
                        return (
                            <tr key={entry.proyecto}>
                                <td width="20%">
                                    <span className="pl-2 remove-style">{entry.nombre}</span>
                                </td>
                                <td width="30%">
                                    <NumberFormat
                                        placeholder="Ingrese porcentaje"
                                        value={entry.porcentaje || null}
                                        className="form-control custom-number-field ml-0 ml-md-2"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        suffix=" %"
                                        onValueChange={value => enCambioPorcentaje(value.floatValue, entry)}
                                    />
                                </td>
                                <td width="40%">
                                    <select
                                        value={entry.distribucion || '1'}
                                        className="custom-select-table"
                                        style={{ width: '20rem' }}
                                        onChange={e => {
                                            const value = e.target.value;
                                            enCambioDriver(value, entry);
                                        }}
                                    >
                                        {!entry.distribucion && (
                                            <option key={1} value="1">
                                                Seleccione
                                            </option>
                                        )}

                                        {listadoDrivers.map(item => (
                                            <option key={item.value} value={item.value}>
                                                {item.value} - {item.label}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <br />
        </React.Fragment>
    );
}
