import React, { useEffect, useState } from 'react';
import ReactSpinner from '../../../../Utils/Spinner';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import WaitingIcon from '../../../../../../../assets/img/espera.png';
import AlertIcon from '../../../../../../../assets/img/Alerta@2x.png';
import ErrorIcon from '../../../../../../../assets/img/error.png';
import ExpandComponent from './ExpandPasoUno';
import { OPCIONES_ITEMS, LISTADO_CATALOGOS } from '../../../../../../utility/constants';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import { api } from 'api';

import Stepper from '../../../../Utils/Stepper';

function rowClassNameFormat(row, rowIdx) {
    return rowIdx % 2 === 0 ? 'td-column-function-even-example' : 'td-column-function-odd-example';
}

const INICIALIZADO = 10;
const NO_INICIALIZADO = 20;
const FINALIZADO = 30;
const ERROR = 40;
// ESTADOS CELERY
const PENDING = 'PENDING';
const RECEIVED = 'RECEIVED';
const STARTED = 'STARTED';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

const dataSeedDrivers = OPCIONES_ITEMS.filter(
    item => item.carga === 'automatica' && item.tipo === 'DRIVER' && item.value != 'DRIVER_25'
).map(item => ({
    nombre: item.value,
    descripcion: item.label,
}));

const dataSeedCatalogos = LISTADO_CATALOGOS;

const DriversAutomaticos = props => {
    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });
    const [dataDrivers, setDataDrivers] = useState(dataSeedDrivers);
    const [dataCatalogos, setDataCatalogos] = useState(dataSeedCatalogos);

    const [consultarCatalogos, setConsultarCatalogos] = useState(false);
    const [consultarDrivers, setConsultarDrivers] = useState(false);
    const [verBtnDriversFallidos, setVerBtnDriversFallidos] = useState(false);

    const { setLoader, paso, informe, history } = props;

    const next = async () => {
        const { next } = props;
        const siguiente = paso + 1;
        await next(informe.id, siguiente);
        history.push(`/informe/${informe.id}/${siguiente}`);
    };
    const prev = () => {
        history.push(`/historial/${informe.anio}/${informe.mes}`);
    };

    useEffect(() => {
        async function inicializar() {
            await consultarEstadoCatalogos();
        }
        setLoader(true);
        inicializar().then(() => setLoader(false));
    }, []);

    const ejecutaTareasCatalogos = async () => {
        await api
            .post('catalogos/cargar', {}, { informe: informe.id })
            .then(response => {
                NotificationManager.success('Se inicio la extraccion de los catalogos  ', 'Exito', 5000);
                consultarEstadoCatalogos().then();
            })
            .catch(() => {
                NotificationManager.error('No se pudo iniciar la extraccion de los catalogos  ', 'Error', 5000);
            });
    };

    const ejecutaTareasDrivers = async () => {
        await api
            .post('driver/automatico/cargar', {}, { informe: informe.id })
            .then(response => {
                NotificationManager.success('Se inicio la extraccion de los drivers', 'Exito', 5000);
                consultarEstadoCatalogos().then();
            })
            .catch(() => {
                NotificationManager.error('No se pudo iniciar la extraccion de los drivers', 'Error', 5000);
            });
    };

    const consultarEstadoCatalogos = async () => {
        await api.get('catalogos/estado', { informe: informe.id }).then(res => {
            if (res && res.estado) {
                switch (res.estado) {
                    case INICIALIZADO:
                        setDataCatalogos(res.catalogos);
                        setConsultarCatalogos(true);
                        break;
                    case NO_INICIALIZADO:
                        ejecutaTareasCatalogos().then();
                        break;
                    case FINALIZADO:
                        setDataCatalogos(res.catalogos);
                        setConsultarCatalogos(false);
                        consultarEstadoDrivers().then();
                        break;
                    case ERROR:
                        setDataCatalogos(res.catalogos);
                        setConsultarCatalogos(false);
                        Swal.fire({
                            title: 'Existen catalogos fallidos !',
                            text: '¿Desea ejecutarlos de nuevo?',
                            showCancelButton: true,
                            confirmButtonText: 'Aceptar',
                            cancelButtonText: 'Cancelar',
                            allowEnterKey: false,
                            allowOutsideClick: false,
                        }).then(async res => {
                            if (res.value) {
                                ejecutaTareasCatalogos().then(() => setConsultarCatalogos(true));
                            }
                        });
                        break;
                }
            }
        });
    };

    const consultarEstadoDrivers = async () => {
        await api.get('driver/automatico/estado', { informe: informe.id }).then(res => {
            if (res && res.estado) {
                switch (res.estado) {
                    case INICIALIZADO:
                        setDataDrivers(res.drivers);
                        setConsultarDrivers(true);
                        setVerBtnDriversFallidos(false);
                        break;
                    case NO_INICIALIZADO:
                        ejecutaTareasDrivers().then();
                        setVerBtnDriversFallidos(false);
                        break;
                    case FINALIZADO:
                        setDataDrivers(res.drivers);
                        setConsultarDrivers(false);
                        setVerBtnDriversFallidos(false);
                        setNextButton(s => ({ ...s, isVisible: true }));
                        break;
                    case ERROR:
                        setDataDrivers(res.drivers);
                        setConsultarDrivers(false);
                        setVerBtnDriversFallidos(true);
                        Swal.fire({
                            title: 'Existen drivers fallidos !',
                            text: '¿Desea ejecutarlos de nuevo?',
                            showCancelButton: true,
                            confirmButtonText: 'Aceptar',
                            cancelButtonText: 'Cancelar',
                            allowOutsideClick: false,
                            allowEnterKey: false,
                        }).then(async res => {
                            if (res.value) {
                                ejecutaTareasDrivers().then(() => setConsultarDrivers(true));
                            }
                        });
                        break;
                }
            }
        });
    };

    let interval;

    React.useEffect(() => {
        if (consultarCatalogos) {
            interval = setInterval(() => {
                consultarEstadoCatalogos().then();
            }, 15000);
        } else if (consultarDrivers) {
            interval = setInterval(() => {
                consultarEstadoDrivers().then();
            }, 15000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultarCatalogos, consultarDrivers]);

    return (
        <React.Fragment>
            <div>
                <Stepper
                    step={props.paso}
                    stepName="Carga Automática de información"
                    totalStep={props.totalPasos}
                    nextButton={{ ...nextButton, next: next }}
                    prevButton={{ ...prevButton, prev: prev }}
                />
                <br />
                <br />
                {verBtnDriversFallidos && (
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-primary"
                            onClick={async () => ejecutaTareasDrivers().then(() => setConsultarDrivers(true))}
                        >
                            Volver a ejecutar drivers fallidos
                        </button>
                    </div>
                )}
                <label className="nombre-tabla">Catalogo</label>
                <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                <Grid
                    data={{ results: dataCatalogos, count: dataCatalogos.length }}
                    trClassName={rowClassNameFormat}
                    expandableRow={() => {
                        return true;
                    }}
                    expandComponent={ExpandComponent}
                    expandColumnOptions={{
                        expandColumnVisible: false,
                        columnWidth: 45,
                    }}
                    pagination={false}
                    isColor={'White'}
                >
                    <TableHeaderColumn
                        dataField="estado"
                        width="7rem"
                        dataAlign="center"
                        dataFormat={data => {
                            if ([PENDING, STARTED, RECEIVED].includes(data)) return <ReactSpinner />;
                            else if (data === SUCCESS) return <img className="column-data-icon" src={OkIcon} />;
                            else if (data === FAILURE) return <img className="column-data-icon" src={ErrorIcon} />;
                            else return <img className="column-data-icon" src={WaitingIcon} />;
                        }}
                    >
                        Estado
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        width="15rem"
                        isKey
                        dataFormat={value => {
                            if (value) return value.replace('CA_', '');
                            return value;
                        }}
                    >
                        Catalogo
                    </TableHeaderColumn>
                </Grid>
                <br />
                <br />
                <label className="nombre-tabla">Drivers</label>
                <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                <Grid
                    data={{ results: dataDrivers, count: dataDrivers.length }}
                    trClassName={rowClassNameFormat}
                    expandableRow={() => {
                        return true;
                    }}
                    expandComponent={ExpandComponent}
                    expandColumnOptions={{
                        expandColumnVisible: false,
                        columnWidth: 45,
                    }}
                    pagination={false}
                    isColor={'White'}
                >
                    <TableHeaderColumn
                        dataField="estado"
                        width="7rem"
                        dataAlign="center"
                        pagination="False"
                        dataFormat={(value, row) => {
                            if ([PENDING, STARTED, RECEIVED].includes(value)) return <ReactSpinner />;
                            else if (row.estado === SUCCESS && row.result.length === 0)
                                return <img className="column-data-icon" src={OkIcon} />;
                            else if (row.estado === SUCCESS && row.result.length > 0)
                                return <img className="column-data-icon" src={AlertIcon} />;
                            else if (value === FAILURE) return <img className="column-data-icon" src={ErrorIcon} />;
                            else return <img className="column-data-icon" src={WaitingIcon} />;
                        }}
                    >
                        Estado
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="nombre" width="10rem" isKey>
                        Archivo
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="descripcion" width="35rem">
                        Nombre
                    </TableHeaderColumn>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default DriversAutomaticos;
