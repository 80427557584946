import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import AlertIcon from '../../../../../../../assets/img/alerta.png';
import ErrorIcon from '../../../../../../../assets/img/error.png';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField.js';

const PasoIntermedio = props => {
    const [pageQ, setPageQ] = React.useState(1);
    const [pageD, setPageD] = React.useState(1);
    const [pageErroresQ, setPageErroresQ] = React.useState(1);
    const [pageErroresD, setPageErroresD] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { alertasQ, alertasD, erroresQ, erroresD } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {erroresQ && erroresQ.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores archivo en quetzales</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(erroresQ.results, pageErroresQ),
                            count: erroresQ.count,
                        }}
                        onPageChange={setPageErroresQ}
                        sizePerPage={10}
                        page={pageErroresQ}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={ErrorIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="50rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="20rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="20rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="20rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="20rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="20rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="20rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="INFRAESTRUCTURA"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            INFRAESTRUCTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="TOTAL"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            TOTAL
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {erroresD && erroresD.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores archivo en dolares</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(erroresD.results, pageErroresD),
                            count: erroresD.count,
                        }}
                        onPageChange={setPageErroresD}
                        sizePerPage={10}
                        page={pageErroresD}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={ErrorIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="50rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="20rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="20rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="20rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="20rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="20rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="20rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="INFRAESTRUCTURA"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            INFRAESTRUCTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="TOTAL"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            TOTAL
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {alertasQ && alertasQ.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con alertas archivo en quetzales</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(alertasQ.results, pageQ),
                            count: alertasQ.count,
                        }}
                        onPageChange={setPageQ}
                        sizePerPage={10}
                        page={pageQ}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ALERTA" width="50rem">
                            ALERTA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="20rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="20rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="20rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="20rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="20rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="20rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="INFRAESTRUCTURA"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            INFRAESTRUCTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="TOTAL"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            TOTAL
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}

            {alertasD && alertasD.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con alertas archivo en dolares</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(alertasD.results, pageD),
                            count: alertasD.count,
                        }}
                        onPageChange={setPageD}
                        sizePerPage={10}
                        page={pageQ}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="5rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ALERTA" width="50rem">
                            ALERTA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="20rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="20rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="20rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="20rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="20rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="20rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="INFRAESTRUCTURA"
                            dataAlign="right"
                            dataFormat={value => (
                                <RenderCurrency className="remove-style" prefix={'$ '} value={value} />
                            )}
                            width="10rem"
                        >
                            INFRAESTRUCTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="TIPO_CAMBIO"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            TIPO CAMBIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="TOTAL"
                            dataAlign="right"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            width="10rem"
                        >
                            TOTAL
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
