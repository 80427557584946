import React from 'react';
import UploadDriver from '../../../../Utils/UploadDriver';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PasoIntermedio from './PasoIntermedio';
import PasoFinal from './PasoFinal';
import IconoAlerta from '../../../../../../../assets/img/alerta-xl.png';
import { api } from 'api';

const dataSeed = {
    results: [],
    count: 0,
};

const ArbolCentrosCosto = props => {
    const [driverTitle, setDriverTitle] = React.useState('Carga archivo base');
    const [errores, setErrores] = React.useState(dataSeed);
    const [nuevos, setNuevos] = React.useState(dataSeed);
    const [actualizados, setActualizados] = React.useState(dataSeed);
    // SE HABILITA EL PASO INTERMEDIO SI EXISTE UNA RESPUESTA DEL BACKEND
    const [intermedio, setIntermedio] = React.useState(false);
    // SE HABILITA EL PASO FINAL SI TODO ESTA BIEN
    const [final, setFinal] = React.useState(false);
    const { setLoader, paso, informe, history } = props;

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });
    const next = async () => {
        if (intermedio) {
            const mySwal = withReactContent(Swal);
            await mySwal
                .fire({
                    title: 'Al aceptar estarás guardando los datos nuevos y actualizados',
                    text: '¿Deseas continuar?',
                    iconHtml: <img src={IconoAlerta} />,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                })
                .then(async res => {
                    if (res.value) {
                        await guardar({ informe: informe.id });
                    }
                });
        } else {
            const siguiente = paso + 1;
            history.push(`/informe/${informe.id}/${siguiente}`);
        }
    };
    const prev = () => {
        if (!final && !intermedio) {
            const anterior = paso - 1;
            history.push(`/informe/${informe.id}/${anterior}`);
        } else {
            setFinal(false);
            setIntermedio(false);
            setNextButton(s => ({ ...s, isVisible: false }));
        }
    };

    const cargar = async (data, files = []) => {
        setLoader(true);
        await api
            .postAttachments('centro-costos/cargar_archivo', data, files)
            .then(async res => {
                if (res.status === 'INTERMEDIO') {
                    if (res.errores.length > 0) {
                        NotificationManager.error(
                            'Archivo base tiene errores en los siguientes registros',
                            'Error',
                            4000
                        );
                    } else {
                        NotificationManager.warning(
                            'Archivo base tiene nuevos datos o actualizaciones en los siguientes registros',
                            'Alerta',
                            4000
                        );
                        setNextButton(s => ({ ...s, isVisible: true }));
                    }
                    setErrores({ results: res.errores, count: res.errores.length });
                    setNuevos({ results: res.nuevos, count: res.nuevos.length });
                    setActualizados({ results: res.actualizados, count: res.actualizados.length });
                    setFinal(false);
                    setIntermedio(true);
                } else {
                    await guardar(data);
                }
            })
            .catch(error => {
                let mensajeError = 'Error al cargar archivo base';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const guardar = async data => {
        await setLoader(true);
        await api
            .post('centro-costos', data)
            .then(res => {
                const siguiente = paso + 1;
                props.next(informe.id, siguiente).then();
                NotificationManager.success('Archivo base cargado exitosamente', 'Éxito', 4000);
                setFinal(true);
                setIntermedio(false);
                setNextButton(s => ({ ...s, isVisible: true }));
            })
            .catch(error => {
                let mensajeError = 'Error al guardar archivo base';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const copiarDatos = async informe => {
        await setLoader(true);
        await api
            .post('centro-costos/copiar_datos', { informe })
            .then(() => {
                NotificationManager.success('Copia de archivo base del mes anterior exitosamente', 'Éxito', 4000);
                setFinal(true);
                setIntermedio(false);
                setNextButton(s => ({ ...s, isVisible: true }));
                const siguiente = paso + 1;
                props.next(informe, siguiente).then();
            })
            .catch(error => {
                let mensajeError = 'Error al copiar archivo base del mes anterior';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const onCopy = async () => {
        await copiarDatos(informe.id);
    };
    const onChangeFile = async file => {
        const files = [];
        files.push({ name: 'archivo', file: file });
        await cargar({ informe: informe.id }, files);
    };

    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="Carga archivo base"
                totalStep={props.totalPasos}
                nextButton={{ ...nextButton, next: next }}
                prevButton={{ ...prevButton, prev: prev }}
            />

            {final && <PasoFinal setLoader={setLoader} informe={informe} />}
            {intermedio && <PasoIntermedio errores={errores} nuevos={nuevos} actualizados={actualizados} />}
            {!final && !intermedio && (
                <UploadDriver driverTitle={driverTitle} onCopy={onCopy} onChange={onChangeFile} />
            )}
        </React.Fragment>
    );
};

export default ArbolCentrosCosto;
