import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/informe/informe';
import WizardInforme from './WizardInforme';

const ms2p = state => {
    return {
        ...state.informe,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(WizardInforme);
