import React, { Component } from 'react';
import { api } from '../../../utility/api';
import NumberFormat from 'react-number-format';
import AsyncSelect from 'react-select/lib/Async';
import Table from '../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { RenderDateTimeAll } from '../Utils/renderField/renderTableField';
import { standardActions } from '../Utils/Grid/StandardActions';
import CabeceraBitacora from '../Utils/Cabeceras/CabeceraBitacora';
import moment from 'moment';

import './Bitacora.css';

const getActions = search => {
    return api
        .get('bitacora/acciones', { search })
        .then(data => {
            return data;
        })
        .catch(error => []);
};

class Bitacora extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { listar } = this.props;
        listar();
    }

    render() {
        const {
            listar,
            data,
            loader,
            page,
            initial_date,
            final_date,
            set_initial_date,
            set_final_date,
            search,
        } = this.props;

        return (
            <React.Fragment>
                <div className="card px-4 my-3 pb-4">
                    <CabeceraBitacora />
                    <div className="row d-flex flex-row flex-wrap justify-content-evenly align-items-center m-1 mb-2">
                        <NumberFormat
                            format="##/##/####"
                            placeholder="DD/MM/YYYY"
                            mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control col-12 col-md-3 col-lg-2"
                            value={initial_date}
                            onValueChange={values => {
                                if (moment(values.formattedValue).isValid()) {
                                    set_initial_date(values.formattedValue);
                                }
                            }}
                            autoFocus
                            onFocus={e => {
                                e.target.setSelectionRange(0, 10);
                            }}
                        />

                        <NumberFormat
                            format="##/##/####"
                            placeholder="DD/MM/YYYY"
                            mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control col-12 mt-2 mt-md-0 col-md-3 ml-md-2 col-lg-2"
                            value={final_date}
                            onValueChange={values => {
                                if (moment(values.formattedValue).isValid()) {
                                    set_final_date(values.formattedValue);
                                }
                            }}
                            onFocus={e => {
                                e.target.setSelectionRange(0, 10);
                            }}
                        />

                        <AsyncSelect
                            isClearable
                            className="form-control p-0 col-12 mt-2 mt-md-0 col-md-3 ml-md-2 col-lg-2 border-trasparent"
                            onChange={e => {
                                if (e) search(e.accion);
                                else search('');
                            }}
                            autoFocus
                            theme={theme => {
                                return {
                                    ...theme,
                                    border: 'none',
                                    background: 'white',
                                    colors: {
                                        ...theme.colors,
                                        text: '#415362',
                                        primary25: '#ebebeb',
                                        primary: '#0a5995',
                                    },
                                };
                            }}
                            classNamePrefix="react-select"
                            cache={false}
                            placeholder="Escriba para buscar"
                            defaultOptions={true}
                            searchPromptText="Escriba para buscar"
                            getOptionValue={option => option['accion']}
                            getOptionLabel={option => option['accion']}
                            loadOptions={getActions}
                        />
                    </div>
                    {data.count > 0 ? (
                        <Table onPageChange={listar} data={data} loading={loader} page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                width="4rem"
                                dataSort
                                isKey
                                dataFormat={standardActions({ ver: 'reporte_bitacora_detalle' })}
                            >
                                ACCIONES
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="createdAt"
                                dataSort
                                width="15rem"
                                dataFormat={cell => <RenderDateTimeAll className="remove-style" fecha={cell} />}
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="usuario" width="10rem" dataSort>
                                USUARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="accion" width="10rem" dataSort>
                                ACCIÓN
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="entidad" width="10rem" dataSort>
                                ENTIDAD
                            </TableHeaderColumn>
                        </Table>
                    ) : (
                        <h1>No hay datos</h1>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default Bitacora;
