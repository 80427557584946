import React from 'react';
import { getMes } from '../../Utils/utilidades';
import { withRouter } from 'react-router-dom';
import { INFORME_CARGA_COMPLETA, INFORME_INICIADO, INFORME_FINALIZADO } from '../../../../utility/constants';
import ReactSpinner from '../../Utils/Spinner';

import Check from '../../../../../assets/img/OneCheck.png';
import DoubleCheck from '../../../../../assets/img/DoubleCheck.png';
import Tiempo from '../../../../../assets/img/reloj.png';
import Tiempo2 from '../../../../../assets/img/tiempo-dark.png';
import Wizard from '../../../../../assets/img/wizard.png';
import Ver from '../../../../../assets/img/2-Ver.png';

// ESTADOS CELERY
const STARTED = 'STARTED';
const FAILURE = 'FAILURE';

const CardInforme = props => {
    const { entry, history } = props;

    const obtenerEstiloEstado = estado => {
        if (estado === INFORME_CARGA_COMPLETA) {
            return 'card-state-2';
        } else if (estado === INFORME_FINALIZADO) {
            return 'card-state-3';
        } else {
            return 'card-state-1';
        }
    };
    const obtenerTextoEstado = estado => {
        if (estado === INFORME_CARGA_COMPLETA) {
            return 'Carga completa';
        } else if (estado === INFORME_FINALIZADO) {
            return 'Finalizado';
        } else {
            return 'Iniciado';
        }
    };

    const obtenerIconoEstado = informe => {
        if (informe.estado === INFORME_CARGA_COMPLETA) {
            if (informe.estado_dist_final === STARTED) {
                return (
                    <div className="d-flex flex-row ml-2">
                        <ReactSpinner />
                        <div className="ml-3">
                            Se a procesado el {informe.progreso_dist_final}% del 100% de la Distribucion Gastos
                            Estructura Comercial.
                        </div>
                    </div>
                );
            } else return <img src={Check} alt="Carga completa" className="icon-lg" />;
        } else if (informe.estado === INFORME_FINALIZADO) {
            return <img src={DoubleCheck} alt="Terminado" className="icon-lg" />;
        } else {
            return <img src={Tiempo} alt="Iniciado" className="icon-lg" />;
        }
    };

    return (
        <React.Fragment>
            <div className="historical-entry" key={entry.id}>
                <div className="d-flex flex-column justify-content-start py-1">
                    <span>
                        {entry.is_oficial ? (
                            <button className="btn btn-secondary btn-version">Versión Oficial</button>
                        ) : (
                            <button className="btn btn-light btn-version">{entry.nombre}</button>
                        )}
                    </span>
                    <div
                        className={`card month d-flex flex-column justify-content-between py-3  ${obtenerEstiloEstado(
                            entry.estado
                        )}`}
                    >
                        <div className="d-flex flex-column align-items-around flex-fill justify-content-around">
                            <div className="card-actions">
                                {entry.estado === INFORME_INICIADO && (
                                    <div className="px-3">
                                        <img className="text-icon" src={Tiempo2} />
                                        <span className="text-alert pl-1">{`Carga de datos se quedo en el paso ${entry.ultimo_paso}`}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="px-3">{obtenerIconoEstado(entry)}</div>
                            <div className="d-flex justify-content-between">
                                <div className="mx-3 d-flex flex-column">
                                    <span className="card-month">{getMes(entry.mes)}</span>
                                    <span className="text-status"> {obtenerTextoEstado(entry.estado)}</span>
                                </div>
                                <div className="d-flex justify-content-center align-content-center">
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            history.push(`/informe/${entry.id}/${entry.ultimo_paso}`);
                                        }}
                                    >
                                        <img src={Wizard} alt="Wizard" className="icon" />
                                    </button>
                                    {[INFORME_FINALIZADO, INFORME_CARGA_COMPLETA].includes(entry.estado) && (
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                history.push(`/informe/${entry.id}/detalle`);
                                            }}
                                        >
                                            <img src={Ver} alt="Ver" className="icon" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(CardInforme);
