import React from 'react';
import Cabecera from '../../Utils/Cabeceras/CabeceraSimple';
import Grafica from '../../../../../assets/img/1-Grafica.png';
import { getMes } from '../../Utils/utilidades';
import TabUno from './TabUno';

import './detalle.css';

let intervalo;
const DetalleInforme = props => {
    React.useEffect(() => {
        const { obtener, item } = props;
        const { id } = props.match.params;
        async function obtenerInforme() {
            await obtener(id);
        }
        obtenerInforme().then();
        //Volvemos a consultar cada 30 seg
        intervalo = setInterval(() => {
            obtenerInforme().then();
        }, 30000);

        return () => {
            clearInterval(intervalo);
        };
    }, [props.match.params]);
    const { item, obtener } = props;
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo={`Informe ${item.nombre || ''} ${getMes(item.mes)}-${item.anio} `} icono={Grafica} />
                <div className="custom-tabs pt-4">
                    <TabUno informe={props.item} obtenerInforme={obtener} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default DetalleInforme;
