import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/Bolson/bolson';
import CrearEditarBolson from './CrearEditarBolson';

const ms2p = state => {
    return {
        ...state.bolson,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditarBolson);
