import React, { useRef } from 'react';
import ArchiveIcon from '../../../../../../../assets/img/duplicar-archivo.png';
import PropTypes from 'prop-types';
import UploaderOne from './../../../../Utils/Uploader';
import UploaderTwo from './../../../../Utils/Uploader';

const UploadDriver = props => {
    React.useEffect(() => {
        props.setFileOne(undefined);
        props.setFileTwo(undefined);
    }, []);
    return (
        <React.Fragment>
            <br />
            <br className="d-none d-md-flex" />
            <br className="d-none d-md-flex" />
            <br className="d-none d-sm-flex" />
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-center">
                    <img src={ArchiveIcon} className="upload-driver-icon" />
                </div>
                <br />
                <br className="d-none d-sm-flex" />
                <div className="d-flex justify-content-center">
                    <span className="upload-driver-title">{props.driverTitle}</span>
                </div>
                <br />
                <div className="d-flex justify-content-center flex-column flex-sm-row">
                    <span className="upload-driver-text">¿Deseas copiar los datos del mes anterior?</span>
                    <br className="d-flex d-md-none" />
                    <button type="button" className="btn btn-light ml-sm-1 ml-md-3" onClick={props.onCopy}>
                        Copiar datos
                    </button>
                </div>
            </div>
            <br />
            <br />
            <div className="upload-text-title">
                <span>Archivo en quetzales</span>
            </div>
            <UploaderOne onChange={props.setFileOne} file={props.fileOne} />
            <br />
            <br />
            <div className="upload-text-title">
                <span>Archivo en dolares</span>
            </div>
            <UploaderTwo onChange={props.setFileTwo} file={props.fileTwo} />
            <br />
        </React.Fragment>
    );
};

UploadDriver.propTypes = {
    onCopy: PropTypes.func.isRequired,
    driverTitle: PropTypes.string.isRequired,
};

export default UploadDriver;
