import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import { INFORME_CARGA_COMPLETA, INFORME_FINALIZADO } from '../../../utility/constants';

const SET_LOADER = 'SET_LOADER_ASIGNACION_DISTRIBUCION';
const DATA = 'SET_DATA_ASIGNACION_DISTRIBUCION';
const DATA_ABC = 'SET_DATA_ABC';
const ITEM = 'SET_ITEM_ASIGNACION_DISTRIBUCION';
const SET_PAGE = 'SET_PAGE_ASIGNACION_DISTRIBUCION';
const SET_LISTADO_DRIVERS = 'SET_LISTADO_DRIVERS';

const setItem = item => ({
    type: ITEM,
    item,
});

const setABC = data_abc => ({
    type: DATA_ABC,
    data_abc,
});

export const crear = data => dispatch => {};

export const validar_asignacion = informe => (dispatch, getStore) => {
    //dispatch({ type: SET_LOADER, loader: true });
    const params = { informe };
    return api.get('asignacion-costos/comprobar_asignacion', params);
};

export const distribuir_asignaciones = informe => (dispatch, getStore) => {
    const params = { informe };
    return api.post('asignacion-costos/distribuir_asignaciones', {}, params);
};

export const listar_abc = () => (dispatch, getStore) => {
    dispatch({ type: SET_LOADER, loader: true });
    api.get('abc')
        .then(response => {
            if (response) {
                dispatch(setABC(response));
            }
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registros ABC', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listarDrivers = informe => (dispatch, getStore) => {
    dispatch({ type: SET_LOADER, loader: true });
    return api
        .get(`informe/${informe}/listado_drivers`)
        .then(response => {
            let listadoDrivers = [];
            if (response && response.length > 0) {
                listadoDrivers = response.map(item => ({
                    value: item.id_driver,
                    label: `${item.nombre}`,
                }));
            }
            dispatch({ type: SET_LISTADO_DRIVERS, listadoDrivers: listadoDrivers });
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registros de drivers', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listar = (page = 1, informe, tipo = 'todos', ref = '') => (dispatch, getStore) => {
    const params = { page, tipo, informe, ref };

    api.get('asignacion-costos', params)
        .then(response => {
            if (response) {
                dispatch({
                    type: DATA,
                    data: response,
                });
                dispatch({
                    type: SET_PAGE,
                    page: response.page,
                });
            }
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registros', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const actualizar = items => dispatch => {
    return api.post(`${'asignacion-costos/actualizar_asginacion_ref'}`, { ref: items });
};

export const leer = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    return api
        .get(`informe/${id}`)
        .then(response => {
            if (response && ![INFORME_CARGA_COMPLETA, INFORME_FINALIZADO].includes(response.estado)) {
                NotificationManager.error(
                    'Carga de informe incompleta, termina de cargar los drivers faltantes.',
                    'Error',
                    5000
                );

                dispatch(push(`/informe/${response.id}/${response.ultimo_paso}`));
            } else {
                dispatch({ type: ITEM, item: response });
            }
        })
        .catch(error => {
            let mensajeError = 'No se encontró el informe';
            if (error.detail) mensajeError = error.detail;
            NotificationManager.error(mensajeError, 'Error', 5000);
            dispatch(push('/404'));
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const editar = (id, data) => dispatch => {};

const setLoader = loader => dispatch => {
    dispatch({ type: SET_LOADER, loader });
};

const eliminar = id => dispatch => {};
export const removerItem = () => dispatch => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const actions = {
    crear,
    listar,
    listar_abc,
    editar,
    leer,
    eliminar,
    removerItem,
    actualizar,
    setLoader,
    validar_asignacion,
    distribuir_asignaciones,
    listarDrivers,
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_ABC]: (state, { data_abc }) => {
        return {
            ...state,
            data_abc,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_LISTADO_DRIVERS]: (state, { listadoDrivers }) => {
        return {
            ...state,
            listadoDrivers,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    data_abc: [],
    listadoDrivers: [],
    page: 1,
    item: {},
    buscador: '',
    filtro: '',
};

export default handleActions(reducers, initialState);
