import React from 'react';
import { withRouter } from 'react-router-dom';

// Si es necesario agregar más ítems de configuración
// se puede actualizar esta lista para llamar a las vistas
const listConfig = [
    { nombre: 'Ramos Comerciales', to: 'ramo' },
    { nombre: 'Canales Comerciales', to: 'canal' },
    { nombre: 'Unidades Comerciales', to: 'unidad' },
];

const SubMenuConfig = props => {
    const onClickContainer = e => {
        const submenu = document.getElementById('submenu');
        if (e.target === submenu) {
            props.onClose();
        }
    };
    return (
        <div id="submenu" className="contenedor-submenu" onClick={onClickContainer}>
            <div
                className={
                    props.toggleOpen && window.innerWidth <= 767 ? 'submenu-container' : 'submenu-container-config'
                }
            >
                <div className="submenu-header pl-2 pt-1 bg-danger">
                    <span className="text-white">Mantenimientos</span>
                </div>
                <ol className="submenu-list p-0">
                    {listConfig.map(item => (
                        <li
                            key={item.nombre}
                            className="submenu-item"
                            onClick={() => {
                                props.history.push(`/mantenimiento/${item.to}`);
                                props.closeMenu();
                                props.onClose();
                            }}
                        >
                            {item.nombre}
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default withRouter(SubMenuConfig);
