import React from 'react';
import Asignacion from './Asignacion';
import VistaErrores from './VistaErrores';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';

export default function PasoAsignacion(props) {
    const [erroresBolsonAbc, setErroresBolsonAbc] = React.useState([]);
    const [erroresActividades, setErroresActividades] = React.useState([]);
    const [intermedio, setIntermedio] = React.useState(false);
    const [consultar, setConsultar] = React.useState(false);
    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: true,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });

    const { history, paso, setPaso, setLoader } = props;
    const { id } = props.match.params;

    function validacion_asignacion() {
        setLoader(true);
        props
            .validar_asignacion(id)
            .then(response => {
                if (response.estado == true) {
                    setLoader(true);
                    props.distribuir_asignaciones(id).then(() => setConsultar(true));
                } else if (response.estado == false) {
                    NotificationManager.error(response.mensaje, 'Error', 4000);
                }
            })
            .finally(setLoader(false));
    }
    const consultarEstado = () => {
        api.get('task/estado', { informe: id, driver: 'DIST_ASIGNACIONES' })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    setPaso(paso + 1);
                    setIntermedio(false);
                    setConsultar(false);
                    setNextButton(s => ({ ...s, isVisible: true }));
                    setLoader(false);
                    NotificationManager.success('Se han distribuido exitosamente todas las referencias', 'Éxito', 5000);
                } else if (response.status === 'FAILURE') {
                    if (
                        response.result &&
                        (response.result.errores_bolson_abc || response.result.errores_actividades)
                    ) {
                        setErroresBolsonAbc({
                            results: response.result.errores_bolson_abc,
                            count: response.result.errores_bolson_abc.length,
                        });
                        setErroresActividades({
                            results: response.result.errores_actividades,
                            count: response.result.errores_actividades.length,
                        });
                        setIntermedio(true);
                        setNextButton(s => ({ ...s, isVisible: false }));
                    } else if (response.result && response.result.exc_message) {
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                        setNextButton(s => ({ ...s, isVisible: true }));
                    } else {
                        NotificationManager.error('Ocurrió un error al distribuir las referencias', 'Error', 7000);
                        setNextButton(s => ({ ...s, isVisible: true }));
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = JSON.stringify(e);
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 7000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 20000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="Asignacion de costo bolsón, directos, abc"
                totalStep={props.totalPasos}
                nextButton={{
                    ...nextButton,
                    next: () => {
                        validacion_asignacion();
                    },
                }}
                prevButton={{
                    ...prevButton,
                    prev: () => {
                        if (intermedio) {
                            setIntermedio(false);
                            setNextButton(s => ({ ...s, isVisible: true }));
                        } else {
                            history.push(`/informe/${id}/detalle`);
                        }
                    },
                }}
            />
            {!intermedio ? (
                <Asignacion {...props} />
            ) : (
                <VistaErrores erroresBolsonAbc={erroresBolsonAbc} erroresActividades={erroresActividades} />
            )}
        </React.Fragment>
    );
}
