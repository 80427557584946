import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import { CANAL_COMERCIAL } from '../../../utility/constants';

const SET_LOADER = 'SET_LOADER_CANAL';
const DATA = 'SET_DATA_CANAL';
const ITEM = 'SET_ITEM_CANAL';
const SET_PAGE = 'SET_PAGE_CANAL';
const SET_BUSCADOR_MANTENIMIENTO = 'SET_BUSCADOR_MANTENIMIENTO_CANAL';
const SET_FILTRO_MANTENIMIENTO = 'SET_FILTRO_MANTENIMIENTO_CANAL';

export const crear = data => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.post('mantenimiento', data)
        .then(response => {
            dispatch(push('/mantenimiento/canal'));
            NotificationManager.success('Registro creado exitosamente', 'Éxito', 4000);
            dispatch(listar());
        })
        .catch(error => {
            NotificationManager.error('Error al crear registro', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: SET_LOADER, loader: true });
    const params = { page, tipo_equivalencia: CANAL_COMERCIAL };

    api.get('mantenimiento', params)
        .then(response => {
            if (response) {
                dispatch({
                    type: DATA,
                    data: response,
                });
                dispatch({
                    type: SET_PAGE,
                    page: page,
                });
            }
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registros', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const leer = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.get(`mantenimiento/${id}`)
        .then(response => {
            if (response) {
                response.codigos.push({ codigo: 'ultimo' });
                dispatch({
                    type: ITEM,
                    item: response,
                });
            }
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registro', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const editar = (id, data) => (dispatch, getStore) => {
    dispatch({ type: SET_LOADER, loader: true });
    api.put(`mantenimiento/${id}`, data)
        .then(response => {
            NotificationManager.success('El registro de actualizó exitosamente', 'Éxito', 3000);
            dispatch(push('/mantenimiento/canal'));
        })
        .catch(error => {
            NotificationManager.error('Error al actualizar registro', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const eliminar = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.eliminar(`mantenimiento/${id}`)
        .then(response => {
            NotificationManager.success('Registro eliminado correctamente', 'Éxito', 4000);
            dispatch(listar());
        })
        .catch(error => {
            NotificationManager.error('Error al eliminar registro', 'Error', 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const removerItem = () => dispatch => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const search = search => dispatch => {
    dispatch({ type: SET_BUSCADOR_MANTENIMIENTO, buscador: search });
    dispatch(listar());
};

export const filter = filter => dispatch => {
    dispatch({ type: SET_FILTRO_MANTENIMIENTO, filtro: filter });
    dispatch(listar());
};

export const actions = {
    crear,
    listar,
    editar,
    leer,
    eliminar,
    removerItem,
    search,
    filter,
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_BUSCADOR_MANTENIMIENTO]: (state, { buscador }) => {
        return {
            ...state,
            buscador,
        };
    },
    [SET_FILTRO_MANTENIMIENTO]: (state, { filtro }) => {
        return {
            ...state,
            filtro,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    page: 1,
    item: {},
    buscador: '',
    filtro: '',
};

export default handleActions(reducers, initialState);
