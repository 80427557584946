import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { NotificationManager } from 'react-notifications';

import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import { RenderCurrency, RenderNumber } from '../../../../Utils/renderField/renderReadField.js';
import { api } from 'api';

const dataSeed = {
    results: [],
    count: 0,
};

const PasoFinal = props => {
    const [data, setData] = React.useState(dataSeed);

    const [page, setPage] = React.useState(1);

    const { setLoader } = props;

    React.useEffect(() => {
        listar(page);
    }, [page]);

    const listar = page => {
        setLoader(true);
        api.get('metros-cuadrados', { id_informe: props.informe.id, page })
            .then(res => {
                setData(res);
            })
            .catch(error => {
                let mensajeError = 'Error al obtener los registros del driver de metros cuadrados';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <React.Fragment>
            <br />
            <br />
            <span className="text-dark">Registros de driver Metros cuadrados</span>
            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
            <Grid data={data} onPageChange={setPage} sizePerPage={20} page={page}>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="150"
                    isKey
                    dataFormat={data => {
                        return <img className="column-data-icon" src={OkIcon} />;
                    }}
                >
                    ESTADO
                </TableHeaderColumn>
                <TableHeaderColumn width="260" dataField="gerencia">
                    GERENCIA
                </TableHeaderColumn>
                <TableHeaderColumn width="240" dataField="direccion">
                    DIRECCION
                </TableHeaderColumn>
                <TableHeaderColumn width="300" dataField="departamento">
                    DEPARTAMENTO
                </TableHeaderColumn>
                <TableHeaderColumn width="200" dataField="centro_costo">
                    CENTRO DE COSTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="metros"
                    width="120"
                    dataAlign="right"
                    dataFormat={value => (
                        <RenderNumber className="remove-style" suffix={''} decimalScale={2} value={value} />
                    )}
                >
                    METROS
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

export default PasoFinal;
