import React, { useRef } from 'react';
import './UploadDriver.css';
import ArchiveIcon from '../../../../../assets/img/duplicar-archivo.png';
import PropTypes from 'prop-types';

const UploadDriver = props => {
    const input = useRef();
    const onFileChange = (e, file) => {
        file = file || e.target.files[0];
        const excelType = '.xlsx|.xls|.sheet';
        if (!!file.type.match(excelType)) {
            props.onChange(file);
        }
    };
    const onClick = () => {
        input.current.value = null;
        input.current.click();
    };

    const onDrop = e => {
        e.preventDefault();
        onFileChange(e, e.dataTransfer.files[0]);
    };
    const onDragOver = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <br />
            <br className="d-none d-md-flex" />
            <br className="d-none d-md-flex" />
            <br className="d-none d-sm-flex" />
            <br className="d-none d-sm-flex" />
            <br className="d-none d-sm-flex" />
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-center">
                    <img src={ArchiveIcon} className="upload-driver-icon" />
                </div>
                <br />
                <br className="d-none d-sm-flex" />
                <div className="d-flex justify-content-center">
                    <span className="upload-driver-title">{props.driverTitle}</span>
                </div>
                <br />
                <div className="d-flex justify-content-center flex-column flex-sm-row">
                    <span className="upload-driver-text">¿Deseas copiar los datos del mes anterior?</span>
                    <br className="d-flex d-md-none" />
                    <button type="button" className="btn btn-light ml-sm-1 ml-md-3" onClick={props.onCopy}>
                        Copiar datos
                    </button>
                </div>
            </div>
            <br />
            <div key="label" className="upload-driver-container" onDrop={onDrop} onDragOver={onDragOver}>
                <br className="d-none d-sm-flex" />
                <br />
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    ref={input}
                ></input>
                <p className="upload-driver-text m-0 pb-2">Arrastra o haz clic en el botón para cargar excel</p>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-danger" type="button" onClick={onClick}>
                        Haz click aquí
                    </button>
                </div>
                <br className="d-none d-sm-flex" />
                <br />
            </div>
            <br />
            <br />
            <br />
            <br />
        </React.Fragment>
    );
};

UploadDriver.propTypes = {
    onChange: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    driverTitle: PropTypes.string.isRequired,
};

export default UploadDriver;
