import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/distribucion/distribucion';
import Distribucion from './WizardIDistribucion';

const ms2p = state => {
    return {
        ...state.distribucion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Distribucion);
