import React from 'react';

import CaretAbajo from '../../../../../assets/img/Caret-abajo.png';

const ExpandColumnComponent = ({ isExpandableRow, isExpanded }) => {
    if (isExpandableRow) {
        if (isExpanded) {
            return (
                <img
                    src={CaretAbajo}
                    className="m-0 p-0"
                    style={{ transform: 'rotate(0.5turn)' }}
                    width="15px"
                    alt="Expanded"
                />
            );
        } else {
            return <img src={CaretAbajo} className="m-0 p-0" width="15px" alt="Expanded" />;
        }
    } else return <div> </div>;
};

export default ExpandColumnComponent;
