import React from 'react';
import Grid from '../../../../Utils/Grid';
import CentrosCosto from './CentrosCosto';
import ExpandColumnComponent from '../../../../Utils/ExpandColumn';
import Stepper from '../../../../Utils/Stepper';
import Select from 'react-select/';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField';
import { NotificationManager } from 'react-notifications';
import { OPCIONES_DISTRIBUCION, OPCIONES_ITEMS, CLAVE } from '../../../../../../utility/constants';
import { api } from 'api';

import './distribuirActividades.css';
import _ from 'lodash';

const themeOfSelect = theme => ({
    ...theme,
    border: 'none',
    background: 'white',
    colors: {
        ...theme.colors,
        text: '#415362',
        primary25: '#ebebeb',
        primary: '#0a5995',
    },
});

const dataSeed = {
    results: [],
    count: 0,
};

const DistActividades = props => {
    const [page, setPage] = React.useState(1);
    const [data, setData] = React.useState(dataSeed);
    const [buscar, setBuscar] = React.useState(undefined);
    //GUARDAMOS TODAS LAS ACTIVIDADES SELECCIONADAS
    const [selected, setSelected] = React.useState([]);
    //GUARDAMOS EL TIPO DE DISTRIBUCIÓN Y DRIVER SELECCIONADOS
    const [tipo, setTipo] = React.useState(null);
    const [driver, setDriver] = React.useState(null);

    const { paso, totalPasos, setPaso, listadoDrivers } = props;
    const { id } = props.match.params;

    React.useEffect(() => {
        const { id } = props.match.params;
        async function cargar_actividades() {
            await props.setLoader(true);
            await props.listarDrivers(id);
            await listarActividades(1).then(() => props.setLoader(false));
        }
        cargar_actividades().then();
    }, []);

    React.useEffect(() => {
        if (!buscar || buscar === '') {
            listarActividades(1).then();
        }
    }, [buscar]);

    const onBuscar = () => {
        listarActividades(1).then();
    };

    const listarActividades = async (page = 1) => {
        const { id } = props.match.params;
        const params = {};
        if (buscar) params.search = buscar;
        params.informe = id;
        params.page = page;
        return await api.get('dist-actividades', params).then(response => {
            setData(response);
            setPage(page);
        });
    };

    const onSelect = (row, isSelected, e) => {
        if (isSelected) {
            const newRows = [...selected];
            const actividad = { ...row };
            delete actividad.centros_costo;
            newRows.push(actividad);
            setSelected([...newRows]);
        } else {
            setSelected(selected.filter(item => item.id !== row.id));
        }
    };

    const onSelectAll = (isSelected, rows) => {
        const newRows = [];
        if (isSelected) {
            rows.forEach(row => {
                const actividad = { ...row };
                delete actividad.centros_costo;
                if (!_.includes(selected, actividad.id)) newRows.push(actividad);
            });
            setSelected([...selected, ...newRows]);
        } else {
            setSelected(_.differenceWith(selected, rows, (a, b) => a.id === b.id));
        }
    };

    const onAplicar = () => {
        const copiaActividades = [...selected].map(item => {
            item['tipo'] = tipo.value;
            if (item['tipo'] === CLAVE) item['distribucion'] = driver.value;
            return item;
        });
        api.post('dist-actividades/actualizar_actividades', { actividades: copiaActividades })
            .then(() => {
                listarActividades(page).then();
                setDriver(null);
                setTipo(null);
                setSelected([]);
            })
            .catch(() => {
                listarActividades(page).then();
            });
    };

    const onCambio = data => {
        const actividades = [];
        const actividad = { ...data };
        delete actividad.centros_costo;
        actividades.push(actividad);

        api.post('dist-actividades/actualizar_actividades', { actividades })
            .then(() => {
                listarActividades(page).then();
            })
            .catch(() => {
                listarActividades(page).then();
            });
    };

    const selectRowProps = {
        mode: 'checkbox',
        clickToSelect: false,
        clickToExpand: true,
        selected: selected.map(item => item.id),
        columnWidth: '3rem',
        onSelect,
        onSelectAll,
    };

    const deshabilitarAplicar = () => {
        if (tipo && tipo.value && tipo.value === CLAVE && selected.length > 0) {
            if (driver && driver.value) {
                return false;
            }
            return true;
        } else if (tipo && tipo.value && selected.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    const nextStep = () => {
        api.get('dist-actividades/no_asignados/', { informe: id }).then(response => {
            if (response && response.no_asignados > 0) {
                NotificationManager.error(`Asignación incompleta; faltantes: ${response.no_asignados}`, 'Error', 7000);
            } else {
                api.post('actividades-compuestas/generar', {}, { informe: id })
                    .then(() => {
                        setPaso(paso + 1);
                    })
                    .catch(error => {
                        let msj = 'Error interno, no se puede continuar con la distribucion.';
                        if (error && error.detail) msj = error.detail;
                        NotificationManager.error(msj, 'Error', 7000);
                    });
            }
        });
    };

    return (
        <React.Fragment>
            <br />
            <Stepper
                step={paso}
                stepName="Distribución de actividades clave"
                totalStep={totalPasos}
                nextButton={{
                    name: 'Siguiente',
                    isVisible: true,
                    next: nextStep,
                }}
                prevButton={{
                    name: 'Regresar',
                    isVisible: true,
                    prev: () => {
                        setPaso(paso - 1);
                    },
                }}
            />
            <br />
            <h6 className="text-dark font-weight-bold  pl-2">
                Selecciona las actividades claves que serán asignadas a drivers
            </h6>
            <div className="d-flex flex-column  flex-xl-row custom-dis-actividades">
                <div className="d-flex flex-column flex-md-row flex-3 mb-3 mb-xl-0">
                    <div className="d-flex flex-1 py-2 py-md-0">
                        <label className="text-filter">Seleccionados </label>
                    </div>
                    <div className="d-flex flex-1 py-2 py-md-0">
                        <Select
                            isClearable={true}
                            className="w-100 m-auto pl-0 pl-md-2"
                            autoFocus
                            theme={themeOfSelect}
                            defaultValue={tipo}
                            value={tipo}
                            onChange={value => {
                                setTipo(value ? value : null);
                            }}
                            placeholder="Seleccione tipo"
                            options={OPCIONES_DISTRIBUCION}
                            classNamePrefix="react-select"
                        />
                    </div>
                    <div className="d-flex flex-2 py-2 py-md-0">
                        <Select
                            isClearable={true}
                            className="w-100 m-auto pl-0 pl-md-2"
                            theme={themeOfSelect}
                            value={driver}
                            onChange={value => {
                                setDriver(value ? value : null);
                            }}
                            isDisabled={tipo && tipo.value !== CLAVE ? true : false}
                            placeholder="Seleccione driver"
                            options={listadoDrivers}
                            classNamePrefix="react-select"
                        />
                    </div>
                    <div className="d-flex flex-1 py-2 py-md-0 justify-content-md-end justify-content-xl-start">
                        <button
                            type="button"
                            className="btn btn-blue ml-0 col-12 col-md-8 ml-md-2"
                            disabled={deshabilitarAplicar()}
                            onClick={onAplicar}
                        >
                            Aplicar
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-2 flex-column flex-md-row py-2 py-md-0 flex-1">
                    <input
                        type="text"
                        className="form-control mb-3 mb-md-0"
                        placeholder="Buscar por...."
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                onBuscar();
                            }
                        }}
                        onChange={e => {
                            const eValue = e.target.value;
                            setBuscar(eValue || '');
                        }}
                    ></input>
                    <button type="button" className="btn btn-blue ml-0 col-12 col-md-3 ml-md-2 py-2" onClick={onBuscar}>
                        Buscar
                    </button>
                </div>
            </div>
            <br />
            <br />
            <div className="scroll-table-actividades">
                <Grid
                    data={data}
                    expandableRow={() => {
                        return true;
                    }}
                    expandComponent={row => <CentrosCosto data={row} />}
                    expandColumnOptions={{
                        expandColumnVisible: true,
                        expandColumnComponent: ExpandColumnComponent,
                    }}
                    selectRow={selectRowProps}
                    expandBy="column"
                    pagination={true}
                    page={page}
                    onPageChange={page => {
                        props.setLoader(true);
                        listarActividades(page).then(() => props.setLoader(false));
                    }}
                    sizePerPage={20}
                    striped
                >
                    <TableHeaderColumn dataField="id" width="0" isKey></TableHeaderColumn>
                    <TableHeaderColumn dataField="empresa" width="4rem">
                        EMPRESA
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="actividad" expandable={false} width="7rem">
                        ACTIVIDAD
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo"
                        width="7rem"
                        expandable={false}
                        dataFormat={(value, row) => {
                            return (
                                <select
                                    value={value || '1'}
                                    onChange={e => {
                                        const copiaActividad = { ...row, tipo: e.target.value };
                                        onCambio(copiaActividad);
                                    }}
                                    className="custom-select-table"
                                >
                                    {!value && (
                                        <option key={1} value="1">
                                            Seleccione
                                        </option>
                                    )}
                                    {OPCIONES_DISTRIBUCION.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            );
                        }}
                    >
                        TIPO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="distribucion"
                        expandable={false}
                        width="30rem"
                        dataFormat={(value, row) => {
                            if (row && row.tipo && row.tipo === CLAVE) {
                                return (
                                    <select
                                        value={value || '1'}
                                        onChange={e => {
                                            const copiaActividad = { ...row, distribucion: e.target.value };
                                            onCambio(copiaActividad);
                                        }}
                                        className="custom-select-table"
                                        style={{ width: '30rem' }}
                                    >
                                        {!value && (
                                            <option key={1} value="1">
                                                Seleccione...
                                            </option>
                                        )}
                                        {listadoDrivers.map(item => (
                                            <option key={item.value} value={item.value}>
                                                {item.label} - {item.value}
                                            </option>
                                        ))}
                                    </select>
                                );
                            }
                        }}
                    >
                        DISTRIBUCIÓN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado"
                        width="7rem"
                        dataFormat={value =>
                            value ? (
                                <div className="asignado">ASIGNADO</div>
                            ) : (
                                <div className="no_asignado">PENDIENTE</div>
                            )
                        }
                    >
                        ESTADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        width="7rem"
                        dataAlign="right"
                        dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default DistActividades;
