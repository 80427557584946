import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { NotificationManager } from 'react-notifications';

import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField.js';
import { api } from 'api';

const dataSeed = {
    results: [],
    count: 0,
};

const PasoFinal = props => {
    const [data, setData] = React.useState(dataSeed);
    const [page, setPage] = React.useState(1);
    const { setLoader } = props;

    React.useEffect(() => {
        listar();
    }, [page]);

    const listar = () => {
        setLoader(true);
        api.get('centro-costos', { informe: props.informe.id, page })
            .then(res => {
                setData(res);
            })
            .catch(error => {
                let mensajeError = 'Error al obtener los centros de costo';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <React.Fragment>
            <br />
            <br />
            <Grid data={data} onPageChange={setPage} sizePerPage={20} page={page} scrollTable>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width="5rem"
                    dataFormat={data => {
                        return <img className="column-data-icon" src={OkIcon} />;
                    }}
                >
                    ESTADO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="referencia" width="15rem">
                    REFERENCIA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="centro_costo" width="10rem">
                    CENTRO COSTO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="anio" width="5rem">
                    AÑO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="mes" width="5rem">
                    MES
                </TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_empresa" width="7rem">
                    EMPRESA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="compania" width="7rem">
                    COMPAÑIA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="gerencia">GERENCIA</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_direccion" width="7rem">
                    COD. DIRECCIÓN
                </TableHeaderColumn>
                <TableHeaderColumn dataField="direccion">DIRECCION</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_departamento" width="7rem">
                    COD. DEPARTAMENTO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="departamento">DEPARTAMENTO</TableHeaderColumn>
                <TableHeaderColumn dataField="no_cuenta" width="10rem">
                    NO. CUENTA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="nombre_cuenta">CUENTA</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="monto"
                    width="15rem"
                    dataAlign="right"
                    dataFormat={e => <RenderCurrency className="remove-style" value={e} />}
                >
                    MONTO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="elegible" width="7rem" dataFormat={e => (e ? 'Si' : 'No')}>
                    ELEGIBLE
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

export default PasoFinal;
