import React from 'react';
import { Form, Field } from 'react-final-form';
import { renderField } from '../../Utils/renderField/renderField';
import { Link } from 'react-router-dom';
import Cabecera from '../../Utils/Cabeceras/CabeceraSimple';
import RamoContableIcono from '../../../../../assets/img/ramo-contable.png';

const validate_length = (input, values, errors, max_length) => {
    if (String(values[input]).length > max_length) {
        errors[input] = `El campo debe tener menos de ${max_length} caracteres`;
    }
};

const validate = values => {
    const errors = {};
    if (!values.codigo_producto) errors.codigo_producto = 'Campo requerido';
    if (!values.nombre) errors.nombre = 'Campo requerido';
    if (values.codigo_producto) validate_length('codigo_producto', values, errors, 20);
    if (values.nombre) validate_length('nombre', values, errors, 120);
    return errors;
};

const CrearEditarEmpleado = props => {
    const [ramoContable, setRamoContable] = React.useState(undefined);
    const { id } = props.match.params;

    React.useEffect(() => {
        if (id) props.leer(id);
    }, []);

    React.useEffect(() => {
        if (id && props.item && props.item.id) setRamoContable(props.item);
        else setRamoContable(undefined);
    }, [props.item]);

    const onSubmit = data => {
        const body = { ...data };
        body.codigo_producto = String(body.codigo_producto).toUpperCase();
        body.nombre = String(body.nombre).toUpperCase();
        if (id) props.editar(id, body);
        else props.crear(body);
    };
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Ramo contable" icono={RamoContableIcono} />
                <br className="d-none d-md-flex" />
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    initialValues={{ ...ramoContable }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className="my-md-5 py-md-5">
                            <div className="d-flex flex-column col-12 col-md-5 col-lg-4 m-auto py-4">
                                <div>
                                    <label className="form-label" htmlFor="codigo">
                                        Codigo Producto
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field
                                            name="codigo_producto"
                                            type="text"
                                            component={renderField}
                                            className="flex-1 input-text-uppercase"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="form-label" htmlFor="nombre">
                                        Ramo contable
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field
                                            name="nombre"
                                            type="text"
                                            component={renderField}
                                            className="flex-1 input-text-uppercase"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex mt-4 justify-content-center">
                                <button disabled={submitting} className="btn btn-danger mr-2" type="submit">
                                    Guardar
                                </button>
                                <Link to="/ramos-contables" className="btn btn-dark ml-2">
                                    Cancelar
                                </Link>
                            </div>
                        </form>
                    )}
                </Form>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </React.Fragment>
    );
};
export default CrearEditarEmpleado;
