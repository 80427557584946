import React, { useState, useEffect } from 'react';
import moment from 'moment';

import './Inicio.css';
import Check from '../../../../assets/img/7-Check.png';
import Tiempo from '../../../../assets/img/3-Tiempo.png';
import Tiempo2 from '../../../../assets/img/PendienteIniciar.png';
import Siguiente from '../../../../assets/img/5-Flecha-derecha.png';
import CabeceraInicio from '../Utils/Cabeceras/Cabecera';
import LoadMask from '../Utils/LoadMask/LoadMask';

const obtenerIconoEstado = estado => {
    if (estado === 'Finalizado') {
        return <img className="icon" src={Check} alt="Terminado" />;
    } else if (estado === 'En proceso') {
        return <img className="icon" src={Tiempo} alt="En proceso" />;
    } else {
        return <img className="icon" src={Tiempo2} alt="Pendiente de iniciar" />;
    }
};

export default function Inicio(props) {
    const [anio, setAnio] = useState(moment().year());
    const { data, history, getMonths, loader } = props;

    useEffect(() => {
        obtenerMeses(anio).then();
    }, []);

    const obtenerMeses = async anio => {
        await getMonths(anio);
        setAnio(anio);
        localStorage.setItem('year', anio);
    };

    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <CabeceraInicio />
                <LoadMask loading={loader} blur>
                    <div className="row d-flex justify-content-start align-items-center">
                        {anio && (
                            <React.Fragment>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        let value = parseInt(anio);
                                        value--;
                                        obtenerMeses(value);
                                    }}
                                >
                                    <span className="material-icons">navigate_before</span>
                                </button>
                                <span className="anio">Año {anio}</span>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        let value = parseInt(anio);
                                        value++;
                                        obtenerMeses(value);
                                    }}
                                >
                                    <span className="material-icons">navigate_next</span>
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="row d-flex justify-content-center flex-wrap">
                        {data &&
                            data.map(entry => {
                                return (
                                    <React.Fragment key={entry.id}>
                                        <div className="card month d-flex flex-column justify-content-between py-3">
                                            <div className="d-flex flex-column  align-items-around flex-fill justify-content-around">
                                                <div className="mx-3 d-flex flex-row flex-wrap">
                                                    {obtenerIconoEstado(entry.estado)}
                                                    <p className="month-text px-2 mb-2">{entry.mes}</p>
                                                    <p className="status-text mb-2">{entry.estado}</p>
                                                </div>
                                                <div className="card-actions">
                                                    <ul>
                                                        {entry.comentarios &&
                                                            entry.comentarios.map((action, index) => {
                                                                return <li key={index}>{action}</li>;
                                                            })}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="d-flex mx-3 flex-row justify-content-start">
                                                <span className="more-text"> Ver mas</span>
                                                <button
                                                    className="btn py-0"
                                                    onClick={() => {
                                                        history.push(`/historial/${anio}/${entry.id}`);
                                                    }}
                                                >
                                                    <img className="icon" src={Siguiente} alt="Continuar" />
                                                </button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </LoadMask>
            </div>
        </React.Fragment>
    );
}
