import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Profile, Registro } from './common/components/LoginRegister';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('react-tabs/style/react-tabs.css');
require('../style/index.css');

import 'bootstrap/dist/css/bootstrap.min.css';

import BitacoraListContainer from './common/components/Bitacora/BitacoraListContainer';
import BitacoraContainer from './common/components/Bitacora/BitacoraContainer';
import InicioContainer from './common/components/Inicio/InicioContainer';
import HistorialContainer from './common/components/Inicio/Historial/HistorialContainer';
// Informe
import CrearInforme from './common/components/Informe/Crear';
import CargarInforme from './common/components/Informe/Cargar';
import DetalleInforme from './common/components/Informe/Detalle';

import DriverContainer from './common/components/Drivers/DriversContainer';
import { CrearEditarProyectos, ListadoProyectos } from './common/components/Proyecto';
import { CrearEditarBolson, CrearEditarReferencia, ListarBolson } from './common/components/Bolson';
import DistribucionInforme from './common/components/Informe/Distribucion';
import { ListarABC, CrearEditarABC } from './common/components/Abc';
import { ListarGestion, CrearEditarGestiones } from './common/components/Gestion';
import { CrearEditarEmpleado, ListadoEmpleados } from './common/components/Empleado';
import { CrearEditarRamoContable, ListarRamosContables } from './common/components/RamoContable';

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <ProtectedRoute exact path="/" component={InicioContainer} />
                <ProtectedRoute exact path="/bitacora" component={BitacoraListContainer} />
                <ProtectedRoute exact path="/reporte_bitacora_detalle/:id" component={BitacoraContainer} />
                <ProtectedRoute exact path="/historial/:anio/:mes" component={HistorialContainer} />

                {/* Detalle Drivers */}
                <ProtectedRoute exact path="/drivers/:id_driver" component={DriverContainer} />
                {/* Pasos informes */}
                <ProtectedRoute exact path="/crear-informe/:anio/:mes" component={CrearInforme} />
                <ProtectedRoute exact path="/informe/:id/detalle" component={DetalleInforme} />
                <ProtectedRoute exact path="/informe/configuracion-costos/:id" component={DistribucionInforme} />
                <ProtectedRoute exact path="/informe/:id/:paso" component={CargarInforme} />

                {/* Rutas de proyectos  */}
                <ProtectedRoute exact path="/proyectos" component={ListadoProyectos} />
                <ProtectedRoute exact path="/proyecto" component={CrearEditarProyectos} />
                <ProtectedRoute exact path="/proyecto/:id/editar" component={CrearEditarProyectos} />

                {/* No. Bolson */}
                <ProtectedRoute exact path="/bolson" component={ListarBolson} />
                <ProtectedRoute exact path="/bolson/nuevo" component={CrearEditarBolson} />
                <ProtectedRoute exact path="/bolson/:id/editar" component={CrearEditarBolson} />

                {/* ABC  */}
                <ProtectedRoute exact path="/abc" component={ListarABC} />
                <ProtectedRoute exact path="/abc/nuevo" component={CrearEditarABC} />
                <ProtectedRoute exact path="/abc/:id/editar" component={CrearEditarABC} />

                {/* Gestiones  */}
                <ProtectedRoute exact path="/gestiones" component={ListarGestion} />
                <ProtectedRoute exact path="/gestiones/nuevo" component={CrearEditarGestiones} />
                <ProtectedRoute exact path="/gestiones/:id/editar" component={CrearEditarGestiones} />

                {/* Empleados  */}
                <ProtectedRoute exact path="/empleados" component={ListadoEmpleados} />
                <ProtectedRoute exact path="/empleado" component={CrearEditarEmpleado} />
                <ProtectedRoute exact path="/empleado/:id/editar" component={CrearEditarEmpleado} />

                {/* Ramos Contables  */}
                <ProtectedRoute exact path="/ramos-contables" component={ListarRamosContables} />
                <ProtectedRoute exact path="/ramo-contable" component={CrearEditarRamoContable} />
                <ProtectedRoute exact path="/ramo-contable/:id/editar" component={CrearEditarRamoContable} />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
