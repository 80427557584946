import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import Swal from 'sweetalert2';

const SET_LOADER = 'SET_LOADER_INFORME';
const DATA = 'SET_DATA_INFORME';
const ITEM = 'SET_ITEM_INFORME';
const SET_PAGE = 'SET_PAGE_INFORME';

export const crear = data => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.post('informe', data)
        .then(response => {
            if (response === 'existe_oficial') {
                Swal.fire({
                    title: 'Ya existe un informe oficial !',
                    text: 'Si desea crear de nuevo el informe oficial se procederá a cambiar el actual a proyección.',
                    inputLabel: 'Ingrese el nombre con que desea guardar el informe oficial actual.',
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    allowOutsideClick: false,
                    inputValidator: value => {
                        if (!value) {
                            return 'Campo requerido!';
                        }
                    },
                }).then(async res => {
                    if (res.value) {
                        const informeActualizar = {
                            nombre: res.value,
                            anio: data.anio,
                            mes: data.mes,
                        };
                        dispatch(cambiarAProyeccion(informeActualizar, data));
                    }
                });
            } else {
                NotificationManager.success('Informe creado exitosamente', 'Éxito', 3000);
                dispatch(push(`/informe/${response.id}/${response.ultimo_paso}`));
            }
        })
        .catch(error => {
            let mensajeError = 'No se ha podido crear el informe';
            if (error && error.detail) mensajeError = error.detail;
            NotificationManager.error(mensajeError, 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listar = (page = 1, informe = undefined) => (dispatch, getStore) => {};

export const leer = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    return api
        .get(`informe/${id}`)
        .then(response => {
            dispatch({ type: ITEM, item: response });
            dispatch(push(`/informe/${response.id}/${response.ultimo_paso}`));
        })
        .catch(error => {
            let mensajeError = 'No se encontró el informe';
            if (error.detail) mensajeError = error.detail;
            NotificationManager.error(mensajeError, 'Error', 5000);
            dispatch(push('/404'));
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const obtener = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    return api
        .get(`informe/${id}`)
        .then(response => {
            dispatch({ type: ITEM, item: response });
        })
        .catch(error => {
            let mensajeError = 'No se encontró el informe';
            if (error.detail) mensajeError = error.detail;
            NotificationManager.error(mensajeError, 'Error', 5000);
            dispatch(push('/404'));
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const next = (id, paso) => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    return api
        .post(`informe/${id}/actualizar_paso`, { paso })
        .then(response => {
            dispatch({ type: ITEM, item: response });
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const editar = (id, data) => (dispatch, getStore) => {};

export const eliminar = id => dispatch => {};

export const cambiarAProyeccion = (dataActualizar, dataInforme) => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    return api
        .post(`informe/cambiar_a_proyeccion`, dataActualizar)
        .then(response => {
            dispatch(crear(dataInforme));
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const setLoader = (loader = false) => dispatch => {
    dispatch({ type: SET_LOADER, loader: loader });
};

export const removerItem = () => dispatch => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const actions = {
    crear,
    listar,
    editar,
    leer,
    eliminar,
    removerItem,
    setLoader,
    next,
    obtener,
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    page: 1,
    item: {},
};

export default handleActions(reducers, initialState);
