import React from 'react';
import PropTypes from 'prop-types';
import './stepper.css';
const Stepper = props => {
    return (
        <React.Fragment>
            <div className="stepper-container flex-column flex-md-row">
                <div className="stepper-title pl-2 pr-2">
                    <span className="stepper-step">
                        Paso {props.step} de {props.totalStep}
                    </span>
                    <span className="stepper-step-name">{props.stepName}</span>
                    <div className="progress">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(props.step / props.totalStep) * 100}%` }}
                            aria-valuenow={(props.step / props.totalStep) * 100}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        ></div>
                    </div>
                </div>
                <div className="stepper-buttons mt-2 mt-md-0  mr-md-2">
                    {(!props.prevButton.isVisible || !props.nextButton.isVisible) && (
                        <div className="d-none d-sm-flex flex-1 mr-md-2" />
                    )}
                    {props.prevButton.isVisible && (
                        <button
                            className="btn btn-dark mr-1 mr-md-2 flex-1"
                            type="button"
                            onClick={props.prevButton.prev}
                        >
                            {props.prevButton.name}
                        </button>
                    )}
                    {props.nextButton.isVisible && (
                        <button
                            className="btn btn-danger ml-1 ml-md-2 flex-1"
                            type="button"
                            onClick={props.nextButton.next}
                        >
                            {props.nextButton.name}
                        </button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
Stepper.propTypes = {
    step: PropTypes.number.isRequired,
    stepName: PropTypes.string.isRequired,
    totalStep: PropTypes.number.isRequired,
    nextButton: PropTypes.shape({
        name: PropTypes.string.isRequired,
        isVisible: PropTypes.bool.isRequired,
        next: PropTypes.func.isRequired,
    }).isRequired,
    prevButton: PropTypes.shape({
        name: PropTypes.string.isRequired,
        isVisible: PropTypes.bool.isRequired,
        prev: PropTypes.func.isRequired,
    }).isRequired,
};

export default Stepper;
