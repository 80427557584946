import React from 'react';
import { Table } from 'reactstrap';
import Collapse from '../../Utils/Collapse';
import {
    OPCIONES_ITEMS,
    DRIVER_MANUALES,
    INFORME_CARGA_COMPLETA,
    INFORME_FINALIZADO,
} from '../../../../utility/constants';
import ReactSpinner from '../../Utils/Spinner/';
import { getArchivo, getReporte } from '../../Utils/utilidades';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { api } from 'api';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import ErrorIcon from '../../../../../assets/img/error.png';
import Swal from 'sweetalert2';

const REPORTES = [
    { value: 1, label: 'Detalle gastos directos' },
    { value: 2, label: 'Detalle gastos bolson y abc' },
    { value: 3, label: 'Integración de distribución actividades de soporte' },
];

const themeOfSelect = theme => ({
    ...theme,
    border: 'none',
    background: 'white',
    colors: {
        ...theme.colors,
        text: '#415362',
        primary25: '#ebebeb',
        primary: '#0a5995',
    },
});

// ESTADOS CELERY
const STARTED = 'STARTED';
const FAILURE = 'FAILURE';

const TabUno = props => {
    const [loader, setLoader] = React.useState(false);
    const [consultar, setConsultar] = React.useState(false);
    const { id, estado, progreso_dist_final, estado_dist_final } = props.informe;
    const [reporteConsultar, setReporteConsultar] = React.useState(undefined);

    const descargarArchivo = idArchivo => {
        const params = {};
        params.informe = id;
        params.archivo = idArchivo;
        api.get('descargar/archivo', params)
            .then(response => {
                getArchivo(response.archivo);
            })
            .catch(error => {
                let mensajeError = 'Archivo no existe';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            });
    };
    const descargarExcel = () => {
        setLoader(true);
        const params = {};
        params.informe = id;
        api.get('descargar/generar_excel_drivers', params)
            .then(e => {
                setReporteConsultar('ARCHIVO_REP_DRIVERS');
                setConsultar(true);
            })
            .catch(error => {
                NotificationManager.error('No se puedo generar el reporte.', 'Error', 7000);
                setReporteConsultar(undefined);
                setConsultar(false);
            });
    };

    const EjecutarDistribucionFinal = informe => {
        Swal.fire({
            title: 'No se completo la Distribución Gastos Estructura Comercial!',
            text: '¿Desea ejecutarla de nuevo?',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowEnterKey: false,
            allowOutsideClick: false,
        }).then(async res => {
            if (res.value) {
                api.post('informe/distribucion_final', {}, { informe: informe.id })
                    .then(response => {
                        props.obtenerInforme(informe.id);
                    })
                    .catch(error => {
                        let mensajeError = 'No se pudo iniciar la Distribucion Gastos Estructura Comercial';
                        if (error && error.detail) mensajeError = error.detail;
                        NotificationManager.error(mensajeError, 'Error', 7000);
                    });
            }
        });
    };

    const mostrar_progreso_dist_final = () => {
        if (estado_dist_final === STARTED) {
            return (
                <div className="d-flex flex-row ml-2 align-items-center">
                    <ReactSpinner />
                    <div className="ml-3">
                        Se a procesado el {progreso_dist_final}% del 100% de la Distribucion Gastos Estructura
                        Comercial.
                    </div>
                </div>
            );
        } else if (estado_dist_final === FAILURE) {
            return (
                <div className="d-flex flex-row ml-2 align-items-center">
                    <img src={ErrorIcon} className="column-data-icon" />
                    <div className="ml-3 mr-1">
                        Ocurrio un error en la Distribucion Gastos Estructura Comercial,{' '}
                        <span
                            className="text-blue text-button"
                            onClick={() => EjecutarDistribucionFinal(props.informe)}
                        >
                            volver a distribuir.
                        </span>
                    </div>
                </div>
            );
        } else {
            return <div className="col-12 p-0 mb-4 col-md-5 col-lg-3 mb-md-0" />;
        }
    };

    const descargarReporte = opcion => {
        setLoader(true);
        const params = {};
        params.informe = id;
        switch (opcion) {
            case 1:
                getArchivo(api.makeUrl('descargar/detalle_gastos_directos', params));
                setLoader(false);
                break;
            case 2:
                api.get('descargar/detalle_gastos_bolson_abc', params)
                    .then(e => {
                        setReporteConsultar('ARCHIVO_BOLSON_ABC');
                        setConsultar(true);
                    })
                    .catch(error => {
                        NotificationManager.error('No se puedo generar el reporte.', 'Error', 7000);
                        setConsultar(false);
                        setReporteConsultar(undefined);
                        setLoader(false);
                    });
                break;
            case 3:
                api.get('descargar/detalle_distribucion_soporte', params)
                    .then(e => {
                        setReporteConsultar('ARCHIVO_DIST_SOPORTE');
                        setConsultar(true);
                    })
                    .catch(error => {
                        NotificationManager.error('No se puedo generar el reporte.', 'Error', 7000);
                        setConsultar(false);
                        setReporteConsultar(undefined);
                        setLoader(false);
                    });
                break;
        }
    };

    const consultarEstado = () => {
        api.get('task/estado', { informe: id, driver: reporteConsultar })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    setConsultar(false);
                    const params = {};
                    params.informe = id;
                    params.archivo = '';
                    if (reporteConsultar === 'ARCHIVO_DIST_SOPORTE') {
                        getReporte('/descargar/detalle_distribucion_soporte', params).finally(e => setLoader(false));
                    } else if (reporteConsultar === 'ARCHIVO_REP_DRIVERS') {
                        getReporte('/descargar/generar_excel_drivers', params).finally(e => setLoader(false));
                    } else if (reporteConsultar === 'ARCHIVO_BOLSON_ABC') {
                        getReporte('/descargar/detalle_gastos_bolson_abc', params).finally(e => setLoader(false));
                    }
                } else if (response.status === 'FAILURE') {
                    NotificationManager.error('No se puedo generar el reporte.', 'Error', 6000);
                    setConsultar(false);
                    setLoader(false);
                    setReporteConsultar(undefined);
                }
            })
            .catch(e => {
                let msj = 'No se puedo generar el reporte.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 7000);
                setConsultar(false);
                setLoader(false);
                setReporteConsultar(undefined);
            });
    };

    const irAConfig = () => {
        const { history } = props;
        if (estado_dist_final == STARTED) {
            Swal.fire({
                title: 'La Distribucion Gastos Estructura Comercial se esta ejecutando!',
                text: '¿Desea cancelarla?',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                allowEnterKey: false,
                allowOutsideClick: false,
            }).then(async res => {
                if (res.value) {
                    api.post('informe/cancelar_distribucion_final', {}, { informe: id })
                        .then(response => {
                            props.obtenerInforme(id);
                            history.push(`/informe/configuracion-costos/${id}`);
                        })
                        .catch(error => {
                            let mensajeError = 'No se pudo cancelar la Distribucion Gastos Estructura Comercial';
                            if (error && error.detail) mensajeError = error.detail;
                            NotificationManager.error(mensajeError, 'Error', 7000);
                        });
                }
            });
        } else {
            history.push(`/informe/configuracion-costos/${id}`);
        }
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 30000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);
    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                {estado === INFORME_FINALIZADO && (
                    <div className="d-flex justify-content-start">
                        <span className="text-blue">Descargar reportes</span>
                    </div>
                )}
                <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
                    {estado === INFORME_FINALIZADO ? (
                        <div className="col-12 p-0 mb-4 col-md-5 col-lg-3 mb-md-0">
                            <Select
                                isClearable={false}
                                className="form-control p-0 w-20 col-12 border-trasparent"
                                autoFocus
                                value={null}
                                isSearchable={false}
                                onChange={e => {
                                    descargarReporte(e.value);
                                }}
                                theme={themeOfSelect}
                                classNamePrefix="react-select"
                                placeholder="Seleccionar"
                                cache={false}
                                options={REPORTES}
                            />
                        </div>
                    ) : (
                        mostrar_progreso_dist_final()
                    )}
                    <div className="d-flex flex-column flex-sm-row justify-content-end mt-3 mt-md-0">
                        {(estado === INFORME_FINALIZADO || estado === INFORME_CARGA_COMPLETA) && (
                            <React.Fragment>
                                <button
                                    type="button "
                                    onClick={descargarExcel}
                                    className="btn btn-primary bg-blue btn-text-center mr-0 mb-2 mb-md-0 mr-sm-2"
                                >
                                    Descargar Excels (Drivers)
                                </button>
                                <button onClick={irAConfig} className="btn btn-danger btn-text-center mb-2 mb-md-0">
                                    Configurar costos
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <span className="text-dark">Detalle del informe</span>
                <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                <div className="mt-4">
                    <Collapse key="DRV_AUTO" keyValue="1" nombre="1- Driver automáticos">
                        <br />
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th width="40%" style={{ textAlign: 'center' }}>
                                        Archivo
                                    </th>
                                    <th width="60%">Nombre</th>
                                </tr>
                            </thead>
                            <tbody>
                                {OPCIONES_ITEMS.filter(item => item.carga === 'automatica').map(item => {
                                    return (
                                        <tr key={item.value}>
                                            <td width="40%" style={{ textAlign: 'left' }}>
                                                <p className="ml-0 pl-0 ml-md-5 pl-md-5 mb-0">{item.value}</p>
                                            </td>
                                            <td width="60%">{item.label}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Collapse>

                    <div className="m-2" />
                    <Collapse key="DRV_MANUAL" keyValue="2" nombre="2- Driver Manuales">
                        <br />
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th width="60%">Nombre</th>
                                    <th width="40%">Archivo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DRIVER_MANUALES.map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td width="60%">{item.nombre}</td>
                                            <td width="40%">
                                                <button
                                                    onClick={e => descargarArchivo(item.idArchivo)}
                                                    className="btn btn-primary bg-blue mt-2 mb-3"
                                                >
                                                    Descargar Archivo
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Collapse>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default withRouter(TabUno);
