import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Cabecera from '../../Utils/Cabeceras/CabeceraBreadCrumbs';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import CardInforme from './CardInforme';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets';
import '../Inicio.css';
import './Historial.css';

let intervalo;

const Detalle = props => {
    const { anio, mes } = props.match.params;
    const { history } = props;

    React.useEffect(() => {
        const { getHistorical } = props;

        async function historial() {
            await getHistorical(anio, mes, 1);
        }
        historial().then();
        //Volvemos a consultar cada 30 seg
        intervalo = setInterval(() => {
            historial().then();
        }, 30000);

        return () => {
            clearInterval(intervalo);
        };
    }, [props.match.params]);

    const onChangeAnio = e => {
        history.push(`/historial/${e.target.value}/${mes}`);
    };
    const onChangeMes = e => {
        history.push(`/historial/${anio}/${e.target.value}`);
    };

    const { historical, page, getHistorical } = props;
    return (
        <React.Fragment>
            <LoadMask loading={props.loader} blur>
                <div className="card px-4 my-3 pb-4">
                    <Cabecera mes={mes} anio={anio} onChangeAnio={onChangeAnio} onChangeMes={onChangeMes} />
                    <div className="d-flex flex-row  justify-content-between align-items-center">
                        <Link to={`/crear-informe/${anio}/${mes}`} className="btn btn-primary ml-4">
                            Nueva version
                        </Link>
                        <Pagination
                            current={page}
                            total={historical.count}
                            className="mr-4"
                            onChange={newPage => {
                                getHistorical(anio, mes, newPage);
                            }}
                        />
                    </div>
                    <div className="row d-flex justify-content-center flex-wrap py-4">
                        {historical && historical.results ? (
                            historical.results.map(entry => <CardInforme key={entry.id} entry={entry} />)
                        ) : (
                            <h1>No hay datos</h1>
                        )}
                    </div>
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default Detalle;
