import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logOut } from '../../../../redux/modules/cuenta/login';

import IconBitacora from '../../../../../assets/img/archivo.png';
const defaultAvatar = require('assets/img/avatar-placeholder.png');

const CabeceraBitacora = props => {
    const [toggle, setToggle] = React.useState(false);
    const { me, logOut } = props;
    return (
        <React.Fragment>
            <div className="d-flex pt-4">
                <div className="d-flex flex-4 flex-column ">
                    <div className="d-flex justify-content-start ml-5 ml-md-0">
                        <img className="icon mr-3" src={IconBitacora} alt="Bitacora" />
                        <h5 className="cabecera-title">Bitacora</h5>
                    </div>
                    <hr className="w-100 pt-0 mt-0" />
                </div>
                <div className="d-none d-md-flex justify-content-end flex-1">
                    <ul className="d-flex flex-column ml-1">
                        <Dropdown isOpen={toggle} toggle={e => setToggle(!toggle)} className="dropdown-cabecera">
                            <DropdownToggle color="white" caret className="p-0 pb-2 border-0">
                                <img
                                    className="rounded-circle mr-3 icon"
                                    src={me && me.avatar ? me.avatar : defaultAvatar}
                                    alt="User Avatar"
                                />
                                <span className="d-none d-md-inline-block">{me.nombres}</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <div onClick={logOut}>
                                        <i className="material-icons text-danger"></i>
                                        <span className="d-none d-md-inline d-lg-inline">Salir</span>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <hr className="w-100 p-0 m-0" />
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

const ms2p = state => {
    return {
        ...state.login,
    };
};

const md2p = { logOut };

export default connect(ms2p, md2p)(CabeceraBitacora);
