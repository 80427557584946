import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import ErrorIcon from '../../../../../../../assets/img/error.png';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField.js';

const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { errores } = props;
    return (
        <React.Fragment>
            {errores && errores.count > 0 && (
                <React.Fragment>
                    <br />
                    <br />
                    <span className="text-dark"> Registros con errores</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />

                    <Grid
                        data={{
                            results: paginate(errores.results, pageErrores),
                            count: errores.count,
                        }}
                        onPageChange={setPageErrores}
                        sizePerPage={10}
                        page={pageErrores}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={ErrorIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="50rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="20rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="20rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="20rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="20rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="20rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="20rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="15rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="CANTIDAD"
                            dataAlign="right"
                            width="10rem"
                            dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                        >
                            CANTIDAD
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
