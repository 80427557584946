import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/inicio/inicio';
import Historial from './Historial';

const ms2p = state => {
    return {
        ...state.inicio,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Historial);
