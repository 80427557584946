import React from 'react';
import { Form, Field } from 'react-final-form';
import { renderField } from '../../Utils/renderField';
import { Link } from 'react-router-dom';
import Cabecera from '../../Utils/Cabeceras/CabeceraProyecto';

const validate = values => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = 'Campo requerido';
    }
    return errors;
};

const CrearEditarProyecto = props => {
    const [nombre, setNombre] = React.useState(undefined);
    React.useEffect(() => {
        const { id } = props.match.params;
        if (id) props.leer(id);
    }, []);

    React.useEffect(() => {
        const { id } = props.match.params;
        if (id && props.item && props.item.nombre) setNombre(props.item.nombre);
        else setNombre(undefined);
    }, [props.item]);

    const onSubmit = data => {
        const { id } = props.match.params;
        const body = { ...data };

        if (id) props.editar(id, { nombre: body.nombre });
        else props.crear({ nombre: body.nombre });
    };
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Proyectos" />
                <br className="d-none d-md-flex" />
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    initialValues={{ nombre: nombre }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className="my-5 py-5">
                            <div className="d-flex flex-column col-12 col-md-5 col-lg-4 m-auto py-4">
                                <label className="form-label" htmlFor="nombre">
                                    Nombre del proyecto
                                </label>
                                <div className="d-flex flex-row">
                                    <Field name="nombre" type="text" component={renderField} className="flex-1" />
                                </div>
                            </div>

                            <div className="d-flex mt-4 justify-content-center">
                                <button disabled={submitting} className="btn btn-danger mr-2" type="submit">
                                    Guardar
                                </button>
                                <Link to="/proyectos" className="btn btn-dark ml-2">
                                    Cancelar
                                </Link>
                            </div>
                        </form>
                    )}
                </Form>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </React.Fragment>
    );
};
export default CrearEditarProyecto;
