import React from 'react';
import UploadDriver from '../../../../Utils/UploadDriver';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import PasoIntermedio from './PasoIntermedio';
import PasoFinal from './PasoFinal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconoAlerta from '../../../../../../../assets/img/alerta-xl.png';
import useCopiarDriver from '../useCopiarDriver';
import { api } from 'api';
import _ from 'lodash';
const dataSeed = {
    results: [],
    count: 0,
};

const MetrosCuadrados = props => {
    const [driverTitle, setDriverTitle] = React.useState('Metros cuadrados por centro de costo');
    const [errores, setErrores] = React.useState(dataSeed);
    // SE HABILITA EL PASO INTERMEDIO SI EXISTE UNA RESPUESTA DEL BACKEND
    const [intermedio, setIntermedio] = React.useState(false);
    // SE HABILITA EL PASO FINAL SI TODO ESTA BIEN
    const [final, setFinal] = React.useState(false);
    // USAMOS ESTA VARIABLE  PARA SABER SI CONSULTAR EL ESTADO DE LA O NO
    const [consultar, setConsultar] = React.useState(false);
    const { setLoader, paso, informe, history } = props;

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });

    const next = async () => {
        if (intermedio) {
            const mySwal = withReactContent(Swal);
            await mySwal
                .fire({
                    title: 'Al aceptar estarás guardando los datos nuevos y actualizados',
                    text: '¿Deseas continuar?',
                    iconHtml: <img src={IconoAlerta} />,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                })
                .then(async res => {
                    if (res.value) {
                        await guardar({ informe: informe.id }, { driver: 'DRIVER_MT2' });
                    }
                });
        } else {
            const siguiente = paso + 1;
            history.push(`/informe/${informe.id}/${siguiente}`);
        }
    };
    const prev = () => {
        if (!final && !intermedio) {
            const anterior = paso - 1;
            history.push(`/informe/${informe.id}/${anterior}`);
        } else {
            setFinal(false);
            setIntermedio(false);
            setNextButton(s => ({ ...s, isVisible: false }));
        }
    };

    const guardar = async (data, params) => {
        setLoader(true);
        await api
            .post('metros-cuadrados', data, params)
            .then(async res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al guardar datos de Metros Cuadrados';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const cargar = async (data, files = [], params) => {
        setLoader(true);
        await api
            .postAttachments('metros-cuadrados/cargar_archivo', data, files, params)
            .then(async res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al cargar archivo de Metros Cuadrados';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const { copiarDriver } = useCopiarDriver(
        informe,
        'COPIA_METROS_CUADRADOS',
        paso,
        setLoader,
        setNextButton,
        setFinal,
        props.next
    );

    const consultarEstado = () => {
        api.get('task/estado', { informe: informe.id, driver: 'DRIVER_MT2' })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    const siguiente = paso + 1;
                    props.next(informe.id, siguiente).then();
                    setFinal(true);
                    setIntermedio(false);
                    setConsultar(false);
                    setNextButton(s => ({ ...s, isVisible: true }));
                    NotificationManager.success(
                        'Archivo del driver Metros Cuadrados cargado exitosamente',
                        'Éxito',
                        4000
                    );
                } else if (response.status === 'UPLOADED') {
                    guardar({ informe: informe.id }, { driver: 'DRIVER_MT2' }).then();
                } else if (response.status === 'FAILURE') {
                    if (response.result && response.result.errores) {
                        setIntermedio(true);
                        setFinal(false);
                        setErrores({ results: response.result.errores, count: response.result.errores.length });
                        setNextButton(s => ({ ...s, isVisible: false }));
                    } else if (
                        response.result &&
                        response.result.exc_message &&
                        _.isString(response.result.exc_message)
                    ) {
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                        setNextButton(s => ({ ...s, isVisible: false }));
                    } else {
                        NotificationManager.error(
                            'Ocurrió un error al cargar archivo de driver Metros Cuadrados',
                            'Error',
                            5000
                        );
                        setNextButton(s => ({ ...s, isVisible: false }));
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = 'La tarea que procesa el driver no se pudo ejecutar o fallo.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 5000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 15000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    React.useEffect(() => {
        return setLoader(false);
    }, []);

    const onCopy = async () => {
        await copiarDriver('metros-cuadrados/copiar_datos', 'DRIVER_MT2');
    };
    const onChangeFile = async file => {
        const files = [];
        files.push({ name: 'archivo', file: file });
        await cargar({ informe: informe.id }, files, { driver: 'DRIVER_MT2' });
    };

    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="Metros Cuadrados"
                totalStep={props.totalPasos}
                nextButton={{ ...nextButton, next: next }}
                prevButton={{ ...prevButton, prev: prev }}
            />

            {final && <PasoFinal setLoader={setLoader} informe={informe} />}
            {intermedio && <PasoIntermedio errores={errores} />}
            {!final && !intermedio && (
                <UploadDriver driverTitle={driverTitle} onCopy={onCopy} onChange={onChangeFile} />
            )}
        </React.Fragment>
    );
};

export default MetrosCuadrados;
