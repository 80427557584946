import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import AlertIcon from '../../../../../../../assets/img/alerta.png';
import ErrorIcon from '../../../../../../../assets/img/error.png';

const PasoIntermedio = props => {
    const [pageErrores26, setPageErrores26] = React.useState(1);
    const [pageAlertas26, setPageAlertas26] = React.useState(1);
    const [pageErrores27, setPageErrores27] = React.useState(1);
    const [pageAlertas27, setPageAlertas27] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { errores26, errores27, alertas26, alertas27 } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {errores26 && errores26.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores driver 26</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores26.results, pageErrores26),
                            count: errores26.count,
                        }}
                        onPageChange={setPageErrores26}
                        sizePerPage={10}
                        page={pageErrores26}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={ErrorIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="50rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="15rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="15rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="15rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="15rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="15rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="25rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {errores27 && errores27.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores driver 27</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(errores27.results, pageErrores27),
                            count: errores27.count,
                        }}
                        onPageChange={setPageErrores27}
                        sizePerPage={10}
                        page={pageErrores27}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={ErrorIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ERROR" width="50rem">
                            ERROR
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="15rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="15rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="15rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="15rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="15rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="25rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {alertas26 && alertas26.count > 0 && (
                <React.Fragment>
                    <span className="text-dark">Registros con alertas driver 26</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />

                    <Grid
                        data={{
                            results: paginate(alertas26.results, pageAlertas26),
                            count: alertas26.count,
                        }}
                        onPageChange={setPageAlertas26}
                        sizePerPage={10}
                        page={pageAlertas26}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ALERTA" width="50rem">
                            ALERTA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="15rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="15rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="15rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="15rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="15rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="25rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
            {alertas27 && alertas27.count > 0 && (
                <React.Fragment>
                    <span className="text-dark">Registros con alertas driver 27</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />

                    <Grid
                        data={{
                            results: paginate(alertas27.results, pageAlertas27),
                            count: alertas27.count,
                        }}
                        onPageChange={setPageAlertas27}
                        sizePerPage={10}
                        page={pageAlertas27}
                        scrollTable
                    >
                        <TableHeaderColumn
                            dataField="CODPROD"
                            dataAlign="center"
                            isKey
                            width="7rem"
                            dataFormat={data => {
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ALERTA" width="50rem">
                            ALERTA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODUNIDAD" width="10rem">
                            CÓDIGO DE UNIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="UNIDAD_COMERCIAL" width="15rem">
                            UNIDAD COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODCANAL" width="10rem">
                            CÓDIGO DE CANAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CANAL_COMERCIAL" width="15rem">
                            CANAL COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODINTER" width="10rem">
                            CÓDIGO DE INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="INTERMEDIARIO" width="15rem">
                            INTERMEDIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_COMERCIAL" width="15rem">
                            RAMO COMERCIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="RAMO_CONTABLE" width="15rem">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CODPROD" width="10rem">
                            CÓDIGO DE PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NOMBRE_PRODUCTO" width="25rem">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="NUMPOL" width="10rem">
                            POLIZA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="CLIENTE" width="25rem">
                            CLIENTE
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
