import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField';

import ErrorIcon from '../../../../../../../assets/img/error.png';

export default function VistaErrores(props) {
    const [pageErrores, setPageErrores] = React.useState(1);
    const [pageErrores2, setPageErrores2] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { erroresBolsonAbc, erroresActividades } = props;

    return (
        <React.Fragment>
            <br />
            <br />
            {erroresBolsonAbc && erroresBolsonAbc.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores al distribuir bolsón y abc</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <div className="custom-scroll-step-7">
                        <Grid
                            data={{
                                results: paginate(erroresBolsonAbc.results, pageErrores),
                                count: erroresBolsonAbc.count,
                            }}
                            onPageChange={setPageErrores}
                            sizePerPage={10}
                            page={pageErrores}
                        >
                            <TableHeaderColumn
                                dataField="referencia"
                                dataAlign="center"
                                width="5rem"
                                isKey
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={ErrorIcon} />;
                                }}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="ERROR" width="45rem">
                                ERROR
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="referencia" width="15rem">
                                REFERENCIA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="empresa" width="7rem">
                                EMPRESA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="centro_costo" width="10rem">
                                CENTRO DE COSTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="no_cuenta" width="7rem">
                                CUENTA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="tipo" width="7rem">
                                TIPO GASTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="distribucion" width="10rem">
                                DISTRIBUIDO POR:
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="cuenta_abc">CUENTA ABC</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="monto"
                                width="10rem"
                                dataAlign="right"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                MONTO
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
            <br />
            <br />
            {erroresActividades && erroresActividades.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con errores al distribuir por actividades</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <div className="custom-scroll-step-7">
                        <Grid
                            data={{
                                results: paginate(erroresActividades.results, pageErrores2),
                                count: erroresActividades.count,
                            }}
                            onPageChange={setPageErrores2}
                            sizePerPage={10}
                            page={pageErrores2}
                        >
                            <TableHeaderColumn
                                dataField="referencia"
                                dataAlign="center"
                                width="5rem"
                                isKey
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={ErrorIcon} />;
                                }}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="ERROR" width="45rem">
                                ERROR
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="referencia" width="15rem">
                                REFERENCIA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="empresa" width="7rem">
                                EMPRESA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="centro_costo" width="10rem">
                                CENTRO DE COSTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="codigo_gnt" width="7rem">
                                GNT
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="tipo" width="7rem">
                                TIPO GASTO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="distribucion" width="10rem">
                                DISTRIBUIDO POR:
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="cuenta_abc">CUENTA ABC</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="monto"
                                width="10rem"
                                dataAlign="right"
                                dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                            >
                                MONTO
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
