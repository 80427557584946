import { api } from 'api';
import { NotificationManager } from 'react-notifications';
import { useState, useEffect } from 'react';

export default function useCopiarDriver(
    informe,
    nombreTarea = 'COPIA_DRIVER',
    paso,
    setLoader = undefined,
    setNextButton = undefined,
    setFinal = undefined,
    irSiguientePaso = undefined
) {
    const [consultar, setConsultar] = useState(false);

    let interval;
    useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultaTareaCopiado();
            }, 30000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    const consultaTareaCopiado = () => {
        api.get('task/estado', { informe: informe.id, driver: nombreTarea })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    const siguiente = paso + 1;
                    if (irSiguientePaso) irSiguientePaso(informe.id, siguiente).then();
                    if (setFinal) setFinal(true);
                    setConsultar(false);
                    if (setNextButton) setNextButton(s => ({ ...s, isVisible: true }));
                    NotificationManager.success('Copia del driver del mes anterior exitosa', 'Éxito', 4000);
                } else if (response.status === 'FAILURE') {
                    if (response.result && response.result.exc_message) {
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                    } else {
                        NotificationManager.error('Ocurrió un error desconocido', 'Error', 7000);
                    }
                    setConsultar(false);
                    if (setLoader) setLoader(false);
                }
            })
            .catch(e => {
                let msj = 'La tarea que copia el driver no se pudo ejecutar o fallo.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 5000);
                setConsultar(false);
                if (setLoader) setLoader(false);
            });
    };

    const copiarDriver = async (ruta_backend = 'driver/manual/copiar_datos', ids_driver = []) => {
        if (setLoader) setLoader(true);
        await api
            .post(ruta_backend, { informe: informe.id, ids_driver: ids_driver })
            .then(() => {
                NotificationManager.success('Se inicio proceso de copia del driver', 'Éxito', 5000);
                setConsultar(true);
                if (setLoader) setLoader(true);
            })
            .catch(error => {
                let mensajeError = 'Error al copiar driver del mes anterior';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                if (setLoader) setLoader(false);
                console.log(error);
            });
    };

    return { copiarDriver };
}
