import React from 'react';
import './verify_login.css';
import IconoGyt from '../../../../../assets/img/0-Oficial-Rojo-Seguros-G_T.svg';
import { Progress } from 'reactstrap';

export default function VerifyLogin() {
    const [value, setValue] = React.useState(25);
    React.useEffect(() => {
        let progress = 1;
        let id = setInterval(frame, 25);
        function frame() {
            if (progress > 100) {
                clearInterval(id);
            } else {
                setValue(progress++);
            }
        }
        return () => clearInterval(id);
    }, []);

    return (
        <div className="verify-login-container">
            <div className="verify-login-box">
                <div className="flex p-4">
                    <img src={IconoGyt} />
                </div>
                <Progress className="custom-progress-bar" value={value} />
                <div className="flex p-5">
                    <p>Cargando...</p>
                </div>
            </div>
        </div>
    );
}
