import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { NotificationManager } from 'react-notifications';

import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import { RenderNumber } from '../../../../Utils/renderField/renderReadField.js';
import { api } from 'api';

const pageSize = 10;

const dataSeed = {
    results: [],
    count: 0,
};

const PasoFinal = props => {
    const [data, setData] = React.useState(dataSeed);
    const [data2, setData2] = React.useState(dataSeed);
    const [page, setPage] = React.useState(1);
    const [page2, setPage2] = React.useState(1);

    const { setLoader } = props;

    React.useEffect(() => {
        listar(page, 'DRIVER_26');
    }, [page]);

    React.useEffect(() => {
        listar(page2, 'DRIVER_27');
    }, [page2]);

    const listar = (page, id_driver) => {
        setLoader(true);
        api.get('driver/manual', { id_informe: props.informe.id, page, id_driver, page_size: pageSize })
            .then(res => {
                if (id_driver === 'DRIVER_26') setData(res);
                else if (id_driver === 'DRIVER_27') setData2(res);
            })
            .catch(error => {
                let mensajeError = 'Error al obtener los registros del driver 20, 21, 22';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <React.Fragment>
            <br />
            <br />
            <span className="text-dark">Registros de driver 26</span>
            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
            <Grid data={data} onPageChange={setPage} sizePerPage={pageSize} page={page} scrollTable>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width="5rem"
                    dataFormat={data => {
                        return <img className="column-data-icon" src={OkIcon} />;
                    }}
                >
                    ESTADO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_unidad" width="12rem">
                    CÓDIGO DE UNIDAD
                </TableHeaderColumn>
                <TableHeaderColumn dataField="unidad">UNIDAD COMERCIAL</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_canal" width="12rem">
                    CÓDIGO DE CANAL
                </TableHeaderColumn>
                <TableHeaderColumn dataField="canal">CANAL COMERCIAL</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_intermediario" width="12rem">
                    CÓDIGO DE INTERMEDIARIO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="intermediario">INTERMEDIARIO</TableHeaderColumn>
                <TableHeaderColumn dataField="ramo_comercial">RAMO COMERCIAL</TableHeaderColumn>
                <TableHeaderColumn dataField="ramo_contable">RAMO CONTABLE</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_producto" width="12rem">
                    CÓDIGO DE PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="producto">PRODUCTO</TableHeaderColumn>
                <TableHeaderColumn dataField="numero_poliza" width="10rem">
                    POLIZA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="cliente">CLIENTE</TableHeaderColumn>
                <TableHeaderColumn dataField="mes" width="5rem">
                    MES
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="porcentaje"
                    width="10rem"
                    dataAlign="right"
                    dataFormat={value => (
                        <RenderNumber className="remove-style" suffix={' %'} decimalScale={2} value={value} />
                    )}
                >
                    PORCENTAJE
                </TableHeaderColumn>
                <TableHeaderColumn dataField="valor" width="10rem" dataAlign="right">
                    CANTIDAD
                </TableHeaderColumn>
            </Grid>
            <br />
            <br />
            <span className="text-dark">Registros de driver 27</span>
            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
            <Grid data={data2} onPageChange={setPage2} sizePerPage={10} page={page2} scrollTable>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width="5rem"
                    dataFormat={data => {
                        return <img className="column-data-icon" src={OkIcon} />;
                    }}
                >
                    ESTADO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_unidad" width="12rem">
                    CÓDIGO DE UNIDAD
                </TableHeaderColumn>
                <TableHeaderColumn dataField="unidad">UNIDAD COMERCIAL</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_canal" width="12rem">
                    CÓDIGO DE CANAL
                </TableHeaderColumn>
                <TableHeaderColumn dataField="canal">CANAL COMERCIAL</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_intermediario" width="12rem">
                    CÓDIGO DE INTERMEDIARIO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="intermediario">INTERMEDIARIO</TableHeaderColumn>
                <TableHeaderColumn dataField="ramo_comercial">RAMO COMERCIAL</TableHeaderColumn>
                <TableHeaderColumn dataField="ramo_contable">RAMO CONTABLE</TableHeaderColumn>
                <TableHeaderColumn dataField="codigo_producto" width="12rem">
                    CÓDIGO DE PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="producto">PRODUCTO</TableHeaderColumn>
                <TableHeaderColumn dataField="numero_poliza" width="10rem">
                    POLIZA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="cliente">CLIENTE</TableHeaderColumn>
                <TableHeaderColumn dataField="mes" width="5rem">
                    MES
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="porcentaje"
                    width="10rem"
                    dataAlign="right"
                    dataFormat={value => (
                        <RenderNumber className="remove-style" suffix={' %'} decimalScale={2} value={value} />
                    )}
                >
                    PORCENTAJE
                </TableHeaderColumn>
                <TableHeaderColumn dataField="valor" width="10rem" dataAlign="right">
                    CANTIDAD
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

export default PasoFinal;
