import React from 'react';
import UploadDriver from '../../../../Utils/UploadDriver';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PasoIntermedio from './PasoIntermedio';
import PasoFinal from './PasoFinal';
import IconoAlerta from '../../../../../../../assets/img/alerta-xl.png';
import { api } from 'api';
import _ from 'lodash';
import useCopiarDriver from '../useCopiarDriver';
const dataSeed = {
    results: [],
    count: 0,
};

const PesoSalarial = props => {
    const [driverTitle, setDriverTitle] = React.useState('Peso salarial');
    const [errores, setErrores] = React.useState(dataSeed);
    const [errores_porcentaje, setErrores_Porcentaje] = React.useState(dataSeed);
    const [errores_gnt, setErrores_gnt] = React.useState(dataSeed);
    const [errores_empleados, setErrores_empleados] = React.useState(dataSeed);
    const [nuevos_gnt, setNuevos_gnt] = React.useState(dataSeed);
    const [nuevos_empleados, setNuevos_empleados] = React.useState(dataSeed);
    const [actualizados_gnt, setActualizados_gnt] = React.useState(dataSeed);
    const [actualizados_empleados, setActualizados_empleados] = React.useState(dataSeed);
    // USAMOS ESTA VARIABLE  PARA SABER SI CONSULTAR EL ESTADO DE LA O NO
    const [consultar, setConsultar] = React.useState(false);
    const [consultarGuardado, setConsultarGuardado] = React.useState(false);
    // SE HABILITA EL PASO INTERMEDIO SI EXISTE UNA RESPUESTA DEL BACKEND
    const [intermedio, setIntermedio] = React.useState(false);
    // SE HABILITA EL PASO FINAL SI TODO ESTA BIEN
    const [final, setFinal] = React.useState(false);
    const { setLoader, paso, informe, history } = props;

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });
    const next = async () => {
        if (intermedio) {
            const mySwal = withReactContent(Swal);
            await mySwal
                .fire({
                    title: 'Al aceptar estarás guardando los datos nuevos y actualizados',
                    text: '¿Deseas continuar?',
                    iconHtml: <img src={IconoAlerta} />,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                })
                .then(async res => {
                    if (res.value) {
                        await guardar({ informe: informe.id });
                    }
                });
        } else {
            const siguiente = paso + 1;
            history.push(`/informe/${informe.id}/${siguiente}`);
        }
    };
    const prev = () => {
        if (!final && !intermedio) {
            const anterior = paso - 1;
            history.push(`/informe/${informe.id}/${anterior}`);
        } else {
            setFinal(false);
            setIntermedio(false);
            setNextButton(s => ({ ...s, isVisible: false }));
        }
    };

    const cargar = async (data, files = []) => {
        setLoader(true);
        await api
            .postAttachments('peso-salarial/cargar_archivo', data, files, { driver: 'DRIVER_PESO' })
            .then(async res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al cargar peso salarial';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                //setLoader(false);
            });
    };

    const guardar = async data => {
        await setLoader(true);
        await api
            .post('peso-salarial', data, { driver: 'DRIVER_PESO' })
            .then(res => {
                setConsultarGuardado(true);
            })
            .catch(error => {
                let mensajeError = 'Error al guardar peso salarial';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                //setLoader(false);
            });
    };

    const { copiarDriver } = useCopiarDriver(
        informe,
        'COPIA_PESOS_SALARIAL',
        paso,
        setLoader,
        setNextButton,
        setFinal,
        props.next
    );

    const consultarEstadoGuardado = async () => {
        api.get('task/estado', { informe: informe.id, driver: 'DRIVER_PESO' }).then(res => {
            if (res.status == 'SUCCESS') {
                setConsultarGuardado(false);
                NotificationManager.success('Peso salarial cargado exitosamente', 'Éxito', 4000);
                const siguiente = paso + 1;
                props.next(informe.id, siguiente).then();
                setFinal(true);
                setIntermedio(false);
                setNextButton(s => ({ ...s, isVisible: true }));
            } else if (res.status == 'FAILURE') {
                setLoader(false);
                setConsultarGuardado(false);
                if (res.result.detail) NotificationManager.error(res.result.detail, 'Error', 7000);
                else {
                    NotificationManager.error(
                        'Ocurrio un error al intentar guardar el archivo de peso salarial',
                        'Error',
                        7000
                    );
                }
            }
        });
    };

    const consultarEstado = async () => {
        api.get('task/estado', { informe: informe.id, driver: 'DRIVER_PESO' })
            .then(async res => {
                if (res.status === 'INTERMEDIO') {
                    if (
                        res.result.errores.length > 0 ||
                        res.result.errores_gnt.length > 0 ||
                        res.result.errores_empleados.length > 0 ||
                        res.result.errores_porcentaje.length > 0
                    ) {
                        NotificationManager.error(
                            'Peso salarial tiene errores en los siguientes registros',
                            'Error',
                            4000
                        );
                    } else {
                        NotificationManager.warning(
                            'Peso salarial tiene nuevos datos o actualizaciones en los siguientes registros',
                            'Alerta',
                            4000
                        );
                        setNextButton(s => ({ ...s, isVisible: true }));
                    }
                    setErrores_Porcentaje({
                        results: res.result.errores_porcentaje,
                        count: res.result.errores_porcentaje.length,
                    });
                    setErrores({ results: res.result.errores, count: res.result.errores.length });
                    setErrores_gnt({ results: res.result.errores_gnt, count: res.result.errores_gnt.length });
                    setErrores_empleados({
                        results: res.result.errores_empleados,
                        count: res.result.errores_empleados.length,
                    });
                    setNuevos_gnt({ results: res.result.nuevos_gnt, count: res.result.nuevos_gnt.length });
                    setNuevos_empleados({
                        results: res.result.nuevos_empleados,
                        count: res.result.nuevos_empleados.length,
                    });
                    setActualizados_gnt({
                        results: res.result.actualizados_gnt,
                        count: res.result.actualizados_gnt.length,
                    });
                    setActualizados_empleados({
                        results: res.result.actualizados_empleados,
                        count: res.result.actualizados_empleados.length,
                    });
                    setFinal(false);
                    setIntermedio(true);
                    setConsultar(false);
                    setLoader(false);
                } else if (res.status === 'SUCCESS') {
                    setConsultar(false);
                    guardar({ informe: informe.id });
                } else if (res.status === 'FAILURE') {
                    if (res.result.exc_message && _.isString(res.result.exc_message)) {
                        NotificationManager.error(res.result.exc_message, 'Error', 7000);
                    } else {
                        NotificationManager.error(
                            'Ocurrio un error al intentar cargar el archivo de peso salarial',
                            'Error',
                            7000
                        );
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = 'La tarea que procesa el driver no se pudo ejecutar o fallo.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 5000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 15000);
        } else if (consultarGuardado) {
            interval = setInterval(() => {
                consultarEstadoGuardado();
            }, 15000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar, consultarGuardado]);

    React.useEffect(() => {
        return setLoader(false);
    }, []);

    const onCopy = async () => {
        await copiarDriver('peso-salarial/copiar_datos');
    };
    const onChangeFile = async file => {
        const files = [];
        files.push({ name: 'archivo', file: file });
        await cargar({ informe: informe.id }, files);
    };

    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="Peso salarial"
                totalStep={props.totalPasos}
                nextButton={{ ...nextButton, next: next }}
                prevButton={{ ...prevButton, prev: prev }}
            />

            {final && <PasoFinal setLoader={setLoader} informe={informe} />}
            {intermedio && (
                <PasoIntermedio
                    errores={errores}
                    errores_porcentaje={errores_porcentaje}
                    errores_gnt={errores_gnt}
                    errores_empleados={errores_empleados}
                    nuevos_gnt={nuevos_gnt}
                    nuevos_empleados={nuevos_empleados}
                    actualizados_gnt={actualizados_gnt}
                    actualizados_empleados={actualizados_empleados}
                />
            )}
            {!final && !intermedio && (
                <UploadDriver driverTitle={driverTitle} onCopy={onCopy} onChange={onChangeFile} />
            )}
        </React.Fragment>
    );
};

export default PesoSalarial;
