import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { NotificationManager } from 'react-notifications';
import AlertIcon from '../../../../../../../assets/img/alerta.png';
import ErrorIcon from '../../../../../../../assets/img/error.png';
import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import LapizIcon from '../../../../../../../assets/img/editar.png';

import { RenderNumber } from '../../../../Utils/renderField/renderReadField.js';
import { api } from 'api';

const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);
    const [pageIncompletas, setPageIncompletas] = React.useState(1);
    const [pageSinEncuesta, setPageSinEncuesta] = React.useState(1);
    const [pageDespedidos, setPageDespedidos] = React.useState(1);
    const [pageNuevos, setPageNuevos] = React.useState(1);
    const [actividades, setActividades] = React.useState([]);
    const [gestiones, setGestiones] = React.useState([]);

    React.useEffect(() => {
        async function cargarActividades() {
            await api
                .get('actividad')
                .then(response => {
                    if (response) setActividades(response);
                })
                .catch(error => {
                    let mensajeError = 'Error al obtener actividades';
                    if (error && error.detail) mensajeError = error.detail;
                    NotificationManager.error(mensajeError, 'Error', 7000);
                });
        }
        async function cargarGestiones() {
            await api
                .get('gestiones')
                .then(response => {
                    setGestiones(response);
                })
                .catch(error => {
                    let mensajeError = 'Error al obtener gestiones';
                    if (error && error.detail) mensajeError = error.detail;
                    NotificationManager.error(mensajeError, 'Error', 7000);
                });
        }

        cargarActividades().then(cargarGestiones().then());
    }, []);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const onSaveCell = (row, cellName, cellValue) => {
        let copia_nuevos = [...props.nuevos.results];

        copia_nuevos = copia_nuevos.map(item => {
            if (row.REFERENCIA === item.REFERENCIA) {
                item[cellName] = cellValue;

                if (
                    item.ID &&
                    !!item.ID.trim() &&
                    item.GESTION &&
                    !!item.GESTION.trim() &&
                    item.ACTIVIDAD &&
                    !!item.ACTIVIDAD.trim()
                ) {
                    item['ESTADO'] = 'OK';
                } else {
                    item['ESTADO'] = '';
                }
            }

            return item;
        });
        props.setNuevos(s => ({ ...s, results: copia_nuevos }));
    };

    const { incompletas, sinEncuesta, nuevos, despedidos, errores } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {nuevos && nuevos.count > 0 ? (
                <React.Fragment>
                    <span className="text-dark">Nuevas actividades</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <Grid
                        data={{
                            results: paginate(nuevos.results, pageNuevos),
                            count: nuevos.count,
                        }}
                        onPageChange={setPageNuevos}
                        sizePerPage={10}
                        page={pageNuevos}
                        scrollTable
                        cellEdit={{ mode: 'click', blurToSave: true, beforeSaveCell: onSaveCell }}
                    >
                        <TableHeaderColumn
                            dataField="ESTADO"
                            dataAlign="center"
                            width="5rem"
                            editable={false}
                            dataFormat={cell => {
                                if (cell && cell === 'OK') {
                                    return <img className="column-data-icon" src={OkIcon} />;
                                }
                                return <img className="column-data-icon" src={AlertIcon} />;
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="REFERENCIA"
                            width="30rem"
                            tdStyle={{ whiteSpace: 'normal' }}
                            editable={false}
                            isKey
                        >
                            REFERENCIA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ID"
                            width="10rem"
                            dataFormat={value => {
                                if (value && !!value.trim()) return value;
                                return (
                                    <div className="d-flex flex-1">
                                        <img className="row-edit-icon" src={LapizIcon} />
                                    </div>
                                );
                            }}
                        >
                            CODIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="GESTION"
                            width="15rem"
                            editable={false}
                            dataFormat={(cell, row, value) => {
                                //if (value && !!value.trim()) return value;
                                return (
                                    <select onChange={e => onSaveCell(row, 'GESTION', e.target.value)}>
                                        <option hidden value={''} key={''}>
                                            Selecciona una gestion
                                        </option>
                                        {gestiones.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        ))}
                                    </select>
                                );
                            }}
                        >
                            GESTION
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ACTIVIDAD"
                            width="30rem"
                            tdStyle={{ whiteSpace: 'normal' }}
                            dataFormat={value => {
                                if (value && !!value.trim()) return value;
                                return (
                                    <div className="d-flex flex-1">
                                        <img className="row-edit-icon" src={LapizIcon} />
                                    </div>
                                );
                            }}
                        >
                            ACTIVIDAD
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {errores && errores.count > 0 && (
                        <React.Fragment>
                            <span className="text-dark">Empleados repetidos</span>
                            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                            <div className="custom-small-scroll-step-10">
                                <Grid
                                    data={{
                                        results: paginate(errores.results, pageErrores),
                                        count: errores.count,
                                    }}
                                    onPageChange={setPageErrores}
                                    sizePerPage={10}
                                    page={pageErrores}
                                    scrollTable
                                >
                                    <TableHeaderColumn
                                        dataField="CODEMPLEADO"
                                        dataAlign="center"
                                        isKey
                                        width="5rem"
                                        dataFormat={data => {
                                            return <img className="column-data-icon" src={ErrorIcon} />;
                                        }}
                                    >
                                        ESTADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="ERROR" width="40rem">
                                        ERROR
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="EMPRESA" width="10rem">
                                        EMPRESA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="CODEMPLEADO" width="10rem">
                                        CÓDIGO EMPLEADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="EMPLEADO" width="20rem">
                                        EMPLEADO
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </React.Fragment>
                    )}

                    {sinEncuesta && sinEncuesta.count > 0 && (
                        <React.Fragment>
                            <span className="text-dark">Empleados sin encuesta</span>
                            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                            <div className="custom-small-scroll-step-10">
                                <Grid
                                    data={{
                                        results: paginate(sinEncuesta.results, pageSinEncuesta),
                                        count: sinEncuesta.count,
                                    }}
                                    onPageChange={setPageSinEncuesta}
                                    sizePerPage={10}
                                    page={pageSinEncuesta}
                                    scrollTable
                                >
                                    <TableHeaderColumn
                                        dataField="codigo_empleado"
                                        dataAlign="center"
                                        isKey
                                        width="5rem"
                                        dataFormat={data => {
                                            return <img className="column-data-icon" src={ErrorIcon} />;
                                        }}
                                    >
                                        ESTADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="ERROR" width="40rem">
                                        ERROR
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="empresa">EMPRESA</TableHeaderColumn>
                                    <TableHeaderColumn dataField="codigo_empleado">CÓDIGO EMPLEADO</TableHeaderColumn>
                                    <TableHeaderColumn dataField="nombre_empleado" width="25rem">
                                        EMPLEADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="codigo_gnt">CÓDIGO GNT</TableHeaderColumn>
                                    <TableHeaderColumn dataField="codigo_centro_costo">CENTRO COSTO</TableHeaderColumn>
                                </Grid>
                            </div>
                        </React.Fragment>
                    )}

                    {despedidos && despedidos.count > 0 && (
                        <React.Fragment>
                            <span className="text-dark">Empleados que no están en peso salarial</span>
                            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                            <div className="custom-small-scroll-step-10">
                                <Grid
                                    data={{
                                        results: paginate(despedidos.results, pageDespedidos),
                                        count: despedidos.count,
                                    }}
                                    onPageChange={setPageDespedidos}
                                    sizePerPage={10}
                                    page={pageDespedidos}
                                    scrollTable
                                >
                                    <TableHeaderColumn
                                        dataField="CODEMPLEADO"
                                        dataAlign="center"
                                        isKey
                                        width="5rem"
                                        dataFormat={data => {
                                            return <img className="column-data-icon" src={ErrorIcon} />;
                                        }}
                                    >
                                        ESTADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="ERROR" width="40rem">
                                        ERROR
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="EMPRESA" width="10rem">
                                        EMPRESA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="CODEMPLEADO" width="10rem">
                                        CÓDIGO EMPLEADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="EMPLEADO" width="20rem">
                                        EMPLEADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="TOTAL_ACTIVIDAD"
                                        width="10rem"
                                        dataFormat={value => (
                                            <RenderNumber
                                                suffix={' %'}
                                                value={value}
                                                className="remove-style"
                                                decimalScale={2}
                                            />
                                        )}
                                    >
                                        TOTAL ACTIVIDAD
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </React.Fragment>
                    )}

                    {incompletas && incompletas.count > 0 && (
                        <React.Fragment>
                            <span className="text-dark">Encuestas incompletas</span>
                            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                            <div className="custom-big-scroll-step-10">
                                <Grid
                                    data={{
                                        results: paginate(incompletas.results, pageIncompletas),
                                        count: incompletas.count,
                                    }}
                                    onPageChange={setPageIncompletas}
                                    sizePerPage={10}
                                    page={pageIncompletas}
                                    scrollTable
                                >
                                    <TableHeaderColumn
                                        dataField="CODEMPLEADO"
                                        dataAlign="center"
                                        isKey
                                        width="5rem"
                                        dataFormat={data => {
                                            return <img className="column-data-icon" src={ErrorIcon} />;
                                        }}
                                    >
                                        ESTADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="ERROR" width="40rem">
                                        ERROR
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="CODEMPLEADO" width="10rem">
                                        CÓDIGO EMPLEADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="EMPLEADO" width="20rem">
                                        EMPLEADO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="TOTAL_ACTIVIDAD"
                                        width="10rem"
                                        dataFormat={value => (
                                            <RenderNumber
                                                suffix={' %'}
                                                value={value}
                                                className="remove-style"
                                                decimalScale={2}
                                            />
                                        )}
                                    >
                                        TOTAL ACTIVIDAD
                                    </TableHeaderColumn>
                                    {actividades.map(actividad => (
                                        <TableHeaderColumn
                                            dataField={actividad.id}
                                            dataFormat={value => (
                                                <RenderNumber
                                                    suffix={' %'}
                                                    value={value}
                                                    className="remove-style"
                                                    decimalScale={2}
                                                />
                                            )}
                                        >
                                            {actividad.id}
                                        </TableHeaderColumn>
                                    ))}
                                </Grid>
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
