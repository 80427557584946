import React from 'react';
import Grid from '../../../../Utils/Grid';
import ExpandColumnComponent from '../../../../Utils/ExpandColumn';
import TablaProyectos from './Proyectos';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';
import Stepper from '../../../../Utils/Stepper';
import { withRouter } from 'react-router-dom';
import './distribuirCompuestas.css';

const dataSeed = {
    results: [],
    count: 0,
};

const DistribuirCompuesto = props => {
    const [page, setPage] = React.useState(1);
    const [data, setData] = React.useState(dataSeed);

    const { paso, totalPasos, setPaso, setLoader, listadoDrivers } = props;
    const { id } = props.match.params;

    React.useEffect(() => {
        const { id } = props.match.params;
        async function cargar_actividades() {
            setLoader(true);
            props.listarDrivers(id);
            await listarActividades(1).then(() => {
                setLoader(false);
            });
        }
        cargar_actividades().then();
    }, []);

    const listarActividades = async (page = 1) => {
        const { id } = props.match.params;
        const params = {};
        params.informe = id;
        params.page = page;
        return await api.get('actividades-compuestas', params).then(response => {
            setData(response);
            setPage(page);
        });
    };

    const onSiguientePaso = () => {
        api.get('actividades-compuestas/no_asignados', { informe: id }).then(response => {
            api.get('actividades-compuestas/incompletos', { informe: id }).then(response2 => {
                if (response2 && response2.incompletos > 0) {
                    const incompletos = response2.incompletos;
                    NotificationManager.error(
                        `No puede continuar, ${incompletos === 1 ? 'existe' : 'existen'} ${incompletos} ${
                            incompletos === 1 ? 'actividad' : 'actividades'
                        } que no se han distribuido al 100%`,
                        'Error',
                        7000
                    );
                } else if (response && response.no_asignados > 0) {
                    const no_asignados = response.no_asignados;
                    NotificationManager.error(
                        `No puede continuar, ${no_asignados === 1 ? 'existe' : 'existen'} ${no_asignados} ${
                            no_asignados === 1 ? 'proyecto' : 'proyectos'
                        } que no se les ha asignado driver`,
                        'Error'
                    );
                } else {
                    api.post('informe/distribucion_final', {}, { informe: id }).then(response => {
                        NotificationManager.success(
                            'Se han iniciado la Distribucion Gastos Estructura Comercial.',
                            'Éxito',
                            5000
                        );
                        props.history.push(`/informe/${id}/detalle`);
                    });
                }
            });
        });
    };

    return (
        <React.Fragment>
            <br />
            <Stepper
                step={paso}
                stepName="Distribución de actividades compuestas"
                totalStep={totalPasos}
                nextButton={{
                    name: 'Siguiente',
                    isVisible: true,
                    next: onSiguientePaso,
                }}
                prevButton={{
                    name: 'Regresar',
                    isVisible: true,
                    prev: () => {
                        setPaso(paso - 1);
                    },
                }}
            />
            <br />
            <br />
            <div className="scroll-table-compuestos">
                <Grid
                    data={data}
                    expandableRow={() => {
                        return true;
                    }}
                    expandComponent={row => (
                        <TablaProyectos
                            row={row}
                            setData={setData}
                            listadoDrivers={listadoDrivers}
                            listarActividades={listarActividades}
                            page={page}
                        />
                    )}
                    expandColumnOptions={{ expandColumnVisible: true, expandColumnComponent: ExpandColumnComponent }}
                    pagination={true}
                    page={page}
                    onPageChange={page => {
                        setLoader(true);
                        listarActividades(page).then(() => setLoader(false));
                    }}
                    sizePerPage={10}
                    striped
                >
                    <TableHeaderColumn dataField="id" width="0" isKey></TableHeaderColumn>
                    <TableHeaderColumn dataField="empresa">EMPRESA</TableHeaderColumn>
                    <TableHeaderColumn dataField="actividad">ACTIVIDAD</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado"
                        dataFormat={value =>
                            value ? (
                                <div className="asignado">ASIGNADO</div>
                            ) : (
                                <div className="no_asignado">PENDIENTE</div>
                            )
                        }
                    >
                        ESTADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        width="12rem"
                        dataAlign="right"
                        dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default withRouter(DistribuirCompuesto);
