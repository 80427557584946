import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/ramoContable/ramoContable';
import CrearEditar from './CrearEditar';

const ms2p = state => {
    return {
        ...state.ramoContable,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditar);
