import React from 'react';
import Table from '../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../Utils/Grid/StandardActions';
import Cabecera from '../../Utils/Cabeceras/CabeceraSimple';
import { Link } from 'react-router-dom';
import EmpleadoIcono from '../../../../../assets/img/empleados.png';

const ListarEmpleados = props => {
    React.useEffect(() => {
        props.searchChange('');
        props.listar();
    }, []);

    const alBuscar = value => {
        props.searchChange(value);
    };
    let timeout = null;
    const alTerminarEscritura = e => {
        clearTimeout(timeout);
        const value = e.target.value;
        timeout = setTimeout(() => {
            alBuscar(value);
        }, 500);
    };

    const { data, page, loader, listar: onPageChange, eliminar, editar } = props;
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Empleados" icono={EmpleadoIcono} />
                <div className="d-flex flex-column ml-3">
                    <div className="d-flex flex-md-row flex-wrap  justify-content-between mt-3 mb-3">
                        <Link to="/empleado" className="btn btn-danger">
                            Nuevo
                        </Link>
                        <input
                            id="buscar-empleados"
                            onKeyUp={alTerminarEscritura}
                            className="form-control col-12 mt-3 mt-md-0 col-md-4 "
                            placeholder="Buscar empleado por codigo, nombre..."
                        ></input>
                    </div>

                    <Table hover striped data={data} page={page} loading={loader} onPageChange={onPageChange}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="left"
                            isKey
                            width="5rem"
                            dataFormat={standardActions({ eliminar, editar: '/empleado' })}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn width="7rem" dataField="id" dataAlign="left">
                            CODIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn width="20rem" dataField="nombre" dataAlign="left">
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="7rem"
                            dataField="no_certificado"
                            dataAlign="left"
                            dataFormat={value => (value ? value : 'Sin Certificado')}
                        >
                            NO. CERTIFICADO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="10rem"
                            dataField="empresa_nombre"
                            dataAlign="left"
                            dataFormat={value => (value ? value : 'Sin Empresa')}
                        >
                            EMPRESA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="7rem"
                            dataField="cc"
                            dataAlign="left"
                            dataFormat={value => (value && value.id ? value.id : 'Sin Centro de Costo')}
                        >
                            CENTRO COSTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="15rem"
                            dataField="gnt"
                            dataAlign="left"
                            dataFormat={value => (value && value.id ? value.nombre : 'Sin Gnt')}
                        >
                            GNT
                        </TableHeaderColumn>
                    </Table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListarEmpleados;
