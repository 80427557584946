import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut, getMe } from './redux/modules/cuenta/login';
import { Redirect } from 'react-router-dom';

// maquetado base
import './ProtectedRoute.css';

import Navbar from './common/components/layout/Navbar/Navbar';
import { VerifyLogin } from './common/components/layout';
import SideBar from './common/components/layout/Sidebar/SideBar';
import gytIcono from './../assets/img/logo-gyt-sm.svg';

class PrivateRouteBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: false,
        };
    }

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    closeMenu = () => {
        this.setState({ toggleOpen: false });
    };

    isAuthenticated = () => {
        const {
            getMe,
            login: { me },
        } = this.props;

        if (!me.username) {
            getMe();
            return 'Verifying';
        } else {
            return true;
        }
    };

    render() {
        const {
            component: Component,
            logOut,
            login: { me },
            ...rest
        } = this.props;
        const isAuthenticated = this.isAuthenticated();

        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            <div className="col-offset-in-xl">
                                <SideBar
                                    toggleOpen={this.state.toggleOpen}
                                    navToggle={this.navToggle}
                                    closeMenu={this.closeMenu}
                                    logOut={logOut}
                                />
                                <main
                                    style={{ transition: 'all 0.2s' }}
                                    className={`main-content p-0 ${
                                        this.state.toggleOpen ? 'main-offset-col-fix' : 'second-offset-col-fix'
                                    }`}
                                >
                                    <div className="main-navbar main-navbar-absolute-left navbar-button d-flex d-md-none">
                                        <span className="text-danger" onClick={() => this.navToggle()}>
                                            <img src={gytIcono} style={{ width: '30px' }} />
                                        </span>
                                    </div>
                                    <div className="main-content-container px-3 pl-md-0 container-fluid">
                                        <Component {...props} toggleOpen={this.state.toggleOpen} />
                                    </div>
                                    {/* <Footer /> */}
                                </main>
                            </div>
                        ) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { logOut, getMe };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBase);

export default ProtectedRoute;
