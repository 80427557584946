import React from 'react';
import { createPortal } from 'react-dom';
import { Draggable } from 'react-beautiful-dnd';

export default function Gestion({ item, index }) {
    const draggablePortal = document.getElementById('draggable-portal');
    draggablePortal.className = 'draggable-portal';

    const optionalPortal = (isDragging, element) => {
        if (isDragging) {
            return createPortal(element, draggablePortal);
        }
        return element;
    };

    return (
        <Draggable draggableId={`${item.id}`} index={index}>
            {(provided, snapshot) =>
                optionalPortal(
                    snapshot.isDragging,
                    <div
                        className={`gestion-item my-3 p-2 ${snapshot.isDragging && 'select'}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                    >
                        {item.gestion.nombre}
                    </div>
                )
            }
        </Draggable>
    );
}
