import React from 'react';
import { Form, Field } from 'react-final-form';
import { renderField, renderNumber, SelectField, AsyncSelectField } from '../../Utils/renderField/renderField';
import { Link } from 'react-router-dom';
import Cabecera from '../../Utils/Cabeceras/CabeceraSimple';
import EmpleadoIcono from '../../../../../assets/img/empleados.png';
import { TIPOS_EMPRESAS } from '../../../../utility/constants';
import { api } from 'api';

const getCentrosCosto = search => {
    let params = {};
    if (search) {
        params.search = search;
    }
    return api
        .get('centro-costos/listar_centros_costo', params)
        .then(data => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getGnt = search => {
    let params = {};
    if (search) {
        params.search = search;
    }
    return api
        .get('gnt', params)
        .then(data => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};
const validate_length = (input, values, errors, max_length) => {
    if (String(values[input]).length > max_length) {
        errors[input] = `El campo debe tener menos de ${max_length} caracteres`;
    }
};

const validate = values => {
    const errors = {};
    if (!values.id) errors.id = 'Campo requerido';
    if (!values.nombre) errors.nombre = 'Campo requerido';
    if (!values.no_certificado) errors.no_certificado = 'Campo requerido';
    if (!values.empresa) errors.empresa = 'Campo requerido';
    if (!values.cc) errors.cc = 'Campo requerido';
    if (!values.gnt) errors.gnt = 'Campo requerido';
    if (values.id) validate_length('id', values, errors, 30);
    if (values.nombre) validate_length('nombre', values, errors, 250);
    return errors;
};

const CrearEditarEmpleado = props => {
    const [empleado, setEmpleado] = React.useState(undefined);
    const { id } = props.match.params;

    React.useEffect(() => {
        if (id) props.leer(id);
    }, []);

    React.useEffect(() => {
        if (id && props.item && props.item.id) setEmpleado(props.item);
        else setEmpleado(undefined);
        // debugger;
    }, [props.item]);

    const onSubmit = data => {
        const body = { ...data };
        delete body.cc;
        body.cc = data.cc.id;
        body.gnt = data.gnt.id;
        if (id) props.editar(id, body);
        else props.crear(body);
    };
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Empleados" icono={EmpleadoIcono} />
                <br className="d-none d-md-flex" />
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    initialValues={{ ...empleado }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className="my-md-5 py-md-5">
                            <div className="d-flex flex-column col-12 col-md-5 col-lg-4 m-auto py-4">
                                <div>
                                    <label className="form-label" htmlFor="codigo">
                                        Codigo Empleado
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field
                                            name="id"
                                            type="text"
                                            disabled={!!id}
                                            component={renderField}
                                            className="flex-1"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="form-label" htmlFor="nombre">
                                        Nombre del empleado
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field name="nombre" type="text" component={renderField} className="flex-1" />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="form-label" htmlFor="no_certificado">
                                        No. de certificado
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field
                                            name="no_certificado"
                                            type="text"
                                            component={renderNumber}
                                            className="flex-1"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="form-label" htmlFor="no_certificado">
                                        Empresa
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field
                                            name="empresa"
                                            type="text"
                                            component={SelectField}
                                            options={TIPOS_EMPRESAS}
                                            className="flex-1"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="form-label" htmlFor="no_certificado">
                                        Centro de Costo
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field
                                            name="cc"
                                            type="text"
                                            component={AsyncSelectField}
                                            loadOptions={getCentrosCosto}
                                            valueKey="id"
                                            labelKey="id"
                                            labelKey2="departamento"
                                            isSearchable={true}
                                            className="flex-1"
                                            placeholder="Seleccione Centro de costos"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="form-label" htmlFor="no_certificado">
                                        Grupo Natural de Trabajo
                                    </label>
                                    <div className="d-flex flex-column">
                                        <Field
                                            name="gnt"
                                            type="text"
                                            component={AsyncSelectField}
                                            loadOptions={getGnt}
                                            valueKey="id"
                                            labelKey="id"
                                            labelKey2="nombre"
                                            isSearchable={true}
                                            className="flex-1"
                                            placeholder="Seleccione Gnt"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex mt-4 justify-content-center">
                                <button disabled={submitting} className="btn btn-danger mr-2" type="submit">
                                    Guardar
                                </button>
                                <Link to="/empleados" className="btn btn-dark ml-2">
                                    Cancelar
                                </Link>
                            </div>
                        </form>
                    )}
                </Form>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </React.Fragment>
    );
};
export default CrearEditarEmpleado;
