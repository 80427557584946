import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

export default class ReactSpinner extends React.Component {
    static propTypes = {
        // This object is passed in wholesale as the spinner config
        config: PropTypes.object,
        // This is a quick way to overwrite just the color on the config
        color: PropTypes.string.isRequired,
    };
    static defaultProps = {
        config: {},
        color: '#003DA6',
    };
    componentDidMount() {
        const { color, config } = this.props;
        const spinConfig = {
            // a few sensible defaults
            length: 38,
            width: 17,
            radius: 45,
            scale: 0.1,
            corners: 1,
            speed: 2,
            rotate: 0,
            animation: 'spinner-line-fade-more',
            direction: 1,
            fadeColor: 'transparent',
            // top: '0%',
            // left: '50%',
            shadow: '0 0 1px transparent',
            // zIndex: 2000000000,
            position: 'relative',
            // color should not overwrite config
            color,
            // config will overwrite anything else
            ...config,
        };

        this.spinner = new Spinner(spinConfig);
        this.spinner.spin(this.refs.container);
    }
    componentWillUnmount() {
        this.spinner.stop();
    }
    render() {
        return <span ref="container" />;
    }
}
