import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';

import eliminarIcon from '../../../../../assets/img/eliminar.png';
import editarIcon from '../../../../../assets/img/editar.png';

class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = id => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };

    render() {
        const { id, ver, editar, eliminar } = this.props;

        return (
            <div className="d-flex">
                {ver !== undefined && (
                    <Link to={`${ver}/${id}/`} className="px-2">
                        <i className="material-icons" title="Ver">
                            remove_red_eye
                        </i>
                    </Link>
                )}
                {editar !== undefined && (
                    <Link className="text-warning" to={`${editar}/${id}/editar`}>
                        {/* <i className="material-icons">edit</i> */}
                        <img src={editarIcon} width="24px" title="Editar" />
                    </Link>
                )}
                {eliminar !== undefined && (
                    <a className="px-2" style={{ cursor: 'pointer', color: '#c4183c' }} onClick={this.eliminar(id)}>
                        {/* <i className="material-icons">delete</i> */}
                        <img src={eliminarIcon} title="Eliminar" width="24px" />
                    </a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {};

export function standardActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} {...acciones} />;
    };
}
