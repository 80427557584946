import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/ramoContable/ramoContable';
import ListarRamosContables from './ListarRamosContables';

const ms2p = state => {
    return {
        ...state.ramoContable,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarRamosContables);
