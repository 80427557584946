import React from 'react';
import Cabecera from '../../Utils/Cabeceras/CabeceraProyecto';
import { renderField, renderDatePicker } from '../../Utils/renderField/renderField';
import { SelectField } from './../../Utils/renderField/renderField';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import './CrearEditar.css';

const CrearEditarGestiones = props => {
    const [nombre, setNombre] = React.useState(undefined);
    const [tipo, setTipo] = React.useState(undefined);

    React.useEffect(() => {
        const { id } = props.match.params;
        if (id) props.leer(id);
    }, []);

    React.useEffect(() => {
        const { id } = props.match.params;
        if (id && props.item && props.item.nombre) setNombre(props.item.nombre), setTipo(props.item.tipo);
        else setNombre(undefined);
    }, [props.item]);

    const validate = values => {
        const errors = {};
        if (!values.nombre) {
            errors.nombre = 'Campo requerido';
        } else if (!values.tipo) {
            errors.tipo = 'Campo requerido';
        }
        return errors;
    };

    const onSubmit = async data => {
        const { id } = props.match.params;
        const { crear, editar } = props;
        const body = { ...data };
        body.activo = true;

        if (id) editar(id, body);
        else await crear(body);
    };

    const tipoOptions = [
        { label: 'Soporte', value: 'soporte' },
        { label: 'Clave', value: 'clave' },
    ];

    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Gestion" />
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    initialValues={{ nombre: nombre, tipo: tipo }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <br />
                            <React.Fragment>
                                <div className="d-flex justify-content-center mt-2">
                                    <div className="col-12 col-md-6 col-lg-5 col-xl-3 justify-content-center">
                                        <label className="form-label form-custom" htmlFor="nombre">
                                            Nombre:
                                        </label>
                                        <Field name="nombre" component={renderField} autocomplete="off" />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mt-2">
                                    <div className="col-12 col-md-6 col-lg-5 col-xl-3 justify-content-center">
                                        <label className="form-label form-custom" htmlFor="nombre">
                                            Tipo:
                                        </label>
                                        <Field
                                            name="tipo"
                                            placeholder="Selecciona el tipo"
                                            options={tipoOptions}
                                            component={SelectField}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                            <br />
                            <div className="d-flex mt-4 justify-content-center">
                                <button className="btn btn-danger btn-font mr-2" type="submit">
                                    Guardar
                                </button>
                                <Link to="/gestiones" className="btn btn-dark btn-font ml-2">
                                    Cancelar
                                </Link>
                            </div>
                            <br />
                        </form>
                    )}
                </Form>
            </div>
        </React.Fragment>
    );
};

export default CrearEditarGestiones;
