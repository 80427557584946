import React, { Component } from 'react';
import { NavLink, withRouter, matchPath } from 'react-router-dom';

import './SideBar.css';
import Logo from '../../../../../assets/img/logo-gyt-sm.svg';
import LogoX from '../../../../../assets/img/0-Oficial-Rojo-Seguros-G_T.svg';
import Inicio from '../../../../../assets/img/6-Inicio.png';
import Configuracion from '../../../../../assets/img/4-Configuracion.png';
import Bitacora from '../../../../../assets/img/bitacora.png';
import Archivo from '../../../../../assets/img/archivo.png';
import Expandir from '../../../../../assets/img/23-Expandir.png';
import Carpeta from '../../../../../assets/img/carpeta.png';
import ProyectoIcono from '../../../../../assets/img/proyecto.png';
import EmpleadoIcono from '../../../../../assets/img/empleados.png';
import SubMenu from '../../Utils/SubMenu/SubMenu';
import SubMenuConfig from '../../Utils/SubMenu/SubMenuConfig';
import IconoSalir from '../../../../../assets/img/salir.png';

import Bolson from '../../../../../assets/img/bolson.png';
import ABC from '../../../../../assets/img/abc.png';
import Gestion from '../../../../../assets/img/gestiones.png';
import RamoContable from '../../../../../assets/img/ramo-contable.png';
class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SubMenu: false,
            SubMenuConfig: false,
        };
    }

    render() {
        const { toggleOpen, navToggle, closeMenu, logOut } = this.props;
        const isAtDrivers = matchPath(this.props.location.pathname, {
            path: '/drivers/:id',
            exact: true,
            strict: true,
        })
            ? true
            : false;
        const isAtConfig = matchPath(this.props.location.pathname, {
            path: '/mantenimiento',
            exact: false,
            strict: true,
        })
            ? true
            : false;

        return (
            <React.Fragment>
                <aside
                    className={`main-sidebar px-4 pb-4 d-flex flex-column justify-content-evenly align-content-evenly  ${
                        toggleOpen ? 'col-12 offset-12 sidebar-offset-col-fix offset-md-0' : 'sidebar-cerrado'
                    }`}
                >
                    <div className="sidebar-column bg-white">
                        <div className="d-flex justify-content-center">
                            <nav
                                className={`justify-content-center flex-md-nowrap border-bottom p-0 navbar navbar-light nav-gyt nav-gyt-open`}
                            >
                                <div className="d-flex flex-column align-items-center justify-content-end">
                                    <a href="#">
                                        <div className="m-auto">
                                            {toggleOpen ? (
                                                <img
                                                    id="main-logo"
                                                    className="d-inline-block align-top mt-3 accordion logo-gyt-x"
                                                    src={LogoX}
                                                    alt="Logo"
                                                />
                                            ) : (
                                                <img
                                                    className="d-inline-block align-top mt-3"
                                                    src={Logo}
                                                    width="35px"
                                                    height="35px"
                                                    alt="Logo"
                                                />
                                            )}
                                        </div>
                                    </a>
                                    <button className="btn" onClick={() => navToggle()}>
                                        <img
                                            width="15px"
                                            height="15px"
                                            src={Expandir}
                                            alt="Expandir"
                                            style={
                                                toggleOpen
                                                    ? { transform: 'rotate(0.5turn)', transition: 'all 0.2s' }
                                                    : { transition: 'all 0.2s' }
                                            }
                                        />
                                    </button>
                                </div>
                            </nav>
                        </div>
                        <div className="nav-wrapper mt-1">
                            {/* INICIO */}
                            <NavLink
                                to="/"
                                onClick={() => {
                                    closeMenu();
                                }}
                                exact
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Inicio"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={Inicio} alt="Inicio" className="icon-sidebar" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>inicio</span>
                                </div>
                            </NavLink>

                            {/* DRIVERS */}
                            <div
                                onClick={() => {
                                    this.setState({ SubMenu: !this.state.SubMenu, SubMenuConfig: false });
                                }}
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                } ${isAtDrivers && (toggleOpen ? 'active active-open' : 'active')} `}
                                data-bs-toggle="tooltip"
                                title="Drivers"
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={Archivo} alt="Drivers" className="icon-sidebar" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>Drivers</span>
                                </div>
                            </div>
                            {this.state.SubMenu && (
                                <SubMenu closeMenu={closeMenu} onClose={() => this.setState({ SubMenu: false })} />
                            )}

                            {this.state.SubMenuConfig && (
                                <SubMenuConfig
                                    toggleOpen={toggleOpen}
                                    closeMenu={closeMenu}
                                    onClose={() => this.setState({ SubMenuConfig: false })}
                                />
                            )}

                            {/* PROYECTOS */}
                            <NavLink
                                to="/proyectos"
                                onClick={() => {
                                    closeMenu();
                                }}
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Proyectos"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={ProyectoIcono} alt="Configuracion" className="icon-sidebar p-1" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>Proyectos</span>
                                </div>
                            </NavLink>

                            {/* ABC */}
                            <NavLink
                                to="/abc"
                                onClick={() => {
                                    closeMenu();
                                }}
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Catalogo ABC"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={ABC} alt="Configuracion" className="icon-sidebar p-1" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>ABC</span>
                                </div>
                            </NavLink>

                            {/* No. Bolsones */}
                            <NavLink
                                to="/bolson"
                                onClick={() => {
                                    closeMenu();
                                }}
                                className={`nav-link d-flex flex-row ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Catalogos bolson"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={Bolson} alt="Configuracion" className="icon-sidebar p-1" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>No. Bolson</span>
                                </div>
                            </NavLink>

                            {/* Gestion */}
                            <NavLink
                                to="/gestiones"
                                onClick={() => {
                                    closeMenu();
                                }}
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Gestiones"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={Gestion} alt="Configuracion" className="icon-sidebar p-1" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>Gestiones</span>
                                </div>
                            </NavLink>

                            {/* EMPLEADOS */}
                            <NavLink
                                to="/empleados"
                                onClick={() => {
                                    closeMenu();
                                }}
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Empleados"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={EmpleadoIcono} alt="Empleados" className="icon-sidebar p-1" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>Empleados</span>
                                </div>
                            </NavLink>

                            {/* RAMO CONTABLE */}
                            <NavLink
                                to="/ramos-contables"
                                onClick={() => {
                                    closeMenu();
                                }}
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Ramos contables"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={RamoContable} alt="Ramos contables" className="icon-sidebar p-1" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>Ramos Contables</span>
                                </div>
                            </NavLink>

                            {/* BITÁCORA */}
                            <NavLink
                                to="/bitacora"
                                onClick={() => {
                                    closeMenu();
                                }}
                                className={`nav-link d-flex flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Bitácora"
                                activeClassName={`active ${toggleOpen ? 'active-open' : ''}`}
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={Bitacora} alt="Bitacora" className="icon-sidebar p-1" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>Bitácora</span>
                                </div>
                            </NavLink>
                            <div
                                onClick={() => {
                                    this.props.logOut();
                                }}
                                className={`nav-link d-flex d-md-none flex-row py-2 ${
                                    toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                }`}
                                data-bs-toggle="tooltip"
                                title="Salir"
                            >
                                <div
                                    className={`d-flex flex-row align-items-center item-container ${
                                        toggleOpen ? 'justify-content-start' : 'justify-content-center'
                                    }`}
                                >
                                    <div className="d-flex align-items-center justify-content-center item-icon-wrapper mx-4">
                                        <img src={IconoSalir} alt="Salir" className="icon-sidebar" />
                                    </div>
                                    <span className={`${toggleOpen ? 'd-block' : 'd-none'}`}>Salir</span>
                                </div>
                            </div>
                            <div className="m-5" />
                        </div>
                    </div>
                </aside>
            </React.Fragment>
        );
    }
}

export default withRouter(SideBar);
