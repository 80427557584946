import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logOut } from '../../../../redux/modules/cuenta/login';
import { Link, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconoAlerta from '../../../../../assets/img/alerta-xl.png';
import { getMes } from '../../Utils/utilidades';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';

import Cancelar from '../../../../../assets/img/cancelar.png';
import Grafica from '../../../../../assets/img/1-Grafica.png';
const defaultAvatar = require('assets/img/avatar-placeholder.png');

const eliminarInforme = (informe, history, setLoader) => {
    setLoader(true);
    api.eliminar(`/informe/${informe.id}`)
        .then(res => {
            history.push(`/historial/${informe.anio}/${informe.mes}`);
        })
        .catch(err => {
            NotificationManager.error('No se pudo eliminar el informe', 'ERROR', 5000);
        })
        .finally(() => {
            setLoader(false);
        });
};

const CabeceraInicio = props => {
    const [toggle, setToggle] = React.useState(false);
    const { me, logOut, setLoader } = props;
    const onCancelar = () => {
        const mySwal = withReactContent(Swal);
        const { history, informe } = props;
        const { anio, mes } = props.match.params;
        if (informe && informe.id) {
            mySwal
                .fire({
                    title:
                        'Al aceptar perderás toda la información de este informe, debido a que se eliminaran todos los datos existentes relacionados a este.',
                    text: '¿Deseas eliminar todos los datos del informe?',
                    iconHtml: <img src={IconoAlerta} />,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    allowEnterKey: false,
                })
                .then(res => {
                    if (res.value) eliminarInforme(informe, history, setLoader);
                });
        } else if (anio && mes) {
            history.push(`/historial/${anio}/${mes}`);
        } else {
            history.push('/');
        }
    };

    return (
        <React.Fragment>
            <div className="d-flex pt-4">
                <div className="d-flex flex-4 flex-column">
                    <div className="d-flex flex-row ml-5 ml-md-0 d-">
                        <button className="btn btn-cancelar mr-2 mr-xl-3" onClick={onCancelar} type="button">
                            <img src={Cancelar} />
                            Cancelar Informe
                        </button>
                        <img className="d-none d-md-flex breadcrumb-icon mr-2 mr-xl-3" src={Grafica} alt="" />
                        <span className="cabecera-title mr-2 mr-xl-3">Informe</span>
                        <span className="d-none d-md-flex cabecera-title mr-2 mr-xl-3"> {getMes(props.titulo)} </span>

                        <span className="d-none d-md-flex cabecera-title mr-2 mr-xl-3">{props.subtitulo}</span>
                    </div>
                    <hr className="w-100 pt-0 mt-0" />
                </div>
                <div className="d-none d-md-flex justify-content-end flex-1">
                    <ul className="d-flex flex-column ml-1">
                        <Dropdown isOpen={toggle} toggle={e => setToggle(!toggle)} style={{ height: '36px' }}>
                            <DropdownToggle color="white" caret className="p-0 pb-2 border-0">
                                <img
                                    className="rounded-circle mr-3 icon"
                                    src={me && me.avatar ? me.avatar : defaultAvatar}
                                    alt="User Avatar"
                                />
                                <span className="d-none d-md-inline-block">{me.nombres}</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <div onClick={logOut}>
                                        <i className="material-icons text-danger"></i>
                                        <span className="d-none d-md-inline d-lg-inline">Salir</span>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <hr className="w-100 p-0 m-0" />
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

const ms2p = state => {
    return {
        ...state.login,
    };
};

const md2p = { logOut };

export default connect(ms2p, md2p)(withRouter(CabeceraInicio));
