import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';

import inicio from './modules/inicio/inicio';
import bitacora from './modules/bitacora/bitacora';
import canalComercial from './modules/mantenimiento/canalComercial';
import ramoComercial from './modules/mantenimiento/ramoComercial';
import unidadComercial from './modules/mantenimiento/unidadComercial';
import informe from './modules/informe/informe';
import proyecto from './modules/proyecto/proyecto';
import bolson from './modules/Bolson/bolson';
import abc from './modules/abc/abc';
import distribucion from './modules/distribucion/distribucion';
import gestion from './modules/gestion/gestion';
import empleado from './modules/empleado/empleado';
import ramoContable from './modules/ramoContable/ramoContable';

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    usuarios,
    routing,
    notificaciones,
    inicio,
    bitacora,
    canalComercial,
    ramoComercial,
    unidadComercial,
    informe,
    proyecto,
    bolson,
    abc,
    distribucion,
    gestion,
    empleado,
    ramoContable,
});
