import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { NotificationManager } from 'react-notifications';

import OkIcon from '../../../../../../../assets/img/circulo-check.png';
import { RenderCurrency, RenderNumber } from '../../../../Utils/renderField/renderReadField.js';
import { api } from 'api';

const dataSeed = {
    results: [],
    count: 0,
};

const PasoFinal = props => {
    const [data, setData] = React.useState(dataSeed);
    const [page, setPage] = React.useState(1);
    const [totales, setTotales] = React.useState(undefined);
    const { setLoader } = props;

    React.useEffect(() => {
        listar();
    }, [page]);

    React.useEffect(() => {
        api.get('seguro-empleados/totales', { informe: props.informe.id })
            .then(res => {
                setTotales(res);
            })
            .catch(error => {
                let mensajeError = 'Error al obtener totales de seguros y gastos medicos por empleados';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    }, []);

    const listar = () => {
        setLoader(true);
        api.get('seguro-empleados', { informe: props.informe.id, page })
            .then(res => {
                setData(res);
            })
            .catch(error => {
                let mensajeError = 'Error al obtener los seguros y gastos medicos por empleados';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <React.Fragment>
            <br />
            {totales && (
                <div className="d-flex flex-1 flex-column flex-md-row">
                    <div className="flex-1">
                        <div className="d-flex flex-row">
                            <p className="m-0 pb-1 text-dark font-weight-bolder pr-2">Total en base empresa 1: </p>
                            <RenderCurrency
                                className="remove-style"
                                decimalScale={2}
                                value={totales.total_base_emp_1}
                            />
                        </div>
                        <div className="d-flex flex-row">
                            <p className="m-0 pb-1 text-dark font-weight-bolder pr-2">Total en base empresa 2: </p>
                            <RenderCurrency
                                className="remove-style"
                                decimalScale={2}
                                value={totales.total_base_emp_2}
                            />
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="d-flex flex-row">
                            <p className="m-0 pb-1 text-dark font-weight-bolder pr-2">Total en seguros empresa 1: </p>
                            <RenderCurrency
                                className="remove-style"
                                decimalScale={2}
                                value={totales.total_driver_emp_1}
                            />
                        </div>
                        <div className="d-flex flex-row">
                            <p className="m-0 pb-1 text-dark font-weight-bolder pr-2">Total en seguros empresa 2: </p>
                            <RenderCurrency
                                className="remove-style"
                                decimalScale={2}
                                value={totales.total_driver_emp_2}
                            />
                        </div>
                    </div>
                </div>
            )}
            <br />
            <div className="custom-scroll">
                <Grid data={data} onPageChange={setPage} sizePerPage={20} page={page} scrollTable>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width="5rem"
                        dataFormat={data => {
                            return <img className="column-data-icon" src={OkIcon} />;
                        }}
                    >
                        ESTADO
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="certificado">CERTIFICADO</TableHeaderColumn>
                    <TableHeaderColumn dataField="codigo_empleado">CÓDIGO EMPLEADO</TableHeaderColumn>
                    <TableHeaderColumn dataField="nombre_empleado" width="25rem">
                        NOMBRE EMPLEADO
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="empresa">EMPRESA</TableHeaderColumn>
                    <TableHeaderColumn dataField="centro_costo">CENTRO COSTO</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="prima_vida"
                        dataAlign="right"
                        dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                    >
                        PRIMA VIDA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="prima_gm"
                        dataAlign="right"
                        dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                    >
                        PRIMA GM
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="retroactivo_vida"
                        dataAlign="right"
                        dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                    >
                        RETROACTIVO VIDA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="retroactivo_gm"
                        dataAlign="right"
                        dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                    >
                        RETROACTIVO GM
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="porcentaje"
                        dataAlign="right"
                        dataFormat={value => (
                            <RenderNumber className="remove-style" suffix={' %'} decimalScale={2} value={value} />
                        )}
                    >
                        PORCENTAJE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total_certificado"
                        dataAlign="right"
                        dataFormat={value => <RenderCurrency className="remove-style" value={value} />}
                    >
                        TOTAL
                    </TableHeaderColumn>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default PasoFinal;
