import React from 'react';
import Table from '../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../Utils/Grid/StandardActions';
import Cabecera from '../../Utils/Cabeceras/CabeceraSimple';
import { Link } from 'react-router-dom';
import RamoContableIcono from '../../../../../assets/img/ramo-contable.png';

const ListarRamosContables = props => {
    React.useEffect(() => {
        props.searchChange('');
        props.listar();
    }, []);

    const alBuscar = value => {
        props.searchChange(value);
    };
    let timeout = null;
    const alTerminarEscritura = e => {
        clearTimeout(timeout);
        const value = e.target.value;
        timeout = setTimeout(() => {
            alBuscar(value);
            s;
        }, 500);
    };

    const { data, page, loader, listar: onPageChange, eliminar, editar } = props;
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Ramo contable" icono={RamoContableIcono} />
                <div className="d-flex flex-column ml-3">
                    <div className="d-flex flex-md-row flex-wrap  justify-content-between mt-3 mb-3">
                        <Link to="/ramo-contable" className="btn btn-danger">
                            Nuevo
                        </Link>
                        <input
                            id="buscar-ramos"
                            onKeyUp={alTerminarEscritura}
                            className="form-control col-12 mt-3 mt-md-0 col-md-4 "
                            placeholder="Buscar por codigo producto, nombre ramo..."
                        ></input>
                    </div>

                    <Table hover striped data={data} page={page} loading={loader} onPageChange={onPageChange}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="left"
                            isKey
                            width="5rem"
                            dataFormat={standardActions({ eliminar, editar: '/ramo-contable' })}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn width="7rem" dataField="codigo_producto" dataAlign="left">
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn width="15rem" dataField="nombre" dataAlign="left">
                            RAMO CONTABLE
                        </TableHeaderColumn>
                    </Table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListarRamosContables;
