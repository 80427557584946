import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import moment from 'moment';

const SET_MONTHS = 'SET_MONTHS';
const SET_HISTORICAL = 'SET_HISTORICAL';
const SET_REPORT = 'SET_REPORT';
const SET_LOADER = 'SET_LOADER_HISTORICAL';
const SET_HISTORICAL_PAGE = 'SET_HISTORIAL_PAGE';

export const getMonths = year => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.get('dashboard', { anio: year })
        .then(response => {
            dispatch({
                type: SET_MONTHS,
                data: response,
            });
        })
        .catch(error => {
            let mensajeError = 'No se pudo cargar el dashboard';
            if (error.detail) mensajeError = error.detail;
            NotificationManager.error(mensajeError, 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const getHistorical = (anio, mes, page) => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    const params = {};
    if (anio) params.anio = anio;
    if (mes) params.mes = mes;
    if (page) params.page = page;
    return api
        .get('informe', params)
        .then(response => {
            if (response && response.results) {
                dispatch({
                    type: SET_HISTORICAL,
                    historical: response,
                });
                dispatch({
                    type: SET_HISTORICAL_PAGE,
                    page: page,
                });
            }
        })
        .catch(error => {
            let mensajeError = 'No se pudo obtener el historial';
            if (error.detail) mensajeError = error.detail;
            NotificationManager.error(mensajeError, 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const actions = {
    getMonths,
    getHistorical,
};

export const reducers = {
    [SET_MONTHS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_HISTORICAL]: (state, { historical }) => {
        return {
            ...state,
            historical,
        };
    },
    [SET_REPORT]: (state, { report }) => {
        return {
            ...state,
            report,
        };
    },
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_HISTORICAL_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    page: 1,
    loader: false,
    data: [],
    report: {},
    historical: {
        results: [],
        count: 0,
    },
};

export default handleActions(reducers, initialState);
