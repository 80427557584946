import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/gestion/gestion';
import ListarGestion from './ListarGestion';

const ms2p = state => {
    return {
        ...state.gestion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarGestion);
