import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { api } from 'api';

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const getMe = () => dispatch => {
    api.get('/user/me')
        .then(me => {
            dispatch(setMe(me));
        })
        .catch(() => {
            dispatch(push('/login'));
        })
        .finally(() => {});
};

export const logOut = () => dispatch => {
    api.get('/user/logout')
        .then(me => {
            dispatch(setMe({}));
            dispatch(push('/login'));
        })
        .catch(() => {
            dispatch(push('/login'));
        })
        .finally(() => {});
};

export const actions = {
    logOut,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
