import React from 'react';
import IconoExito from '../../../../../../../assets/img/exito.png';

const DistribucionFinalizada = props => {
    const onAceptar = () => {
        const { id } = props.match.params;
        props.history.push(`/informe/${id}/detalle`);
    };
    return (
        <React.Fragment>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br className="d-none d-md-flex" />
            <br className="d-none d-md-flex" />
            <br className="d-none d-md-flex" />
            <div className="d-flex flex-1 flex-column">
                <div className="d-flex m-auto">
                    <img className="success-icon" src={IconoExito} />
                </div>
                <div className="d-flex m-auto pt-4">
                    <span className="success-text text-center">
                        Felicidades ha completado la distribucion de todos los costos
                    </span>
                </div>
                <div className="d-flex m-auto pt-4">
                    <button className="btn btn-danger ml-2" onClick={onAceptar} type="button">
                        Aceptar
                    </button>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </React.Fragment>
    );
};

export default DistribucionFinalizada;
