import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';

export const RenderNumber = ({ value, decimalScale, prefix, suffix, className }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 0}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix={prefix || ''}
            suffix={suffix || ''}
            displayType="text"
        />
    );
};

export const RenderCurrency = ({ value, className, prefix }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={2}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix={prefix || 'Q '}
            displayType="text"
        />
    );
};

export const RenderDateTime = ({ value, className }) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>
                {fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}
            </span>
        );
    }
    return <span className={className}>{value}</span>;
};

export const RenderDateTimeAll = ({ value, className }) => {
    if (value) {
        const fecha = new Date(value);

        let date = moment(value).tz('America/Guatemala').format('DD/MM/YYYY HH:mm:ss');
        return <span className={className}>{date}</span>;
    }
    return <span className={className}>{value}</span>;
};

export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
};
