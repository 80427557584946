import React, { useRef } from 'react';
import Cabecera from '../../Utils/Cabeceras/CabeceraProyecto';
import Select from 'react-select';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { renderField } from '../../Utils/renderField/renderField';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import './CrearEditar.css';
import eliminarIcon from '../../../../../assets/img/eliminar.png';
import Grid from '../../Utils/Grid';

const CrearEditarBolson = props => {
    const { loader, eliminar } = props;
    const [referencias, setReferencias] = React.useState([]);
    const [referencia, setReferencia] = React.useState([]);
    const [selectedRef, setselectedRef] = React.useState(null);

    const [nombre, setNombre] = React.useState(undefined);

    const selectInputRef = useRef();

    React.useEffect(() => {
        props.listar_referencias().then(data => {
            setReferencias(data);
        });
        const { id } = props.match.params;
        if (id) props.leer(id);
    }, []);

    React.useEffect(() => {
        const { id } = props.match.params;
        if (id && props.item && props.item.nombre) setNombre(props.item.nombre), setReferencia(props.item.referencias);
        else setNombre(undefined), setReferencia(undefined);
    }, [props.item]);

    const validate = values => {
        const errors = {};
        if (!values.nombre) {
            errors.nombre = 'Campo requerido';
        }
        return errors;
    };

    const onSubmit = async data => {
        const { id } = props.match.params;
        const { crear, editar } = props;
        const body = { ...data };
        body.activo = true;
        body.referencia = referencia;
        if (id) editar(id, body);
        else await crear(body);
    };

    const AddData = async data => {
        let referencias_completas = [...referencias];
        referencias_completas = referencias_completas.filter(function (it) {
            return it.referencia != data;
        });
        setReferencias(referencias_completas);
        let ref = [];
        if (referencia) ref = [...referencia];
        ref.push({ referencia: data });
        setReferencia(ref);
        selectInputRef.current.select.clearValue();
    };

    const Eliminar = async data => {
        let arr = [...referencia];
        let referencias_completas = [...referencias];

        arr = arr.filter(function (it) {
            return it.referencia != data;
        });

        referencias_completas.unshift({ referencia: data });
        setReferencias(referencias_completas);
        setReferencia(arr);
    };

    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Catalogo Bolson" />
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    initialValues={{ nombre: nombre }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <br />
                                    <React.Fragment>
                                        <div className="d-flex justify-content-center mt-2">
                                            <div className="col-12 col-md-6 col-lg-5 col-xl-3 justify-content-center">
                                                <label className="form-label form-custom" htmlFor="nombre">
                                                    Bolson:
                                                </label>
                                                <Field
                                                    name="nombre"
                                                    className="form-control p-0 w-20 col-12 border-trasparent "
                                                    component={renderField}
                                                    autocomplete="off"
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    <br />
                                    <React.Fragment>
                                        <div className="d-flex justify-content-center mt-2">
                                            <div className="col-12 col-md-6 col-lg-5 col-xl-3 justify-content-center">
                                                <label className="form-label form-custom" htmlFor="nombre">
                                                    Referencia:
                                                </label>
                                                <Select
                                                    ref={selectInputRef}
                                                    isClearable={true}
                                                    className="form-control p-0 w-20 col-12 border-trasparent "
                                                    autoFocus
                                                    isSearchable={true}
                                                    onChange={e => {
                                                        setselectedRef(e && e.referencia ? e.referencia : null);
                                                    }}
                                                    classNamePrefix="react-select"
                                                    getOptionValue={option => option['referencia']}
                                                    getOptionLabel={option => option['referencia']}
                                                    placeholder="Seleccionar"
                                                    cache={false}
                                                    options={referencias}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            <button
                                                className="btn btn-secondary btn-font mr-2 "
                                                disabled={!selectedRef}
                                                type="button"
                                                onClick={() => {
                                                    AddData(selectedRef);
                                                }}
                                            >
                                                Agregar
                                            </button>
                                        </div>
                                    </React.Fragment>
                                    <br />
                                    <br className="d-none d-md-flex" />
                                    <div className="d-flex m-auto col-12 col-lg-8 col-md-10">
                                        <LoadMask loading={loader} blur>
                                            <Grid data={{ results: referencia }} pagination={false}>
                                                <TableHeaderColumn width="10rem" dataField="referencia" isKey>
                                                    REFERENCIA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="referencia"
                                                    dataAlign="left"
                                                    width="4rem"
                                                    dataFormat={data => {
                                                        return (
                                                            <a
                                                                className="px-2"
                                                                style={{ cursor: 'pointer', color: '#c4183c' }}
                                                                onClick={() => {
                                                                    Eliminar(data);
                                                                }}
                                                            >
                                                                <img src={eliminarIcon} width="24px" />
                                                            </a>
                                                        );
                                                    }}
                                                >
                                                    ACCIONES
                                                </TableHeaderColumn>
                                            </Grid>
                                        </LoadMask>
                                    </div>
                                    <br />
                                    <div className="d-flex mt-4 justify-content-center">
                                        <button className="btn btn-danger btn-font mr-2" type="submit">
                                            Guardar
                                        </button>
                                        <Link to="/bolson" className="btn btn-dark btn-font ml-2">
                                            Cancelar
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        );
                    }}
                </Form>
            </div>
        </React.Fragment>
    );
};

export default CrearEditarBolson;
