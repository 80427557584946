import React from 'react';

// ESTADOS CELERY
const PENDING = 'PENDING';
const RECEIVED = 'RECEIVED';
const STARTED = 'STARTED';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

export default function ExpandComponent(row) {
    if (row.result && row.result.length > 0) {
        if (row.estado === SUCCESS)
            return (
                <div className="alert-warning">
                    <div className="margin-results">
                        <ul>
                            {row.result.map(function (item) {
                                return <li key={item}>{item}</li>;
                            })}
                        </ul>
                    </div>
                </div>
            );
        if (row.estado === FAILURE)
            return (
                <div className="alert-error">
                    <div className="margin-results">
                        <div className="d-flex p-3">
                            <ul>
                                {row.result.map(function (item) {
                                    return <li key={item}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            );
    } else if (row.result && row.result.exc_message) {
        return (
            <div className="alert-error">
                <div className="margin-results">
                    <div className="d-flex p-3">
                        <ul>
                            <li>Error interno al intentar ejecutar la tarea.</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}
