import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';

const SET_LOADER = 'SET_LOADER_GESTION';
const DATA = 'SET_DATA_GESTION';
const ITEM = 'SET_ITEM_GESTION';
const SET_PAGE = 'SET_PAGE_GESTION';

const setItem = item => ({
    type: ITEM,
    item,
});

const setData = data => ({
    type: DATA,
    data,
});

export const crear = data => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.post('gestiones', data)
        .then(response => {
            dispatch(push('/gestiones'));
            NotificationManager.success('Registro creado exitosamente.', 'Exito', 5000);
            dispatch(listar());
        })
        .catch(error => {
            NotificationManager.error('Error, no se pudo guardar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: SET_LOADER, loader: true });
    const params = { page };

    api.get('gestiones', params)
        .then(response => {
            if (response) {
                dispatch(setData(response));
                dispatch({
                    type: SET_PAGE,
                    page: page,
                });
            }
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registros.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const leer = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.get(`${'gestiones'}/${id}`)
        .then(response => {
            dispatch(setItem(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const editar = (id, data) => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.put(`${'gestiones'}/${id}`, data)
        .then(() => {
            NotificationManager.success('Registro actualizado.', 'Exito', 5000);
            dispatch(push('/gestiones'));
        })
        .catch(() => {
            NotificationManager.error('Error, no se pudo actualizar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const eliminar = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.eliminar(`${'gestiones'}/${id}`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success('Registro eliminado.', 'Exito', 5000);
        })
        .catch(() => {
            NotificationManager.error('Error, no se pudo eliminar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};
export const removerItem = () => dispatch => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const actions = {
    crear,
    listar,
    editar,
    leer,
    eliminar,
    removerItem,
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    page: 1,
    item: {},
    buscador: '',
    filtro: '',
};

export default handleActions(reducers, initialState);
