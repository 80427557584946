import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
const defaultAvatar = require('assets/img/avatar-placeholder.png');
import './Navbar.css';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };
    render() {
        const { navToggle, logOut, user } = this.props;
        return (
            <div className="d-flex align-items-stretch flex-md-nowrap p-0 ml-5 navbar navbar-light navbar-gyt">
                <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                    <div className="ml-3 input-group input-group-seamless" />
                </div>
                <nav className="d-flex align-items-stretch flex-md-nowrap p-0 ml-5 navbar navbar-light py-3">
                    <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                        <div className="ml-3 input-group input-group-seamless" />
                    </div>
                    <ul className="d-flex flex-row navbar-nav my-0 py-0 ml-1">
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle color="white" caret className="nav-item-dropdown border-0">
                                <img
                                    className="rounded-circle mr-3 icon"
                                    src={user.profile && user.profile.avatar ? user.profile.avatar : defaultAvatar}
                                    alt="User Avatar"
                                />
                                {/* <span className="d-none d-md-inline-block">{user.first_name}</span> */}
                                <span className="d-none d-md-inline-block">Carlos</span>
                            </DropdownToggle>
                            <hr className="w-100 p-0 m-0 d-none d-sm-none d-md-block d-lg-block" />
                            <DropdownMenu>
                                <DropdownItem>
                                    {/* <a className="text-danger" onClick={logOut} href="/">
                                        <i className="material-icons text-danger"></i>
                                        <span className="d-none d-md-inline d-lg-inline">Salir</span>
                                    </a> */}
                                    <Link to="/login" className="text-danger">
                                        <i className="material-icons text-danger"></i>
                                        <span className="d-none d-md-inline d-lg-inline">Salir</span>
                                    </Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </ul>
                    <hr className="w-100 p-0 m-0" />
                    {/* <nav className="nav">
                        <a  className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                            onClick={ navToggle } >
                            <i className="material-icons"></i>
                        </a> */}
                </nav>
            </div>
        );
    }
}

export default Navbar;
