import { push } from 'react-router-redux';
import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';

// ------------------------------------
// Constants
// ------------------------------------

const constants = {
    LOADER: `PROYECTO_LOADER`,
    DATA: `PROYECTO_DATA`,
    ITEM: `PROYECTO_ITEM`,
    PAGE: `PROYECTO_PAGE`,
    ORDERING: `PROYECTO_ORDERING`,
    SEARCH: `PROYECTO_SEARCH`,
};

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});

const setData = data => ({
    type: constants.DATA,
    data,
});

const setItem = item => ({
    type: constants.ITEM,
    item,
});

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

// -----------------------------------
// Actions
// -----------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyecto;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('proyecto', params)
        .then(response => {
            dispatch(setData(response));
            dispatch(setPage(page));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = id => dispatch => {
    dispatch(setLoader(true));
    api.get(`${'proyecto'}/${id}`)
        .then(response => {
            dispatch(setItem(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const crear = data => dispatch => {
    dispatch(setLoader(true));
    api.post('proyecto', data)
        .then(() => {
            NotificationManager.success('Registro creado.', 'Éxito', 5000);
            dispatch(push('/proyectos'));
        })
        .catch(() => {
            NotificationManager.error('Error, no se pudo guardar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const editar = (id, data) => dispatch => {
    dispatch(setLoader(true));
    api.put(`${'proyecto'}/${id}`, data)
        .then(() => {
            NotificationManager.success('Registro actualizado.', 'Éxito', 5000);
            dispatch(push('/proyectos'));
        })
        .catch(() => {
            NotificationManager.error('Error, no se pudo actualizar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminar = id => dispatch => {
    dispatch(setLoader(true));
    api.eliminar(`${'proyecto'}/${id}`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success('Registro eliminado.', 'Éxito', 5000);
        })
        .catch(() => {
            NotificationManager.error('Error, no se pudo eliminar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = search => dispatch => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
