import React from 'react';
import Grid from '../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../Utils/Grid/StandardActions';

import Cabecera from '../../Utils/Cabeceras/CabeceraProyecto';
import { Link } from 'react-router-dom';

import CaretAbajo from '../../../../../assets/img/Caret-abajo.png';
import TablaReferencias from './TablaReferencias';

const expandComponent = row => {
    return <TablaReferencias data={row.referencias} />;
};
const expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
    if (isExpandableRow) {
        if (isExpanded) {
            return (
                <div className="d-flex flex-1">
                    <img
                        src={CaretAbajo}
                        className="m-0 p-0"
                        style={{ transform: 'rotate(0.5turn)' }}
                        width="24px"
                        alt="Expanded"
                    />
                </div>
            );
        } else {
            return <img src={CaretAbajo} className="m-0 p-0" width="24px" alt="Expanded" />;
        }
    } else return <div> </div>;
};

const ListarBolson = props => {
    React.useEffect(() => {
        props.listar();
    }, []);

    const { data, loader, eliminar, page, listar: onPageChange } = props;

    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Catalogo Bolson" />
                <br />
                <div className="d-flex flex-column ml-3">
                    <div className="d-flex flex-row  mt-3 mb-3">
                        <Link to="/bolson/nuevo" className="btn btn-danger mr-2">
                            Nuevo
                        </Link>
                    </div>
                    <Grid
                        expandableRow={() => {
                            return true;
                        }}
                        expandComponent={expandComponent}
                        expandColumnOptions={{
                            expandColumnVisible: true,
                            expandColumnComponent: expandColumnComponent,
                            columnWidth: 45,
                        }}
                        hover
                        striped
                        data={data}
                        page={page}
                        sizePerPage={10}
                        loading={loader}
                        onPageChange={onPageChange}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="left"
                            width="5rem"
                            isKey
                            dataFormat={
                                (
                                    <Link to="/bolson/nuevo" className="btn btn-danger">
                                        Nuevo
                                    </Link>
                                ) && standardActions({ eliminar, editar: '/bolson' })
                            }
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn dataAlign="left" width="15rem" dataField="nombre">
                            DESCRIPCION
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListarBolson;
