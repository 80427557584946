import React from 'react';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField';
import { api } from 'api';
import { Table } from 'reactstrap';

const CentrosCosto = props => {
    const [centrosCosto, setCentrosCosto] = React.useState([]);
    React.useEffect(() => {
        api.get(`dist-actividades/detalle_actividad`, { ...props.data }).then(response => {
            if (response && response.centros_costo) {
                setCentrosCosto(response.centros_costo);
            }
        });
    }, []);

    return (
        <React.Fragment>
            <br />
            <Table className="custom-table-projects">
                <thead>
                    <tr>
                        <th width="20%">CENTRO COSTO</th>
                        <th width="30%">DEPARTAMENTO</th>
                        <th width="30%">CENTRO COSTO</th>
                        <th width="20%" />
                    </tr>
                </thead>
                <tbody>
                    {centrosCosto.map(entry => {
                        return (
                            <tr key={entry.centro_costo}>
                                <td width="30%">
                                    <span className="pl-2 remove-style">{entry.centro_costo}</span>
                                </td>
                                <td width="30%">
                                    <span className="pl-2 remove-style">{entry.departamento}</span>
                                </td>
                                <td width="50%" align="right">
                                    <RenderCurrency className="remove-style" value={entry.monto} />
                                </td>
                                <td width="20%" />
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <br />
        </React.Fragment>
    );
};

export default CentrosCosto;
