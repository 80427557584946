import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logOut } from '../../../../redux/modules/cuenta/login';
import { getMes } from '../../Utils/utilidades';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Grafica from '../../../../../assets/img/1-Grafica.png';
const defaultAvatar = require('assets/img/avatar-placeholder.png');
const CabeceraInicio = props => {
    const [toggle, setToggle] = React.useState(false);
    const [toggleMes, setToggleMes] = React.useState(false);
    const [toggleAnio, setToggleAnio] = React.useState(false);
    const [anios, setAnios] = React.useState([]);
    const [meses, setMeses] = React.useState([
        { value: 1, label: 'Enero' },
        { value: 2, label: 'Febrero' },
        { value: 3, label: 'Marzo' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Mayo' },
        { value: 6, label: 'Junio' },
        { value: 7, label: 'Julio' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Septiembre' },
        { value: 10, label: 'Octubre' },
        { value: 11, label: 'Noviembre' },
        { value: 12, label: 'Diciembre' },
    ]);
    const { me, logOut, history } = props;

    React.useEffect(() => {
        let ultimo = parseInt(props.anio);
        let anios = [];
        for (let i = ultimo - 10; i <= ultimo + 5; i++) {
            anios.push(i);
        }
        setAnios(anios);
    }, []);

    return (
        <React.Fragment>
            <div className="d-flex pt-4">
                <div className="d-flex flex-4 flex-column ">
                    <div className="d-flex justify-content-start ml-5 ml-md-0">
                        <span
                            onClick={() => history.back()}
                            className="d-none d-lg-flex material-icons icono-navegacion mr-3"
                        >
                            navigate_before
                        </span>
                        <img className="d-none d-md-flex breadcrumb-icon mr-3" src={Grafica} alt="" />
                        <h5 className="cabecera-title mr-1">Informe</h5>
                        <Dropdown
                            isOpen={toggleAnio}
                            toggle={e => setToggleAnio(!toggleAnio)}
                            className="breadcrumb-dropdown"
                        >
                            <DropdownToggle
                                color="white"
                                caret
                                className="nav-item-dropdown border-0 breadcrumb-dropdown-toggle p-0 mr-2"
                            >
                                <h5 className="cabecera-title">{props.anio}</h5>
                            </DropdownToggle>
                            <DropdownMenu className="breadcrumb-dropdown-menu mr-3">
                                {anios &&
                                    anios.map((dropDownYear, index) => {
                                        return (
                                            <DropdownItem onClick={props.onChangeAnio} value={dropDownYear} key={index}>
                                                {dropDownYear}
                                            </DropdownItem>
                                        );
                                    })}
                            </DropdownMenu>
                        </Dropdown>
                        <span className="d-none d-lg-flex material-icons icono-navegacion mr-3">navigate_next</span>
                        {props.mes && (
                            <React.Fragment>
                                <Dropdown
                                    isOpen={toggleMes}
                                    toggle={e => setToggleMes(!toggleMes)}
                                    className="d-none d-lg-flex breadcrumb-dropdown"
                                >
                                    <DropdownToggle
                                        color="white"
                                        caret
                                        className="nav-item-dropdown border-0 breadcrumb-dropdown-toggle p-0 mr-2"
                                    >
                                        <h5 className="cabecera-title">{getMes(props.mes)}</h5>
                                    </DropdownToggle>
                                    <DropdownMenu className="breadcrumb-dropdown-menu">
                                        {meses &&
                                            meses.map((dropDownMonth, index) => {
                                                return (
                                                    <DropdownItem
                                                        onClick={props.onChangeMes}
                                                        key={index}
                                                        value={dropDownMonth.value}
                                                    >
                                                        {dropDownMonth.label}
                                                    </DropdownItem>
                                                );
                                            })}
                                    </DropdownMenu>
                                </Dropdown>
                                <span className="d-none d-lg-flex material-icons icono-navegacion ">navigate_next</span>
                            </React.Fragment>
                        )}
                    </div>
                    <hr className="w-100 pt-0 mt-0" />
                </div>
                <div className="d-none d-md-flex justify-content-end flex-1">
                    <ul className="d-flex flex-column ml-1">
                        <Dropdown isOpen={toggle} toggle={e => setToggle(!toggle)} style={{ height: '36px' }}>
                            <DropdownToggle color="white" caret className="p-0 pb-2 border-0">
                                <img
                                    className="rounded-circle mr-3 icon"
                                    src={me && me.avatar ? me.avatar : defaultAvatar}
                                    alt="User Avatar"
                                />
                                <span className="d-none d-md-inline-block">{me.nombres}</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <div onClick={logOut}>
                                        <i className="material-icons text-danger"></i>
                                        <span className="d-none d-md-inline d-lg-inline">Salir</span>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <hr className="w-100 p-0 m-0" />
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

const ms2p = state => {
    return {
        ...state.login,
    };
};

const md2p = { logOut };

export default connect(ms2p, md2p)(withRouter(CabeceraInicio));
