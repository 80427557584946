import React from 'react';
import { RenderNumber } from '../../../../Utils/renderField/renderReadField.js';
import NumberFormat from 'react-number-format';

const TablaCuentas = props => {
    let { data, setData, data_original, cuenta, total_cuenta } = props;
    let porcentaje_total = 0;

    let total = data.reduce(function (prev, cur) {
        let porcentaje = parseFloat(cur.porcentaje);
        if (!Number.isNaN(porcentaje)) {
            return prev + porcentaje;
        } else {
            return prev;
        }
    }, 0);

    total = total ? total.toFixed(2) : total;
    const editCell = (value, item) => {
        let cuenta_madre = item.cuenta_madre.toString();
        let originales = [...data_original.results];
        let porcentaje = 0;
        let monto_asignado = 0;

        originales = originales.map(item2 => {
            if (item.gestion_soporte == item2.gestion_soporte) {
                //monto_asignado = parseFloat(item2.monto);
                item2.cuentas.map(item3 => {
                    if (item3[cuenta_madre]) {
                        item3[cuenta_madre].map(item4 => {
                            if (item4.id == item.id) {
                                item.porcentaje = value.floatValue || '';
                            }
                            let percent = parseFloat(item4.porcentaje);
                            if (!Number.isNaN(percent)) {
                                porcentaje = porcentaje + percent;
                            }
                            return item4;
                        });
                    }
                    return item3;
                });
            }
            return item2;
        });

        /*VALIDACION TOTAL*/
        setData(s => ({ ...s, results: originales }));
    };

    return (
        <React.Fragment>
            <div>
                <div className="d-flex bd-highlight container-text-cta justify-content-between">
                    <div className="p-2 text-cta"> {cuenta} </div>
                    <div className="ml-auto p-2 text-cta">
                        Monto de cuenta:{' '}
                        <RenderNumber className="remove-style pl-1" value={total_cuenta} decimalScale={2} />
                    </div>
                    <div className="ml-auto p-2 bd-highlight">
                        {total == 100 && (
                            <div className="text-percent">
                                Distribucion{' '}
                                <RenderNumber className="text-percent" suffix={'%'} value={total} decimalScale={2} />
                                &nbsp; de 100.00%{' '}
                            </div>
                        )}
                        {total != 100 && (
                            <div className="text-percent-error">
                                Distribucion{' '}
                                <RenderNumber
                                    className="text-percent-error m-0"
                                    suffix={'%'}
                                    value={total}
                                    decimalScale={2}
                                />{' '}
                                de 100.00%
                            </div>
                        )}
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Porcentaje</th>
                            <th scope="col">Monto sin soporte</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, i) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.nombre}</td>
                                    <td>
                                        <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale
                                            value={item.porcentaje}
                                            thousandSeparator
                                            suffix={' %'}
                                            onValueChange={value => editCell(value, item)}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            className="remove-style"
                                            decimalScale={2}
                                            fixedDecimalScale
                                            value={item.monto}
                                            thousandSeparator
                                            displayType="text"
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default TablaCuentas;
