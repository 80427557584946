export const INFORME_OFICIAL = 1;
export const INFORME_PROYECCION = 2;

export const CANAL_COMERCIAL = 10;
export const RAMO_COMERCIAL = 20;
export const UNIDAD_COMERCIAL = 30;

export const CENTROS_COSTO = 10;
export const DRIVER_20_21_22 = 20;
export const DRIVER_12 = 30;
export const METROS_CUADRADOS = 40;
export const NUEVOS_O_MODIFICADOS_PRODUCTOS = 50;
export const SEGURO_EMPLEADO = 60;
export const PESO_SALARIAL = 70;
export const DRIVER_24_QUETZALES = 80;
export const DRIVER_24_DOLARES = 90;
export const DRIVER_CAPACITACIONES = 100;
export const ENCUESTA_ACTIVIDADES = 110;
export const DRIVER_16 = 120;
export const DRIVER_17 = 130;
export const DRIVER_26_27 = 140;

export const INFORME_INICIADO = 1;
export const INFORME_CARGA_COMPLETA = 2;
export const INFORME_FINALIZADO = 3;

export const EMPRESA_SEGUROS = 1;
export const EMPRESA_FINANZAS = 2;
export const TIPOS_EMPRESAS = [
    { value: EMPRESA_SEGUROS, label: 'Empresa Seguros' },
    { value: EMPRESA_FINANZAS, label: 'Empresa Fianzas' },
];

export const TIPOS_ARCHIVOS = [
    { value: CENTROS_COSTO, label: 'Arbol Centros de Costo' },
    { value: METROS_CUADRADOS, label: 'Metros Cuadrados' },
    { value: NUEVOS_O_MODIFICADOS_PRODUCTOS, label: 'Productos Nuevos o Modificados' },
    { value: DRIVER_20_21_22, label: 'Archivo del los drivers 20, 21, 22' },
    { value: DRIVER_12, label: 'Archivo del drivers 12' },
    { value: SEGURO_EMPLEADO, label: 'Seguro de empleados y gastos medicos' },
    { value: PESO_SALARIAL, label: 'Peso Salarial' },
    { value: ENCUESTA_ACTIVIDADES, label: 'Encuesta de actividades' },
    { value: DRIVER_24_QUETZALES, label: 'Archivo del driver 24 - dolares' },
    { value: DRIVER_24_DOLARES, label: 'Archivo del driver 24 - quetzales' },
    { value: DRIVER_CAPACITACIONES, label: 'Capacitaciones' },
    { value: DRIVER_16, label: 'Cantidad de salvamentos' },
    { value: DRIVER_17, label: 'Cantidad de recobro' },
    { value: DRIVER_26_27, label: 'Cantidad de servicios atendidos autos y gm' },
];

export const MESES = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
];

export const DRIVER_MANUALES = [
    { id: 'CENTRO_COSTO', nombre: 'ÁRBOL CENTROS DE COSTO', idArchivo: CENTROS_COSTO },
    { id: 'PESO_SALARIAL', nombre: 'PESO SALARIAL', idArchivo: PESO_SALARIAL },
    { id: 'ENCUESTA_FTE', nombre: 'ENCUESTA DE ACTIVIDADES', idArchivo: ENCUESTA_ACTIVIDADES },
    { id: 'METROS_CUADRADOS', nombre: 'METROS CUADRADOS', idArchivo: METROS_CUADRADOS },
    { id: 'SEGUROS', nombre: 'SEGURO DE EMPLEADOS Y GASTOS MEDICOS', idArchivo: SEGURO_EMPLEADO },
    { id: 'CAPACITACION', nombre: 'INDICADORES DE CAPACITACIÓN', idArchivo: DRIVER_CAPACITACIONES },
    {
        id: 'PRODUCTOS_NUEVOS_MODIFICADOS',
        nombre: 'CANTIDAD DE MODIFICACIONES/NUEVOS PRODUCTOS',
        idArchivo: NUEVOS_O_MODIFICADOS_PRODUCTOS,
    },
    { id: 'DRIVER_20', nombre: 'DVR 20 MONTO COBRADO', idArchivo: DRIVER_20_21_22 },
    { id: 'DRIVER_21', nombre: 'DVR 21 MONTO COBRADO SIN BANCO', idArchivo: DRIVER_20_21_22 },
    { id: 'DRIVER_22', nombre: 'DVR 22 MONTO COBRADO CON TARJETA DE CRÉDITO', idArchivo: DRIVER_20_21_22 },
    { id: 'DRIVER_12', nombre: 'DVR 12 CANTIDAD DE REQUERIMIENTOS DE COBRO', idArchivo: DRIVER_12 },
    { id: 'DRIVER_16', nombre: 'DVR 16 CANTIDAD DE SALVAMENTOS', idArchivo: DRIVER_16 },
    { id: 'DRIVER_17', nombre: 'DVR 17 CANTIDAD DE RECOBRO', idArchivo: DRIVER_17 },
    { id: 'DRIVER_14_Q', nombre: 'DVR 24 MONTO PAGADO POR INFRAESTRUCTURA QUETZALES', idArchivo: DRIVER_24_QUETZALES },
    { id: 'DRIVER_14_D', nombre: 'DVR 24 MONTO PAGADO POR INFRAESTRUCTURA DOLARES', idArchivo: DRIVER_24_DOLARES },
    { id: 'DRIVER_26', nombre: 'DVR 26 CANTIDAD DE SERVICIOS ATENDIDOS AUTO', idArchivo: DRIVER_26_27 },
    { id: 'DRIVER_27', nombre: 'DVR 27 CANTIDAD DE SERVICIOS ATENDIDOS GM', idArchivo: DRIVER_26_27 },
];

export const OPCIONES_BOLSON = [
    { value: 'DRIVER_MT2', label: 'MTS2' },
    { value: 'DRIVER_PESO', label: 'Peso' },
];

export const OPCIONES_ITEMS = [
    {
        value: 'DRIVER_13_1',
        label: 'CANTIDAD TOTAL DE OPERACIONES REASEGURO CEDIDO',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    { value: 'DRIVER_2', label: 'CANTIDAD TOTAL DE POLIZAS NUEVAS Y RENOVADAS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_2_1', label: 'CANTIDAD DE POLIZAS NUEVAS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_2_2', label: 'CANTIDAD DE POLIZAS RENOVADAS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_3', label: 'CANTIDAD TOTAL DE RECLAMOS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_3_1', label: 'CANTIDAD DE RECLAMOS VIDA Y GM', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_3_2', label: 'CANTIDAD DE RECLAMOS AUTOS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_3_3', label: 'CANTIDAD DE RECLAMOS DIVERSOS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_3_4', label: 'CANTIDAD DE RECLAMOS FIANZAS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_4', label: 'CANTIDAD DE POLIZAS FACULTATIVAS', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_08', label: 'PRODUCTOS NUEVOS Y MODIFICADOS', tipo: 'DRIVER', carga: 'manual' },
    {
        value: 'DRIVER_9_1',
        label: 'CANTIDAD ORDENES DE BPM POLIZAS NUEVAS Y RENOVADAS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_9_2',
        label: 'CANTIDAD ORDENES DE BPM POLIZAS NUEVAS Y RENOVADAS CANAL TELEMERCADEO',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10',
        label: 'CANTIDAD TOTAL DE ORDENES DE BPM PROCESADAS EN PRODUCCIÓN',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_1_1',
        label: 'CANTIDAD ORDENES DE BPM POR RAMO AUTO FLOTILLA',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_1_2',
        label: 'CANTIDAD ORDENES DE BPM POR RAMO AUTO INDIVIDUAL',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_2',
        label: 'CANTIDAD ORDENES DE BPM POR RAMO GASTOS MEDICOS COLECTIVOS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_4',
        label: 'CANTIDAD ORDENES DE BPM PROCESADAS EN PRODUCCIÓN BCA SEG',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_5',
        label: 'CANTIDAD ORDENES DE BPM PROCESADAS EN PRODUCCIÓN AGRICOLA',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_7',
        label: 'CANTIDAD ORDENES DE BPM PROCESADAS EN PRODUCCIÓN FIANZAS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_8',
        label: 'CANTIDAD ORDENES DE BPM POR RAMO DAÑOS (DIVERSOS)',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_9',
        label: 'CANTIDAD ORDENES DE BPM DE LINEAS EMPRESARIALES',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_10',
        label: 'CANTIDAD ORDENES DE BPM DE LINEAS ESPECIALES',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_11',
        label: 'CANTIDAD ORDENES DE BPM DE LINEAS PERSONALES',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_13',
        label: 'CANTIDAD ORDENES DE BPM POR RAMO GASTOS MEDICOS INDIVIDUALES',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_14',
        label: 'CANTIDAD ORDENES DE BPM POR RAMO VIDA COLECTIVOS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_10_15',
        label: 'CANTIDAD ORDENES DE BPM POR RAMO VIDA INDIVIDUAL',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11',
        label: 'CANTIDAD TOTAL DE ORDENES DE BPM ENTREGA DE POLIZAS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_1',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO AUTO FLOTILLA',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_2',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO VIDA COLECTIVO',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_3',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO AUTO INDIVIDUAL',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_4',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO GASTOS MEDICOS COLECTIVOS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_5',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO GASTOS MEDICOS INDIVIDUAL',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_6',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS BANCASEGUROS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_7',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO FIANZAS',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_8',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO PROAGRO',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_9',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO DAÑOS (DIVERSOS)',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    {
        value: 'DRIVER_11_10',
        label: 'CANTIDAD ORDENES DE BPM ENTREGA DE POLIZAS RAMO VIDA INDIVIDUAL',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    { value: 'DRIVER_12', label: 'CANTIDAD DE REQUERIMIENTOS DE COBROS', tipo: 'DRIVER', carga: 'manual' },
    {
        value: 'DRIVER_13',
        label: 'CANTIDAD TOTAL DE POLIZAS FACULTATIVAS Y FRONTING',
        tipo: 'DRIVER',
        carga: 'automatica',
    },
    { value: 'DRIVER_14', label: 'CANTIDAD DE POLIZAS FRONTING', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_16', label: 'CANTIDAD DE SALVAMENTOS', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_17', label: 'CANTIDAD DE RECOBROS', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_20', label: 'MONTO COBRADO', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_21', label: 'MONTO COBRADO SIN BANCO', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_22', label: 'MONTO COBRADO CON TARJETA', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_24', label: 'MONTO PAGADO POR INFRAESTRUCTURA', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_25', label: 'MONTO COBRADO  AÑO ANTERIOR', tipo: 'DRIVER', carga: 'automatica' },
    { value: 'DRIVER_26', label: 'CANTIDAD DE SERVICIOS ATENDIDOS AUTOS', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_27', label: 'CANTIDAD DE SERVICIOS ATENDIDOS GM', tipo: 'DRIVER', carga: 'manual' },
    { value: 'DRIVER_MT2', label: 'MTS2', tipo: 'BOLSON' },
    { value: 'DRIVER_PESO', label: 'PESO', tipo: 'BOLSON' },
    { value: 'DRIVER_CAPACITACION', label: 'CAPACITACIÓN', tipo: 'BOLSON' },
    { value: 'SEGURO_EMPLEADO', label: 'SEGURO', tipo: 'BOLSON' },
    { value: 'FTE', label: 'FTE', tipo: 'ABC' },
];

export const LISTADO_CATALOGOS = [
    {
        estado: 'WAITING',
        nombre: 'CA_CUENTA',
    },
    {
        estado: 'WAITING',
        nombre: 'CA_RAMO',
    },
    {
        estado: 'WAITING',
        nombre: 'CA_PRODUCTO',
    },
    {
        estado: 'WAITING',
        nombre: 'CA_INTERMEDIARIO',
    },
    {
        estado: 'WAITING',
        nombre: 'CA_UNIDAD-CANAL',
    },
];

export const DIRECTO = 10;
export const CLAVE = 20;
export const SOPORTE = 30;
export const COMPUESTO = 40;

export const OPCIONES_DISTRIBUCION = [
    // { value: DIRECTO, label: 'Directo' },
    { value: CLAVE, label: 'Clave' },
    // { value: SOPORTE, label: 'Soporte' },
    { value: COMPUESTO, label: 'Compuesto' },
];

export const GOOGLE_OAUTH_ID = process.env && process.env.GOOGLE_OAUTH_ID ? process.env.GOOGLE_OAUTH_ID : '903278975562-8pfj3rphk01l739n5p83c5ma45oa63i2.apps.googleusercontent.com';
