import React from 'react';
import Cabecera from '../../Utils/Cabeceras/CabeceraInforme';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import DriversAutomaticos from './PasosWizard/DriversAutomaticos';
import ArbolCentrosCosto from './PasosWizard/ArbolCentrosCosto';
import PesoSalarial from './PasosWizard/PesoSalarial';
import EncuestaFTE from './PasosWizard/EncuestaFTE';
import MetrosCuadrados from './PasosWizard/MetrosCuadrados';
import SeguroEmpleados from './PasosWizard/SeguroEmpleados';
import ServiciosAutoGm from './PasosWizard/ServiciosAutoGm';
import ProductosNuevos from './PasosWizard/ProductosNuevos';
import MontoCobrado from './PasosWizard/MontoCobrado';
import RequerimientosCobro from './PasosWizard/RequerimientosCobro';
import Salvamentos from './PasosWizard/Salvamentos';
import Recobro from './PasosWizard/Recobro';
import MontoInfraestructura from './PasosWizard/MontoInfraestructura';
import IndicadoresCapacitacion from './PasosWizard/IndicadoresCapacitacion';
import MCAnioAnterior from './PasosWizard/MCAnioAnterior';
import { getMes } from '../../Utils/utilidades';
import PasoFinal from './PasosWizard/PasoFinal';
import './cargaInforme.css';

const CargaInforme = props => {
    React.useEffect(() => {
        const { leer, item, history } = props;
        const { id, paso } = props.match.params;
        async function verificar_paso() {
            if ((!item.id && !item.ultimo_paso) || item.id !== parseInt(id)) {
                await leer(id);
            } else {
                if (item.ultimo_paso < parseInt(paso)) {
                    history.push(`/informe/${item.id}/${item.ultimo_paso}`);
                }
            }
            setPaso(parseInt(paso));
        }
        verificar_paso().then();
    }, [props.match.params]);

    const [paso, setPaso] = React.useState(0);
    const [totalPasos, setTotalPasos] = React.useState(15);

    const { item } = props;

    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera
                    titulo={item.anio}
                    subtitulo={getMes(item.mes)}
                    informe={props.item}
                    setLoader={props.setLoader}
                />
                <LoadMask loading={props.loader} blur>
                    {paso === 1 && <DriversAutomaticos paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso == 2 && <MCAnioAnterior paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 3 && <ServiciosAutoGm paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 4 && <ProductosNuevos paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 5 && <MontoCobrado paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 6 && (
                        <RequerimientosCobro paso={paso} totalPasos={totalPasos} informe={item} {...props} />
                    )}
                    {paso === 7 && <Salvamentos paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 8 && <Recobro paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 9 && (
                        <MontoInfraestructura paso={paso} totalPasos={totalPasos} informe={item} {...props} />
                    )}
                    {paso === 10 && <ArbolCentrosCosto paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 11 && <PesoSalarial paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 12 && <EncuestaFTE paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 13 && <MetrosCuadrados paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 14 && <SeguroEmpleados paso={paso} totalPasos={totalPasos} informe={item} {...props} />}
                    {paso === 15 && (
                        <IndicadoresCapacitacion paso={paso} totalPasos={totalPasos} informe={item} {...props} />
                    )}
                    {paso >= 16 && <PasoFinal paso={paso} informe={item} {...props} />}
                </LoadMask>
            </div>
        </React.Fragment>
    );
};

export default CargaInforme;
