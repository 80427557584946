import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from '../LoadMask/LoadMask';

import './grid.css';

function rowClassNameFormat(row, rowIdx) {
    return rowIdx % 2 === 0 ? 'td-column-function-even-example' : 'td-column-function-odd-example';
}

export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    render() {
        const {
            loading,
            data,
            page,
            remote = true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            className,
            scrollTable,
            sizePerPage,
            paginationPosition,
            expandBy,
            isColor,
            ...other
        } = this.props;
        const options = {
            sizePerPage: sizePerPage ? sizePerPage : 10,
            hideSizePerPage: true,
            paginationSize: 5,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : 'No hay datos',
            page,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand: onExpand,
            expandRowBgColor: '#B7B6B6',
            expanding: expanding,
            expandBy: expandBy ? expandBy : 'row',
            paginationPosition: paginationPosition ? paginationPosition : 'bottom',
        };

        if (isColor == 'White') {
            options.expandRowBgColor = '#FFFFFF';
        }

        const getClass = () => {
            if (!!scrollTable) {
                return 'scroll-table';
            } else {
                return '';
            }
        };

        const paginar = !(pagination === false);

        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={rowClassNameFormat}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data.results}
                        afterSaveCell={afterSave}
                        remote={remote}
                        pagination={paginar}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={{ ...options, expanding: expanding }}
                        {...other}
                        containerClass={getClass()}
                        className={className}
                    />
                </LoadMask>
            </div>
        );
    }
}
