import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconoAlerta from '../../../../../../../assets/img/alerta-xl.png';
import Grid from '../../../../Utils/Grid';
import { OPCIONES_ITEMS } from '../../../../../../utility/constants';

import './asignacion.css';

const ABC = OPCIONES_ITEMS.filter(it => it.tipo == 'ABC');
const BOLSON = OPCIONES_ITEMS.filter(it => it.tipo == 'BOLSON');

const TypeOptionsSearch = [
    { label: 'Todos', value: 'todos' },
    { label: 'Asignados', value: 'asignados' },
    { label: 'No Asignados', value: 'no_asignados' },
];

const TypeOptions = [
    { label: 'Directo', value: 'directo' },
    { label: 'Bolson', value: 'bolson' },
    { label: 'ABC', value: 'abc' },
];

const themeOfSelect = theme => ({
    ...theme,
    border: 'none',
    background: 'white',
    colors: {
        ...theme.colors,
        text: '#415362',
        primary25: '#ebebeb',
        primary: '#0a5995',
    },
});

let cc_0 = [];
let cc_not_0 = [];
let is8_12_1 = [];
let is9_19_4 = [];

const Asignacion = props => {
    const { data_abc, data, loader, eliminar, page, listar: onPageChange, history, listadoDrivers } = props;
    const [Search, setSearch] = React.useState('');

    const { id } = props.match.params;

    React.useEffect(() => {
        props.listarDrivers(id);
        props.listar_abc();
        props.listar(1, id, 'todos', '');
    }, []);

    const [state, setState] = useState({ selected: [] });

    React.useEffect(() => {
        if (cc_0.length > 0 && cc_not_0.length > 0) {
            alert_incompatibility();
        }

        if (is8_12_1.length > 0 || is9_19_4.length > 0) {
            alert_exceptions();
        }
    }, [state]);

    function alert_incompatibility() {
        const mySwal = withReactContent(Swal);
        mySwal
            .fire({
                title: 'Alerta existen centro de costos incompatibles',
                text: '¿Cuales desea remover?',
                iconHtml: <img src={IconoAlerta} />,
                showCancelButton: false,
                showDenyButton: true,
                denyButtonText: 'Otros',
                confirmButtonText: '0.0.0',
                confirmButtonColor: '#3367b2',
                denyButtonColor: '#c4183c',
                reverseButtons: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
            })
            .then(async res => {
                if (res.isConfirmed) {
                    remove_incompatibility(true);
                } else if (res.isDenied) {
                    remove_incompatibility(false);
                }
            });
    }

    function alert_exceptions() {
        const mySwal = withReactContent(Swal);
        mySwal
            .fire({
                title: 'Alerta existen centro de costos exceptuados',
                text:
                    'Las referencias pertenecientes a los centros de costo 8.12.1 y 9.19.4 se configuran individualmente, dichas referencias seran removidas',
                iconHtml: <img src={IconoAlerta} />,
                showCancelButton: false,
                showDenyButton: false,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#3367b2',
                denyButtonColor: '#c4183c',
                reverseButtons: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
            })
            .then(async res => {
                if (res.isConfirmed) {
                    remove_exceptions();
                }
            });
    }

    function remove_exceptions() {
        let array = state.selected;

        array.forEach((element, index) => {
            let ref_exclude = element.referencia.substr(3, 6);

            if (ref_exclude == '8.12.1') {
                array = array.filter(it => it.id !== element.id);
                is8_12_1 = is8_12_1.filter(it => it !== element.id);
                cc_not_0 = cc_not_0.filter(it => it !== element.id);
            } else if (ref_exclude == '9.19.4') {
                array = array.filter(it => it.id !== element.id);
                is9_19_4 = is9_19_4.filter(it => it !== element.id);
                cc_not_0 = cc_not_0.filter(it => it !== element.id);
            }
        });

        setState({ selected: array });
    }

    function remove_incompatibility(isZeroCC) {
        let array = state.selected;

        array.forEach((element, index) => {
            let ref = element.referencia.substr(3, 5);

            if (ref == '0.0.0') {
                if (isZeroCC) {
                    array = array.filter(it => it.id !== element.id);
                    cc_0 = cc_0.filter(it => it !== element.id);
                }
            } else {
                if (!isZeroCC) {
                    array = array.filter(it => it.id !== element.id);
                    cc_not_0 = cc_not_0.filter(it => it !== element.id);
                }
            }
        });

        setState({ selected: array });
    }

    async function onRowSelect({ id }, isSelected, rows) {
        let item = data.results.find(element => element.id == id);
        let ref = item.referencia.substr(3, 5);
        let ref_exclude = item.referencia.substr(3, 6);

        if (isSelected) {
            setState({
                selected: [...state.selected, item].sort(),
            });

            if (ref == '0.0.0') {
                cc_0.push(item.id);
            } else if (ref != '0.0.0') {
                cc_not_0.push(item.id);
            }

            if (ref_exclude == '8.12.1') {
                is8_12_1.push(item.id);
            } else if (ref_exclude == '9.19.4') {
                is9_19_4.push(item.id);
            }
        } else {
            setState({ selected: state.selected.filter(it => it.id !== id) });
            if (ref == '0.0.0') {
                cc_0 = cc_0.filter(it => it !== id);
            } else if (ref != '0.0.0') {
                cc_not_0 = cc_not_0.filter(it => it !== id);
            }

            if (ref_exclude == '8.12.1') {
                is8_12_1 = is8_12_1.filter(it => it !== id);
            } else if (ref_exclude == '9.19.4') {
                is9_19_4 = is9_19_4.filter(it => it !== id);
            }
        }
    }

    function onSelectAll(isSelected, rows) {
        let array = state.selected;

        rows.forEach((element, index2) => {
            let ref = element.referencia.substr(3, 5);
            let ref_exclude = element.referencia.substr(3, 6);

            if (isSelected) {
                array.push(element);

                if (ref == '0.0.0') {
                    cc_0.push(element.id);
                } else {
                    cc_not_0.push(element.id);
                }

                if (ref_exclude == '8.12.1') {
                    is8_12_1.push(element.id);
                } else if (ref_exclude == '9.19.4') {
                    is9_19_4.push(element.id);
                }
            } else {
                array = array.filter(it => it.id !== element.id);
                if (ref == '0.0.0') {
                    cc_0 = cc_0.filter(it => it !== element.id);
                } else {
                    cc_not_0 = cc_not_0.filter(it => it !== element.id);
                }

                if (ref_exclude == '8.12.1') {
                    is8_12_1 = is8_12_1.filter(it => it !== element.id);
                } else if (ref_exclude == '9.19.4') {
                    is9_19_4 = is9_19_4.filter(it => it !== element.id);
                }
            }
        });

        setState({ selected: array });
    }

    const selectRowProp = {
        mode: 'checkbox',
        onSelect: onRowSelect,
        onSelectAll: onSelectAll,
        selected: state.selected.map(data => data.id),
        columnWidth: '50px',
    };

    function statusAssigned(cell, row, enumObject) {
        if (row.asignado == false) {
            return <div className="no_asignado">PENDIENTE</div>;
        } else if (row.asignado == true) {
            return <div className="asignado">ASIGNADO</div>;
        }
    }

    function ChangeUpdateOne(item, e) {
        let ref = item.referencia.substr(3, 5);
        let ref_exclude = item.referencia.substr(3, 6);

        if (item.tipo == 'bolson') {
            if (
                item.tipo != null &&
                item.distribuir != null &&
                item.distribucion != null &&
                item.driver_alternativo != null
            ) {
                item.asignado = true;
            } else {
                item.asignado = false;
            }
        } else if (item.tipo != 'bolson') {
            if (item.tipo != null && item.distribuir != null && item.distribucion != null) {
                item.asignado = true;
                item.driver_alternativo = null;
            } else {
                item.asignado = false;
            }
        }
        //}

        let ref_arr = [];
        ref_arr.push(item);
        props.actualizar(ref_arr).then(() => {
            props.listar(page, id, Filter_Type, Search);
        });
    }
    const [selected, setSelected] = React.useState(null);

    //FILTRO GENERAL DE TIPO
    const [Filter_Type, setFilterType] = React.useState('todos');
    const [valorFiltro, setValorFiltro] = React.useState('todos');
    const [Cuenta_ABC, setCuenta_ABC] = React.useState(null);
    const [Driver, setDriver] = React.useState(null);
    const [Driver_Alternativo, setDriver_Alternativo] = React.useState(null);

    //SELECIONAR Y APLICAR
    function Filtrado_General() {
        setFilterType(valorFiltro);
        props.listar(1, id, valorFiltro, Search);
    }

    async function aplicar_multi_row() {
        let selected_row = state.selected;
        let ref = [];

        selected_row.forEach((element, index) => {
            element.tipo = selected && selected.value ? selected.value : null;
            element.distribucion = Driver && Driver.value ? Driver.value : null;
            element.distribuir = Cuenta_ABC && Cuenta_ABC.id ? Cuenta_ABC.id : null;
            element.driver_alternativo =
                Driver_Alternativo && Driver_Alternativo.value ? Driver_Alternativo.value : null;
            ref.push(element);
            if (ref == '0.0.0') {
                if (element.tipo != null && element.distribuir != null && element.distribucion != null) {
                    element.asignado = true;
                } else {
                    element.asignado = false;
                }
            } else {
                if (element.tipo == 'bolson') {
                    if (
                        element.tipo != null &&
                        element.distribuir != null &&
                        element.distribucion != null &&
                        element.driver_alternativo != null
                    ) {
                        element.asignado = true;
                    } else {
                        element.asignado = false;
                    }
                } else if (element.tipo != 'bolson') {
                    if (element.tipo != null && element.distribuir != null && element.distribucion != null) {
                        element.asignado = true;
                        element.driver_alternativo = null;
                    } else {
                        element.asignado = false;
                    }
                }
            }
        });

        props.actualizar(ref).then(() => {
            props.listar(page, id, Filter_Type, Search);
            cc_0 = [];
            cc_not_0 = [];
            is8_12_1 = [];
            is9_19_4 = [];
            setCuenta_ABC(null);
            setDriver(null);
            setDriver_Alternativo(null);
            setSelected(null);
            setState({ selected: [] });
        });
    }

    //VALIDA EL OBJECTO DEL PANEL
    function apply_panel_multi() {
        aplicar_multi_row();
    }

    function validacion_multi() {
        if (selected != null && Cuenta_ABC != null && Driver != null && state.selected.length > 0) {
            if (selected.value === 'bolson' && Driver_Alternativo == null) return true;
            else false;
        } else {
            return true;
        }
    }

    return (
        <React.Fragment>
            <div>
                <br />
                <br />
                <div className="general-container">
                    <div className="general-container-filter1">
                        <div className="container-filter-a1">
                            <label className="text-filter">Filtrar por estado de la referencia: </label>
                        </div>
                        <div className="container-filter-b1">
                            <Select
                                isClearable={false}
                                className="form-control p-0 w-20 col-12 border-trasparent "
                                autoFocus
                                isSearchable={false}
                                defaultValue={TypeOptionsSearch[0]}
                                onChange={e => {
                                    setValorFiltro(e && e.value ? e.value : null);
                                }}
                                theme={themeOfSelect}
                                classNamePrefix="react-select"
                                placeholder="Seleccionar"
                                cache={false}
                                options={TypeOptionsSearch}
                            />
                        </div>
                        <div className="container-filter-c1">
                            <button className="btn btn-blue" onClick={Filtrado_General}>
                                Aplicar
                            </button>
                        </div>
                    </div>

                    <div className="general-container-filter2">
                        <input
                            type="text"
                            onChange={e => {
                                props.listar(1, id, Filter_Type, e.target.value);
                                setSearch(e && e.target.value ? e.target.value : '');
                            }}
                            className="form-control mb-3 mb-md-0 "
                            placeholder="Escriba para buscar"
                        />
                    </div>
                </div>
                <hr className="w-100 mt-0" style={{ backgroundColor: '#e8e8e8b0', opacity: '0.9' }} />
                <div className="general-container-filter mb-3">
                    <div className="container-filter-a">
                        <label className="text-filter">Seleccionados: </label>
                    </div>

                    <div className="container-filter-b0">
                        <Select
                            isClearable={false}
                            className="form-control p-0 w-20 col-12 border-trasparent "
                            autoFocus
                            isSearchable={false}
                            value={Cuenta_ABC}
                            onChange={e => {
                                setCuenta_ABC(e && e.id ? e : null);
                            }}
                            theme={themeOfSelect}
                            classNamePrefix="react-select"
                            getOptionValue={option => option['id']}
                            getOptionLabel={option => option['nombre']}
                            placeholder="Seleccionar"
                            cache={false}
                            options={data_abc}
                        />
                    </div>
                    <div className="container-filter-ba">
                        <Select
                            isClearable={false}
                            className="form-control p-0 w-20 col-12 border-trasparent "
                            autoFocus
                            isSearchable={false}
                            value={selected}
                            onChange={e => {
                                setSelected(e && e.value ? e : null);
                                setDriver(null);
                            }}
                            theme={themeOfSelect}
                            classNamePrefix="react-select"
                            placeholder="Seleccionar"
                            cache={false}
                            options={TypeOptions}
                        />
                    </div>
                    <div className="container-filter-b00"></div>
                    {selected && selected.value === 'directo' && (
                        <div className="container-filter-bb">
                            <Select
                                isClearable={false}
                                className="form-control p-0 w-20 col-12 border-trasparent "
                                autoFocus
                                isSearchable={false}
                                value={Driver}
                                onChange={e => {
                                    setDriver(e && e.value ? e : null);
                                }}
                                theme={themeOfSelect}
                                classNamePrefix="react-select"
                                placeholder="Seleccionar"
                                cache={false}
                                options={listadoDrivers}
                            />
                        </div>
                    )}
                    {selected && selected.value === 'bolson' && (
                        <div className="container-filter-bb">
                            <Select
                                isClearable={false}
                                className="form-control p-0 w-20 col-12 border-trasparent "
                                autoFocus
                                isSearchable={false}
                                value={Driver}
                                onChange={e => {
                                    setDriver(e && e.value ? e : null);
                                }}
                                theme={themeOfSelect}
                                classNamePrefix="react-select"
                                placeholder="Seleccionar"
                                cache={false}
                                options={BOLSON}
                            />
                        </div>
                    )}

                    {selected && selected.value === 'abc' && (
                        <div className="container-filter-bb">
                            <Select
                                isClearable={false}
                                className="form-control p-0 w-20 col-12 border-trasparent "
                                autoFocus
                                isSearchable={false}
                                value={Driver}
                                onChange={e => {
                                    setDriver(e && e.value ? e : null);
                                }}
                                theme={themeOfSelect}
                                classNamePrefix="react-select"
                                placeholder="Seleccionar"
                                cache={false}
                                options={ABC}
                            />
                        </div>
                    )}

                    {selected && selected.value === 'bolson' && (
                        <div className="container-filter-bc">
                            <Select
                                isClearable={false}
                                className="form-control p-0 w-20 col-12 border-trasparent "
                                autoFocus
                                isSearchable={false}
                                value={Driver_Alternativo}
                                onChange={e => {
                                    setDriver_Alternativo(e && e.value ? e : null);
                                }}
                                theme={themeOfSelect}
                                classNamePrefix="react-select"
                                placeholder="Seleccionar"
                                cache={false}
                                options={listadoDrivers}
                            />
                        </div>
                    )}
                    <div className="container-filter-c">
                        <button className="btn btn-blue" disabled={validacion_multi()} onClick={apply_panel_multi}>
                            Aplicar
                        </button>
                    </div>
                </div>

                <Grid
                    data={data}
                    page={page}
                    sizePerPage={20}
                    paginationPosition="both"
                    onPageChange={page => onPageChange(page, id, Filter_Type, Search)}
                    selectRow={selectRowProp}
                    className="custom-table"
                >
                    <TableHeaderColumn dataAlign="left" width="0" dataField="id" isKey>
                        ID
                    </TableHeaderColumn>
                    <TableHeaderColumn dataAlign="left" width="200" dataField="referencia">
                        REFERENCIA
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataAlign="left"
                        width="250"
                        dataField="distribuir"
                        columnClassName="my-class"
                        dataFormat={(value, row) => {
                            return (
                                <select
                                    value={value || ''}
                                    onChange={e => ChangeUpdateOne({ ...row, distribuir: e.target.value })}
                                    className="form-control"
                                >
                                    <option hidden value={''} key={''}>
                                        Selecciona una cuenta
                                    </option>
                                    {data_abc.map(abc => (
                                        <option value={abc.id} key={abc.id}>
                                            {abc.nombre}
                                        </option>
                                    ))}
                                </select>
                            );
                        }}
                    >
                        CUENTA ABC
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        width="130"
                        dataField="tipo"
                        columnClassName="my-class"
                        dataFormat={(value, row) => {
                            return (
                                <select
                                    value={value || ''}
                                    onChange={e =>
                                        ChangeUpdateOne({
                                            ...row,
                                            tipo: e.target.value,
                                            distribucion: null,
                                            driver_alternativo: null,
                                        })
                                    }
                                    className="form-control"
                                >
                                    <option hidden value={''} key={''}>
                                        Selecciona un tipo
                                    </option>
                                    {TypeOptions.map(type => (
                                        <option value={type.value} key={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                </select>
                            );
                        }}
                    >
                        TIPO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        width="325"
                        dataField="distribucion"
                        dataFormat={(value, row) => {
                            let ref = row.referencia.substr(3, 5);

                            if (row.tipo == 'directo') {
                                return (
                                    <select
                                        value={value || ''}
                                        onChange={e => ChangeUpdateOne({ ...row, distribucion: e.target.value })}
                                        className="form-control"
                                    >
                                        <option hidden value={''} key={''}>
                                            Selecciona una distribucion
                                        </option>
                                        {listadoDrivers.map((driver, i) => (
                                            <option value={driver.value} key={driver.value}>
                                                {driver.label} - {driver.value}
                                            </option>
                                        ))}
                                    </select>
                                );
                            } else if (row.tipo == 'bolson') {
                                return (
                                    <select
                                        value={value || ''}
                                        onChange={e => ChangeUpdateOne({ ...row, distribucion: e.target.value })}
                                        className="form-control"
                                    >
                                        <option hidden value={''} key={''}>
                                            Selecciona una distribucion
                                        </option>
                                        {BOLSON.map((bolson, i) => (
                                            <option value={bolson.value} key={bolson.value}>
                                                {bolson.label} -- {bolson.value}
                                            </option>
                                        ))}
                                    </select>
                                );
                            } else if (row.tipo == 'abc') {
                                return (
                                    <select
                                        value={value || ''}
                                        onChange={e => ChangeUpdateOne({ ...row, distribucion: e.target.value })}
                                        className="form-control"
                                        title="Hola"
                                    >
                                        <option hidden value={''} key={''}>
                                            Selecciona una distribucion
                                        </option>
                                        {ABC.map((abc, i) => (
                                            <option value={abc.value} key={abc.value}>
                                                {abc.label}
                                            </option>
                                        ))}
                                    </select>
                                );
                            } else if (value == null) {
                                return '';
                            }
                        }}
                        formatExtraData="estado"
                    >
                        DISTRIBUCION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        width="250"
                        dataField="driver_alternativo"
                        dataFormat={(value, row) => {
                            let ref = row.referencia.substr(3, 5);

                            if (row.tipo == 'bolson') {
                                return (
                                    <select
                                        value={value || ''}
                                        onChange={e => ChangeUpdateOne({ ...row, driver_alternativo: e.target.value })}
                                        className="form-control"
                                    >
                                        <option hidden value={''} key={''}>
                                            Selecciona una driver
                                        </option>
                                        {listadoDrivers.map((driver, i) => (
                                            <option value={driver.value} key={driver.value}>
                                                {driver.label} - {driver.value}
                                            </option>
                                        ))}
                                    </select>
                                );
                            }
                        }}
                        formatExtraData="tipo"
                    >
                        DRIVER CD
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        width="100"
                        dataField="asignado"
                        dataFormat={statusAssigned}
                        formatExtraData="tipo"
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default Asignacion;
