import React from 'react';
import { Field } from 'react-final-form';
import { renderFieldRadio, renderField, renderDatePicker } from '../../Utils/renderField/renderField';
import IconoCarga from '../../../../../assets/img/carga-archivos.png';
import { INFORME_OFICIAL, INFORME_PROYECCION, MESES } from '../../../../utility/constants';
import { Form } from 'react-final-form';
import Cabecera from '../../Utils/Cabeceras/CabeceraInforme';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import moment from 'moment';

const validate = values => {
    const errors = {};
    if (!values.tipo_informe) {
        errors.tipo_informe = 'Campo requerido';
    }
    if (!values.nombre && values.tipo_informe === INFORME_PROYECCION) {
        errors.nombre = 'Campo requerido';
    }
    if (!values.fecha_inicial && values.tipo_informe === INFORME_PROYECCION) {
        errors.fecha_inicial = 'Campo requerido';
    }
    if (!values.fecha_final && values.tipo_informe === INFORME_PROYECCION) {
        errors.fecha_final = 'Campo requerido';
    }
    return errors;
};

const NuevoInforme = props => {
    React.useEffect(() => {}, [props.match.params]);
    const onSubmit = async data => {
        const { crear, match } = props;
        const body = { ...data };
        const anio = match.params.anio;
        const mes = match.params.mes;
        body.anio = anio;
        body.mes = mes;
        if (data.tipo_informe == INFORME_PROYECCION) {
            body.fecha_inicial = moment(data.fecha_inicial).format('YYYY-MM-DD');
            body.fecha_final = moment(data.fecha_final).format('YYYY-MM-DD');
        }
        await crear(body);
    };

    const onCancelar = () => {
        const { anio, mes } = props.match.params;
        if (anio && mes) {
            props.history.push(`/historial/${anio}/${mes}`);
        } else {
            props.history.push('/');
        }
    };
    const BotonRegresar = props => (
        <button className="btn btn-cancelar mr-2 mr-xl-3" onClick={onCancelar} type="button">
            Regresar
        </button>
    );

    function handleChange(e) {
        const value = e.target.value;
        setState({ tipo_informe: value });
    }
    const { anio, mes } = props.match.params;
    return (
        <React.Fragment>
            <LoadMask loading={props.loader} blur>
                <div className="card px-4 my-3 pb-4">
                    <Cabecera titulo={mes} subtitulo={anio} headerButton={BotonRegresar} />
                    <Form validate={validate} initialValues={{ tipo_informe: INFORME_PROYECCION }} onSubmit={onSubmit}>
                        {({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="d-flex flex-1 flex-row justify-content-center">
                                    <img src={IconoCarga} className="icono-carga" />
                                </div>
                                <br />
                                <br />
                                <div className="d-flex justify-content-center">
                                    <span className="text-center">¿La carga es oficial o es una proyección?</span>
                                </div>
                                <br />
                                <div className="d-flex flex-1 flex-row justify-content-center">
                                    <div className="mr-2">
                                        <Field
                                            name="tipo_informe"
                                            component={renderFieldRadio}
                                            onChange={handleChange}
                                            label="Oficial"
                                            type="radio"
                                            parse={val => +val}
                                            value={INFORME_OFICIAL}
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <Field
                                            name="tipo_informe"
                                            component={renderFieldRadio}
                                            onChange={handleChange}
                                            label="Proyección"
                                            type="radio"
                                            parse={val => +val}
                                            value={INFORME_PROYECCION}
                                        />
                                    </div>
                                </div>
                                {values && values.tipo_informe == 2 && (
                                    <React.Fragment>
                                        <div className="d-flex justify-content-center mt-2">
                                            <div className="col-12 col-md-6 col-lg-5 col-xl-3 justify-content-center">
                                                <label className="form-label" htmlFor="nombre">
                                                    Nombre
                                                </label>
                                                <Field name="nombre" component={renderField} autocomplete="off" />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            <div className="col-12 col-md-6 col-lg-5 col-xl-3 justify-content-center">
                                                <label className="form-label" htmlFor="fecha_inicial">
                                                    Fecha Inicio
                                                </label>
                                                <Field
                                                    className="form-control"
                                                    name="fecha_inicial"
                                                    component={renderDatePicker}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            <div className="col-12 col-md-6 col-lg-5 col-xl-3 justify-content-center">
                                                <label className="form-label" htmlFor="fecha_final">
                                                    Fecha Fin
                                                </label>
                                                <Field
                                                    className="form-control"
                                                    name="fecha_final"
                                                    component={renderDatePicker}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                <br />
                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="btn btn-danger">
                                        Comenzar
                                    </button>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </form>
                        )}
                    </Form>
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default NuevoInforme;
