import React from 'react';
import Stepper from '../../../../Utils/Stepper';
import Gestion from './Gestion';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './ordenGestiones.css';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';

const reOrdenar = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default function OrdenGestiones(props) {
    const { paso, setPaso, totalPasos, setLoader } = props;
    const { id } = props.match.params;

    const [gestiones, setGestiones] = React.useState([]);

    React.useEffect(() => {
        api.get(`/informe/${id}/gestiones_soporte`).then(response => {
            if (response) setGestiones(response);
        });
    }, []);

    const Gestiones = React.memo(function Gestiones({ listaGestiones }) {
        return listaGestiones.map((item, index) => <Gestion item={item} index={index} key={item.id} />);
    });

    const onDragEnd = result => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const gestionesOrdenadas = reOrdenar(gestiones, result.source.index, result.destination.index);
        setGestiones(gestionesOrdenadas);
    };

    const onSiguiente = () => {
        let copiaGestiones = [...gestiones];
        copiaGestiones = copiaGestiones.map((item, index) => ({ ...item, orden: index + 1 }));
        setLoader(true);
        api.post(`informe/ordenar_gestiones`, { gestiones: copiaGestiones })
            .then(() => {
                api.post(`asignacion-gestion/generar`, {}, { informe: id })
                    .then(() => setPaso(paso + 1))
                    .catch(error =>
                        NotificationManager.error(
                            'Ocurrió un problema al generar datos de gestiones de soporte',
                            'Error',
                            5000
                        )
                    );
            })
            .catch(error =>
                NotificationManager.error(
                    'Ocurrió un problema al actualizar orden de gestiones de soporte',
                    'Error',
                    5000
                )
            )
            .finally(() => setLoader(false));
    };

    return (
        <React.Fragment>
            <Stepper
                step={paso}
                stepName="Ordenar Gestiones de  soporte"
                totalStep={totalPasos}
                nextButton={{
                    name: 'Siguiente',
                    next: () => {
                        onSiguiente();
                    },
                    isVisible: true,
                }}
                prevButton={{
                    name: 'Regresar',
                    prev: () => {
                        setPaso(paso - 1);
                    },
                    isVisible: true,
                }}
            />
            <br />
            <br />
            <div className="d-flex flex-column align-items-center">
                <h3 className="text-dark font-weight-bold m-0" style={{ fontSize: '1.5rem' }}>
                    Orden de distribución gestiones de soporte
                </h3>
                <h2 className="text-dark" style={{ fontSize: '1.2rem' }}>
                    Suelte y arreaste para ordenar las gestiones
                </h2>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="gestiones">
                    {provided => (
                        <div
                            className="col-12 col-md-5 col-lg-4 m-auto"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <Gestiones listaGestiones={gestiones} />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </React.Fragment>
    );
}
