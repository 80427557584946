import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { NotificationManager } from 'react-notifications';

import OkIcon from '../../../../../../../assets/img/circulo-check.png';

import { api } from 'api';

const dataSeed = {
    results: [],
    count: 0,
};

const PasoFinal = props => {
    const [data, setData] = React.useState(dataSeed);

    const [page, setPage] = React.useState(1);

    const { setLoader } = props;

    React.useEffect(() => {
        listar(page, 'DRIVER_08');
    }, [page]);

    const listar = (page, id_driver) => {
        setLoader(true);
        api.get('driver/manual', { id_informe: props.informe.id, page, id_driver })
            .then(res => {
                setData(res);
            })
            .catch(error => {
                let mensajeError = 'Error al obtener los registros del driver 8';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <React.Fragment>
            <br />
            <br />
            <span className="text-dark">Registros de driver 8</span>
            <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
            <Grid data={data} onPageChange={setPage} sizePerPage={20} page={page}>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width="7rem"
                    dataFormat={data => {
                        return <img className="column-data-icon" src={OkIcon} />;
                    }}
                >
                    ESTADO
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="codigo_unidad">
                    CODIGO UNIDAD
                </TableHeaderColumn>
                <TableHeaderColumn width="20rem" dataField="unidad">
                    UNIDAD
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="codigo_canal">
                    CODIGO CANAL
                </TableHeaderColumn>
                <TableHeaderColumn width="20rem" dataField="canal">
                    CANAL
                </TableHeaderColumn>
                <TableHeaderColumn width="15rem" dataField="ramo_comercial">
                    RAMO COMERCIAL
                </TableHeaderColumn>
                <TableHeaderColumn width="15rem" dataField="ramo_contable">
                    RAMO CONTABLE
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="codigo_producto">
                    CODIGO PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn width="15rem" dataField="producto">
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="valor">
                    CANTIDAD
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

export default PasoFinal;
