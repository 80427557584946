import React from 'react';
import UploadDriver from './UploadDriver';
import Stepper from '../../../../Utils/Stepper';
import { NotificationManager } from 'react-notifications';
import PasoIntermedio from './PasoIntermedio';
import PasoFinal from './PasoFinal';
import { api } from 'api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconoAlerta from '../../../../../../../assets/img/alerta-xl.png';
import useCopiarDriver from '../useCopiarDriver';
import './paso13.css';
import _ from 'lodash';
const dataSeed = {
    results: [],
    count: 0,
};

const MontoInfraestructura = props => {
    const [driverTitle, setDriverTitle] = React.useState('DVR 24 - Monto pagado por infraestructura');
    // ALERTASQ PARA ALERTAS DEL ARCHIVO DE QUETZALES Y ALERTASD PARA ALERTAS DEL ARCHIVO DE QUETZALES
    const [alertasQ, setAlertasQ] = React.useState(dataSeed);
    const [alertasD, setAlertasD] = React.useState(dataSeed);
    const [erroresQ, setErroresQ] = React.useState(dataSeed);
    const [erroresD, setErroresD] = React.useState(dataSeed);
    // SE HABILITA EL PASO INTERMEDIO SI EXISTE UNA RESPUESTA DEL BACKEND
    const [intermedio, setIntermedio] = React.useState(false);
    // SE HABILITA EL PASO FINAL SI TODO ESTA BIEN
    const [final, setFinal] = React.useState(false);
    // USAMOS ESTA VARIABLE  PARA SABER SI CONSULTAR EL ESTADO DE LA O NO
    const [consultar, setConsultar] = React.useState(false);
    // USAREMOS ESTA VARIABLE PARA AGUARDAR TODOS LOS ARCHIVOS SUBIDOS
    const [fileOne, setFileOne] = React.useState(undefined);
    const [fileTwo, setFileTwo] = React.useState(undefined);
    // USAREMOS ESTA VARIABLE PARA GUARDAR EL TIPO DE CAMBIO
    const [cambio, setCambio] = React.useState(0);

    const { setLoader, paso, informe, history } = props;

    const [nextButton, setNextButton] = React.useState({
        name: 'Siguiente',
        next: undefined,
        isVisible: false,
    });
    const [prevButton, setPrevButton] = React.useState({
        name: 'Regresar',
        prev: undefined,
        isVisible: true,
    });

    const next = async () => {
        const { next } = props;
        if (!final && !intermedio) {
            const mySwal = withReactContent(Swal);
            await mySwal
                .fire({
                    title: 'Tipo de Cambio Dolares',
                    text: 'Ingrese el tipo de cambio del dia',
                    input: 'number',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                    allowOutsideClick: false,
                    inputValidator: value => {
                        if (!value) {
                            return 'Necesitas ingresar el tipo de cambio para continuar!';
                        }
                    },
                })
                .then(async res => {
                    if (res.value) {
                        setCambio(res.value);
                        const files = [];
                        files.push({ name: 'quetzales', file: fileOne });
                        files.push({ name: 'dolares', file: fileTwo });
                        await cargar({ informe: informe.id, cambio: res.value }, files, { driver: 'DRIVER_24' });
                    }
                });
        } else if (intermedio) {
            const mySwal = withReactContent(Swal);
            await mySwal
                .fire({
                    title: 'Al aceptar estarás guardando los registros con alertas',
                    text: '¿Deseas continuar?',
                    iconHtml: <img src={IconoAlerta} />,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                })
                .then(async res => {
                    if (res.value) {
                        await guardar({ informe: informe.id, cambio: cambio }, { driver: 'DRIVER_24' });
                    }
                });
        } else {
            const siguiente = paso + 1;
            history.push(`/informe/${informe.id}/${siguiente}`);
        }
    };
    const prev = () => {
        if (!final && !intermedio) {
            const anterior = paso - 1;
            history.push(`/informe/${informe.id}/${anterior}`);
        } else {
            setFinal(false);
            setIntermedio(false);
            setNextButton(s => ({ ...s, isVisible: false }));
        }
    };

    const guardar = async (data, params) => {
        setLoader(true);
        await api
            .post('driver/manual', data, params)
            .then(async res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al guardar datos de drivers 24';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const cargar = async (data, files = [], params) => {
        setLoader(true);
        await api
            .postAttachments('driver/manual/cargar_archivo', data, files, params)
            .then(async res => {
                setConsultar(true);
            })
            .catch(error => {
                let mensajeError = 'Error al cargar archivo del driver 24';
                if (error && error.detail) mensajeError = error.detail;
                NotificationManager.error(mensajeError, 'Error', 7000);
                setLoader(false);
            });
    };

    const consultarEstado = () => {
        api.get('task/estado', { informe: informe.id, driver: 'DRIVER_24' })
            .then(response => {
                if (response.status === 'SUCCESS') {
                    const siguiente = paso + 1;
                    props.next(informe.id, siguiente).then();
                    setFinal(true);
                    setIntermedio(false);
                    setConsultar(false);
                    setNextButton(s => ({ ...s, isVisible: true }));
                    NotificationManager.success('Archivo del driver 24 cargado exitosamente', 'Éxito', 4000);
                } else if (response.status === 'UPLOADED') {
                    guardar({ informe: informe.id, cambio: cambio }, { driver: 'DRIVER_24' }).then();
                } else if (response.status === 'FAILURE') {
                    if (
                        response.result &&
                        (response.result.alertas_q ||
                            response.result.alertas_d ||
                            response.result.errores_q ||
                            response.result.errores_d)
                    ) {
                        setIntermedio(true);
                        setFinal(false);
                        setAlertasQ({ results: response.result.alertas_q, count: response.result.alertas_q.length });
                        setAlertasD({ results: response.result.alertas_d, count: response.result.alertas_d.length });
                        setErroresQ({ results: response.result.errores_q, count: response.result.errores_q.length });
                        setErroresD({ results: response.result.errores_d, count: response.result.errores_d.length });
                        if (response.result.errores_q.length > 0 || response.result.errores_d.length > 0)
                            setNextButton(s => ({ ...s, isVisible: false }));
                        else setNextButton(s => ({ ...s, isVisible: true }));
                    } else if (
                        response.result &&
                        response.result.exc_message &&
                        _.isString(response.result.exc_message)
                    ) {
                        NotificationManager.error(response.result.exc_message, 'Error', 7000);
                        //setNextButton(s => ({ ...s, isVisible: false }));
                    } else {
                        NotificationManager.error('Ocurrió un error al cargar archivo de driver 24', 'Error', 5000);
                        //setNextButton(s => ({ ...s, isVisible: false }));
                    }
                    setConsultar(false);
                    setLoader(false);
                }
            })
            .catch(e => {
                let msj = 'La tarea que procesa el driver no se pudo ejecutar o fallo.';
                if (e && e.detail) msj = e.detail;
                NotificationManager.error(msj, 'Error', 5000);
                setConsultar(false);
                setLoader(false);
            });
    };

    let interval;

    React.useEffect(() => {
        if (consultar) {
            interval = setInterval(() => {
                consultarEstado();
            }, 30000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [consultar]);

    React.useEffect(() => {
        if (fileOne && fileTwo) {
            setNextButton(s => ({ ...s, isVisible: true }));
        }
    }, [fileOne, fileTwo]);

    React.useEffect(() => {
        return setLoader(false);
    }, []);

    const { copiarDriver } = useCopiarDriver(
        informe,
        'COPIA_DRIVER',
        paso,
        setLoader,
        setNextButton,
        setFinal,
        props.next
    );
    const onCopy = async () => {
        await copiarDriver('driver/manual/copiar_datos', ['DRIVER_24']);
    };
    return (
        <React.Fragment>
            <Stepper
                step={props.paso}
                stepName="DVR 24 - Monto pagado por infraestructura"
                totalStep={props.totalPasos}
                nextButton={{ ...nextButton, next: next }}
                prevButton={{ ...prevButton, prev: prev }}
            />

            {final && <PasoFinal setLoader={setLoader} informe={informe} />}
            {intermedio && (
                <PasoIntermedio alertasQ={alertasQ} alertasD={alertasD} erroresQ={erroresQ} erroresD={erroresD} />
            )}
            {!final && !intermedio && (
                <UploadDriver
                    driverTitle={driverTitle}
                    onCopy={onCopy}
                    fileOne={fileOne}
                    fileTwo={fileTwo}
                    setFileOne={setFileOne}
                    setFileTwo={setFileTwo}
                />
            )}
        </React.Fragment>
    );
};

export default MontoInfraestructura;
