import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import Swal from 'sweetalert2';
import moment from 'moment';

const LOADER = 'LOADER';
const SET_BITACORA = 'SET_BITACORA';
const SET_DETAIL_BITACORA = 'SET_DETAIL_BITACORA';
const SET_PAGE = 'SET_PAGE';
const SET_BUSCADOR_BITACORA = 'SET_BUSCADOR_BITACORA';
const SET_INITIAL_DATE = 'SET_INITIAL_DATE';
const SET_FINAL_DATE = 'SET_FINAL_DATE';

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER, loader: true });
    const store = getStore();
    const accion = store.bitacora.buscador;
    const initial_date = store.bitacora.initial_date;
    const final_date = store.bitacora.final_date;
    let params = { page, accion, initial_date, final_date };
    api.get('bitacora', params)
        .catch(error => {
            dispatch({ type: LOADER, loader: false });
            NotificationManager.error('Ha ocurrido un error, por favor vuelva a intentar', 'Error', 0);
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: SET_BITACORA,
                    data: data,
                });
                dispatch({
                    type: SET_PAGE,
                    page: page,
                });
            }
            dispatch({
                type: LOADER,
                loader: false,
            });
        });
};

export const detail = id => dispatch => {
    dispatch({ type: LOADER, loader: true });
    api.get(`bitacora/${id}`)
        .catch(error => {
            dispatch({ type: LOADER, loader: false });
            NotificationManager.error('Ha ocurrido un error, por favor vuelva a intentar', 'Error', 0);
            dispatch(push('/bitacora'));
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: SET_DETAIL_BITACORA,
                    detail_bitacora: data,
                });
            }
            dispatch({
                type: LOADER,
                loader: false,
            });
        });
};

const search = search => dispatch => {
    dispatch({ type: SET_BUSCADOR_BITACORA, buscador: search });
    dispatch(listar(1));
};

const set_initial_date = value => dispatch => {
    dispatch({ type: SET_INITIAL_DATE, initial_date: value });
    dispatch(listar());
};

const set_final_date = value => dispatch => {
    dispatch({ type: SET_FINAL_DATE, final_date: value });
    dispatch(listar());
};

export const actions = {
    listar,
    detail,
    search,
    set_initial_date,
    set_final_date,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_BITACORA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_DETAIL_BITACORA]: (state, { detail_bitacora }) => {
        return {
            ...state,
            detail_bitacora,
        };
    },
    [SET_BUSCADOR_BITACORA]: (state, { buscador }) => {
        return {
            ...state,
            buscador,
        };
    },
    [SET_INITIAL_DATE]: (state, { initial_date }) => {
        return {
            ...state,
            initial_date,
        };
    },
    [SET_FINAL_DATE]: (state, { final_date }) => {
        return {
            ...state,
            final_date,
        };
    },
};

export const initialState = {
    loader: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    detail_bitacora: {},
    buscador: '',
    initial_date: moment().date(1).format('DD/MM/YYYY'),
    final_date: moment().format('DD/MM/YYYY'),
};

export default handleActions(reducers, initialState);
