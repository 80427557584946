import { MESES } from '../../../utility/constants';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';

export const getMes = mes => {
    const NombreMes = MESES.find(item => item.value === parseInt(mes));
    if (NombreMes !== undefined) return NombreMes.label;
};

export const getArchivo = endpoint => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = endpoint;
    a.click();
    document.body.removeChild(a);
};

export const getReporte = async (endpoint, params) => {
    await api
        .get(endpoint, params)
        .then(response => {
            getArchivo(response.archivo);
        })
        .catch(error => {
            NotificationManager.error('Error al generar el reporte', 'Error', 5000);
        });
};
