import React from 'react';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';

import AlertIcon from '../../../../../../../assets/img/error.png';
import { RenderNumber } from '../../../../Utils/renderField/renderReadField.js';

import './paso8.css';
const PasoIntermedio = props => {
    const [pageErrores, setPageErrores] = React.useState(1);

    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 10, page_number * 10);
    };

    const { errores } = props;
    return (
        <React.Fragment>
            <br />
            <br />
            {errores && errores.count > 0 && (
                <React.Fragment>
                    <span className="text-dark"> Registros con error</span>
                    <hr className="w-100 mt-0" style={{ backgroundColor: '#3B3B41', opacity: '0.5' }} />
                    <div className="custom-scroll-step-8">
                        <Grid
                            data={{
                                results: paginate(errores.results, pageErrores),
                                count: errores.count,
                            }}
                            onPageChange={setPageErrores}
                            sizePerPage={10}
                            page={pageErrores}
                        >
                            <TableHeaderColumn
                                dataField="CENTRO DE COSTO"
                                dataAlign="center"
                                isKey
                                width="5rem"
                                dataFormat={data => {
                                    return <img className="column-data-icon" src={AlertIcon} />;
                                }}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="ERROR" width="350">
                                ERROR
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="CENTRO DE COSTO">CENTRO DE COSTO</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="Metros"
                                dataAlign="right"
                                dataFormat={value => (
                                    <RenderNumber className="remove-style" suffix={''} decimalScale={2} value={value} />
                                )}
                            >
                                METROS
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PasoIntermedio;
