import React from 'react';
import Table from '../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../Utils/Grid/StandardActions';
import Cabecera from '../../Utils/Cabeceras/CabeceraProyecto';
import { Link } from 'react-router-dom';

const ListarProyectos = props => {
    React.useEffect(() => {
        props.listar();
    }, []);

    const { data, page, loader, listar: onPageChange, eliminar, editar } = props;
    return (
        <React.Fragment>
            <div className="card px-4 my-3 pb-4">
                <Cabecera titulo="Proyectos" />
                <div className="d-flex flex-column ml-3">
                    <div className="d-flex flex-row  mt-3 mb-3">
                        <Link to="/proyecto" className="btn btn-danger">
                            Nuevo
                        </Link>
                    </div>

                    <Table hover striped data={data} page={page} loading={loader} onPageChange={onPageChange}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="left"
                            isKey
                            width="7rem"
                            dataFormat={standardActions({ eliminar, editar: '/proyecto' })}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn width="15rem" dataField="nombre" dataAlign="left">
                            PROYECTO
                        </TableHeaderColumn>
                    </Table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListarProyectos;
