import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/bitacora/bitacora';
import Bitacora from './Bitacora';

const ms2p = state => {
    return {
        ...state.bitacora,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Bitacora);
