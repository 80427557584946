import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';

const SET_LOADER = 'SET_LOADER_BOLSON';
const DATA = 'SET_DATA_BOLSON';
const ITEM = 'SET_ITEM_BOLSON';
const REFERENCIAS = 'SET_REFERENCIAS_BOLSON';
const SET_PAGE = 'SET_PAGE_BOLSON';

const setItem = item => ({
    type: ITEM,
    item,
});

const setReferencias = item => ({
    type: REFERENCIAS,
    referencias,
});

export const listar_referencias = () => (dispatch, getStore) => {
    return api.get('bolson/base_group');
};

export const listar_bolsones = () => (dispatch, getStore) => {
    return api.get('bolson/list_bolson');
};

export const crear = data => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.post('bolson', data)
        .then(response => {
            dispatch(push('/bolson'));
            NotificationManager.success('Registro creado exitosamente.', 'Éxito', 5000);
            dispatch(listar());
        })
        .catch(error => {
            NotificationManager.error('Error, no se pudo guardar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: SET_LOADER, loader: true });
    const params = { page };

    api.get('bolson', params)
        .then(response => {
            if (response) {
                dispatch({
                    type: DATA,
                    data: response,
                });
                dispatch({
                    type: SET_PAGE,
                    page: page,
                });
            }
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registros.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listar_tabla_referencias = (bolson = '') => (dispatch, getStore) => {
    dispatch({ type: SET_LOADER, loader: true });
    const params = { bolson };

    api.get('bolson/referencia_bolson', params)
        .then(response => {
            if (response) {
                //dispatch({
                //    type: DATA,
                //    data: response,
                //});
                //dispatch({
                //    type: SET_PAGE,
                //    page: page,
                //});
            }
        })
        .catch(error => {
            NotificationManager.error('Error al obtener registros.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const leer = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.get(`${'bolson'}/${id}`)
        .then(response => {
            dispatch(setItem(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const editar = (id, data) => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.put(`${'bolson'}/${id}`, data)
        .then(() => {
            NotificationManager.success('Registro actualizado.', 'Éxito', 5000);
            dispatch(push('/bolson'));
        })
        .catch(() => {
            NotificationManager.error('Error, no se pudo actualizar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const eliminar = id => dispatch => {
    dispatch({ type: SET_LOADER, loader: true });
    api.eliminar(`${'bolson'}/${id}`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success('Registro eliminado.', 'Éxito', 5000);
        })
        .catch(() => {
            NotificationManager.error('Error, no se pudo eliminar el registro.', 'Error', 5000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};
export const removerItem = () => dispatch => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const actions = {
    crear,
    listar,
    editar,
    leer,
    eliminar,
    removerItem,
    listar_referencias,
    listar_tabla_referencias,
    listar_bolsones,
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [REFERENCIAS]: (state, { referencias }) => {
        return {
            ...state,
            referencias,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    page: 1,
    item: {},
    buscador: '',
    filtro: '',
    referencias: [],
};

export default handleActions(reducers, initialState);
